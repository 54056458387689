import { IRoutes } from './types'

const Routes: Readonly<IRoutes> = {
  Home: '/', // or Login
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  BookPublicationForm: '/form',
  MyTasks: '/tasks',
  BookPublicationsList: '/list',
  RejectedBookPublicationsList: '/list/rejected',
  CompletedBookPublicationsList: '/list/completed',
  AllBookPublicationsList: '/list/all',
}

export default Routes
