import React, { FC, useEffect, useState } from 'react'
import { Form, Select, Spin } from 'antd'
import { UIAuthor } from 'features/bookPublicationForm/types'
import { SearchOutlined } from '@ant-design/icons'
import { ModalColFormItem } from '../styles'

const { Option } = Select

const fetchTimeout = 300

// String.includes() that ignores capitalization and Greek accented letters
const CustomStrIncludes = (outer: string, inner: string) => {
  const outerNorm = outer.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  const innerNorm = inner.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  return outerNorm.toUpperCase().includes(innerNorm.toUpperCase())
}

const AuthorSearch: FC<any> = (props) => {
  const [searchForm] = Form.useForm()
  const [resultData, setResultData] = useState<ResultDataItem[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [tempSearchValue, setTempSearchValue] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  const {
    parentForm,
    allAuthors,
    clearSearchFormMarker,
    institutionDetailsHandler,
    rowKey,
  } = props

  let timeout
  const fetchResults = (value) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }

    const fetch = () => {
      setIsFetching(true)
      // TODO improvement: querystring instead of filtering
      // const authors = TestAuthorListData
      const data: ResultDataItem[] = []
      let resultDataIndex = 0
      allAuthors.forEach((item: UIAuthor) => {
        if (
          CustomStrIncludes(item.email, value) ||
          CustomStrIncludes(item.lastname, value)
        ) {
          data.push({
            value: resultDataIndex,
            author: item,
          })
          resultDataIndex += 1
        }
      })
      setResultData(data)
      setTimeout(() => {
        setIsFetching(false)
      }, 1000)
    }

    timeout = setTimeout(fetch, fetchTimeout)
  }

  const handleSearch = (value) => {
    if (value) {
      fetchResults(value)
      setTempSearchValue(value)
    } else {
      setResultData([])
    }
  }

  useEffect(() => {
    searchForm.resetFields()
  }, [clearSearchFormMarker]) // eslint-disable-line

  const handleChange = (value) => {
    setSearchValue(value)
    setTempSearchValue(value)
    const resultAuthor = resultData[value].author
    searchForm.setFieldsValue({
      searchInput: `${resultAuthor.lastname} ${resultAuthor.firstname} - ${resultAuthor.email}`,
    })
    parentForm.setFieldsValue(resultAuthor)
    if (
      resultAuthor.institutionDetails?.university &&
      resultAuthor.institutionDetails?.department
    ) {
      institutionDetailsHandler(
        resultAuthor.institutionDetails.university,
        resultAuthor.institutionDetails.department
      )
    }
    setResultData([])
  }

  const handleBlur = () => {
    if (tempSearchValue !== searchValue) {
      setSearchValue(tempSearchValue)
    }
  }

  return (
    <Form layout="vertical" form={searchForm}>
      <ModalColFormItem
        label={
          <>
            <SearchOutlined />
            &nbsp; Αναζήτηση με Email ή Επώνυμο
          </>
        }
        name="searchInput"
      >
        <Select
          showSearch
          id={`searchInput_${rowKey}`}
          value={searchValue}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          onBlur={handleBlur}
          notFoundContent={isFetching ? <Spin size="small" /> : null}
        >
          {resultData.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.author.lastname} {item.author.firstname} -{' '}
              {item.author.email}
            </Option>
          ))}
        </Select>
      </ModalColFormItem>
    </Form>
  )
}

interface ResultDataItem {
  value: number
  author: UIAuthor
}

export default AuthorSearch
