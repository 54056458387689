import { createStore, applyMiddleware, Middleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import freeze from 'redux-freeze'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './rootReducer'
import rootSagas from './rootSagas'

const AppName = process.env.npm_package_name
const AppVersion = process.env.npm_package_version

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const middlewares: Middleware[] = [sagaMiddleware]

// Only includes in DEV mode
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger)
  middlewares.push(freeze)
}

const composeEnhancers = composeWithDevTools({
  name: `${AppName}:${AppVersion}`,
})

const Store = createStore(
  rootReducer,
  // We need to compose enhancer since createStore can accept only one
  composeEnhancers(applyMiddleware(...middlewares))
)

// Initialize Sagas
sagaMiddleware.run(rootSagas)

export default Store
