import React, {
  FC,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'

import { Checkbox, Form } from 'antd'
import { FormHeader, StepForm } from './styles'

import CompletionSection from './CompletionSection'

import { Dispatch, bindActionCreators } from 'redux'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { GenericActionType, RootState } from 'redux/types'
import CheckboxGroup from 'antd/es/checkbox/Group'
import styled from 'styled-components'

const CheckAllWrapper = styled.div`
  text-align: center;
  margin-bottom: 14px;
`

const CheckboxGroupWrapper = styled(CheckboxGroup)`
  .ant-checkbox-wrapper {
    margin: 8px 14px 8px 0;
  }
`

export enum RuleSetOptions {
  title = 'Τίτλος',
  subtitle = 'Υπότιτλος',
  tableOfContents = 'Περιεχόμενα',
  oneFile = 'Όλο το βιβλίο σε ένα αρχείο',
  capitalHeaders = 'Κεφαλίδες στα κεφάλαια',
  glossary = 'Γλωσσάρι',
  index = 'Ευρετήριο',
  backText = 'Κείμενο οπισθοφύλλου',
  bibliography = 'Βιβλιογραφία',
  catalogText = 'Κείμενο καταλόγου',
  photos = 'Φωτογραφίες',
  equations = 'Εξισώσεις',
  diagrams = 'Γραφήματα',
  tables = 'Πίνακες',
}

const ManuscriptVerification: FC<any> = forwardRef((props, ref) => {
  const { defaults, stepDisabled, setHasFormChanged, actions, allUsers } = props

  const [form] = Form.useForm()

  /**
   * useImperativeHandle customizes the instance value
   * that is exposed to parent components when using ref.
   *
   * We need to expose only the handle submit to the parent
   * instance to handle the validation of the current form
   * from the next/prev step buttons.
   */
  useImperativeHandle(ref, () => ({
    submitHandler,
  }))

  const submitStepValues = useCallback(
    (values) => {
      if (values.completion?.assignees) {
        values.completion.assignees = values.completion.assignees.map(
          (assigneeMail) => allUsers.find((user) => user.email === assigneeMail)
        )
      }
      values.completion.stepEntityId = defaults.completion.stepEntityId
      actions.submitManuscriptVerificationRequested(values, defaults)
    },
    [actions, defaults, allUsers]
  )

  /**
   * Step form submit callback used
   * by the parent component
   */
  const submitHandler = useCallback(() => {
    if (form.getFieldValue(['completion', 'stepCompleted'])) {
      form.validateFields().then((values) => submitStepValues(values))
    } else {
      submitStepValues(form.getFieldsValue())
    }
  }, [form, submitStepValues])

  const checkboxOptions = Object.values(RuleSetOptions)
  const [checkedList, setCheckedList] = useState(defaults.checkboxes || [])

  const onCheckAllChange = useCallback(() => {
    if (checkedList.length === checkboxOptions.length) {
      setCheckedList([])
      form.setFieldsValue({ checkboxes: [] })
    } else {
      setCheckedList(checkboxOptions)
      form.setFieldsValue({ checkboxes: checkboxOptions })
    }
  }, [checkedList, checkboxOptions, form])

  const onCheckboxesChange = (checkedList) => {
    setCheckedList(checkedList)
  }

  return (
    <StepForm
      form={form}
      initialValues={defaults}
      $stepDisabled={stepDisabled}
      onFieldsChange={() => setHasFormChanged(true)}
    >
      <FormHeader>Ελεγμένο Υλικό Έκδοσης</FormHeader>
      <CheckAllWrapper>
        <Checkbox
          indeterminate={
            !!checkedList.length && checkedList.length < checkboxOptions.length
          }
          onChange={onCheckAllChange}
          checked={checkedList.length === checkboxOptions.length}
        >
          Επιλογή όλων
        </Checkbox>
      </CheckAllWrapper>
      <Form.Item name="checkboxes" valuePropName="checked">
        <CheckboxGroupWrapper
          options={checkboxOptions}
          value={checkedList}
          onChange={onCheckboxesChange}
        />
      </Form.Item>
      <FormHeader>Ολοκλήρωση Ελέγχου Υλικού</FormHeader>
      <CompletionSection defaults={defaults.completion} form={form} />
    </StepForm>
  )
})

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allUsers: state.entities.getData.users,
    usersErrors: state.entities.errors.getUsersErrors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ManuscriptVerification) as any
