import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Layout, notification, Statistic } from 'antd'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'

import Routes from 'configs/routes'
import ScrollToTop from 'common/components/ScrollToTop'
import NotFound from 'common/components/NotFound'
import LandingScreen from 'features/landingPage/screens/LandingScreen'
import NewBookPublication from 'features/bookPublicationForm/screens/NewBookPublication'
import MainLayout from 'common/layouts/MainLayout'
import UserLayout from 'common/layouts/UserLayout'
import BookPublicationsList from 'features/bookPublicationsList/screens/BookPublicationsList'
import AuthenticatedRoute from 'common/components/AuthenticatedRoute'
import { RootState } from 'redux/types'
import { connect } from 'react-redux'
import Services from 'services'
import Constants from 'common/constants'
import { AuthenticationActionCreators } from 'features/authentication/ducks'
import { bindActionCreators, Dispatch } from 'redux'
import { AuthenticationActionNames } from 'features/authentication/types'
import Configs from 'configs/configs'
import LoginForm from 'features/landingPage/screens/LoginForm'
import ForgotPasswordForm from 'features/landingPage/screens/ForgotPasswordForm'
import ResetPassword from 'features/landingPage/screens/ResetPassword'
import { BookPublicationsListType } from 'features/bookPublicationsList/types'
import { ErrorBoundary } from 'common/components/ErrorBoundary'

const AppRoutes: FC<any> = (props) => {
  const { isAuthenticated, isTokenRefreshing, actions } = props
  const [menuCollapsed, setMenuCollapsed] = useState(false)

  const logoutTimer = useRef<number>()
  const refreshTimer = useRef<number>()

  const logoutUser = useCallback(() => {
    clearTimeout(refreshTimer.current)
    notification.destroy()
    actions.logoutRequested()
  }, [actions])

  const getMsUntilExpiration = (expirationDate): number => {
    return Math.max(expirationDate - Date.now(), 0)
  }

  const openNotification = useCallback(() => {
    notification.warn({
      message: `Ειδοποίηση Αποσύνδεσης`,
      description: (
        <Statistic.Countdown
          title="Θα αποσυνδεθείτε αυτόματα σε:"
          value={Services.LocalStorage.get(
            Constants.STORAGE_REFRESH_TOKEN_EXPIRATION_DATE
          )}
          valueStyle={{
            fontSize: 18,
          }}
          format="mm:ss"
          onFinish={logoutUser}
        />
      ),
      placement: 'topRight',
      top: 56,
      duration: 0,
      closeIcon: <></>,
    })
  }, [logoutUser])

  // User inactivity/Refresh token expiration timer
  useEffect(() => {
    if (isAuthenticated) {
      // js needs this
      clearTimeout(logoutTimer.current)
      logoutTimer.current = setTimeout(() => {
        openNotification()
      }, Math.max(getMsUntilExpiration(Services.LocalStorage.get(Constants.STORAGE_REFRESH_TOKEN_EXPIRATION_DATE)) - Configs.LogoutNotificationThresholdSec * 1000, 0))
    }
  }, [isAuthenticated, openNotification])

  // Refresh user timer
  useEffect(() => {
    if (isAuthenticated && !isTokenRefreshing) {
      // Issue a refresh 1 second before expiration
      clearTimeout(refreshTimer.current)
      refreshTimer.current = setTimeout(() => {
        actions.refreshRequested()
      }, getMsUntilExpiration(Services.LocalStorage.get(Constants.STORAGE_TOKEN_EXPIRATION_DATE)) - 1000 * 1)
    }
  }, [isAuthenticated, isTokenRefreshing, actions])

  // If the user logs out manually, stop the running timers
  useEffect(() => {
    if (!isAuthenticated) {
      clearTimeout(refreshTimer.current)
      clearTimeout(logoutTimer.current)
    }
  }, [isAuthenticated])

  const location = useLocation()

  return (
    <Layout>
      <ScrollToTop />
      <ErrorBoundary>
        <Switch>
          <Route exact key={Routes.Home} path={Routes.Home}>
            {/* We want to redirect to MyTasks when an authenticated user requests the login page */}
            {!props.isAuthenticated ? (
              <MainLayout>
                <LandingScreen>
                  <LoginForm />
                </LandingScreen>
              </MainLayout>
            ) : (
              <Redirect
                to={{ pathname: Routes.MyTasks, state: { from: location } }}
              />
            )}
          </Route>

          <Route exact key={Routes.ForgotPassword} path={Routes.ForgotPassword}>
            {/* We want to redirect to MyTasks when an authenticated user requests the forgot-password page */}
            {!props.isAuthenticated ? (
              <MainLayout>
                <LandingScreen>
                  <ForgotPasswordForm />
                </LandingScreen>
              </MainLayout>
            ) : (
              <Redirect
                to={{ pathname: Routes.MyTasks, state: { from: location } }}
              />
            )}
          </Route>

          <Route exact key={Routes.ResetPassword} path={Routes.ResetPassword}>
            {/* We want to redirect to MyTasks when an authenticated user requests the reset password page */}
            {!props.isAuthenticated ? (
              <MainLayout>
                <LandingScreen>
                  <ResetPassword />
                </LandingScreen>
              </MainLayout>
            ) : (
              <Redirect
                to={{ pathname: Routes.MyTasks, state: { from: location } }}
              />
            )}
          </Route>

          <AuthenticatedRoute
            exact
            key={Routes.BookPublicationForm}
            path={Routes.BookPublicationForm}
          >
            <UserLayout
              selectedKey={'1'}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            >
              <NewBookPublication />
            </UserLayout>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact key={Routes.MyTasks} path={Routes.MyTasks}>
            <UserLayout
              selectedKey={'2'}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            >
              <BookPublicationsList
                type={BookPublicationsListType.SelfPending}
              />
            </UserLayout>
          </AuthenticatedRoute>

          <AuthenticatedRoute
            exact
            key={Routes.BookPublicationsList}
            path={Routes.BookPublicationsList}
          >
            <UserLayout
              selectedKey={'3'}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            >
              <BookPublicationsList type={BookPublicationsListType.Pending} />
            </UserLayout>
          </AuthenticatedRoute>

          <AuthenticatedRoute
            exact
            key={Routes.RejectedBookPublicationsList}
            path={Routes.RejectedBookPublicationsList}
          >
            <UserLayout
              selectedKey={'4'}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            >
              <BookPublicationsList type={BookPublicationsListType.Rejected} />
            </UserLayout>
          </AuthenticatedRoute>

          <AuthenticatedRoute
            exact
            key={Routes.CompletedBookPublicationsList}
            path={Routes.CompletedBookPublicationsList}
          >
            <UserLayout
              selectedKey={'5'}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            >
              <BookPublicationsList type={BookPublicationsListType.Completed} />
            </UserLayout>
          </AuthenticatedRoute>

          <AuthenticatedRoute
            exact
            key={Routes.AllBookPublicationsList}
            path={Routes.AllBookPublicationsList}
          >
            <UserLayout
              selectedKey={'6'}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            >
              <BookPublicationsList type={BookPublicationsListType.All} />
            </UserLayout>
          </AuthenticatedRoute>

          <Route path="*">
            <MainLayout>
              <NotFound />
            </MainLayout>
          </Route>
        </Switch>
      </ErrorBoundary>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: state.user.auth.isAuthenticated,
    isTokenRefreshing: state.user.auth.isTokenRefreshing,
  }
}

export const mapDispatchToProps = (
  dispatch: Dispatch<AuthenticationActionNames>
) => {
  return {
    actions: bindActionCreators({ ...AuthenticationActionCreators }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes)
