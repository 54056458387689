// As suggested here: https://redux.js.org/recipes/reducing-boilerplate
function makeActionCreator(type, ...argNames) {
  return function(...args) {
    if (!argNames) {
      return { type }
    }
    let payload = {}
    argNames.forEach((arg, index) => {
      payload[argNames[index]] = args[index]
    })
    return { type, payload }
  }
}

export default makeActionCreator
