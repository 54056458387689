import React, {
  useState,
  useEffect,
  FC,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'

import { Form, Modal, DatePicker, Select, Button, Row } from 'antd'
import Configs from 'configs/configs'
import {
  TableAddButton,
  TableDiv,
  SelectWide,
} from 'features/bookPublicationForm/screens/styles'
import EditableTable from 'common/components/EditableTable'
import {
  UITechnicalInstruction,
  UIAuthor,
} from 'features/bookPublicationForm/types'
import { CSVLink } from 'react-csv'
import { DownloadOutlined } from '@ant-design/icons'
import { getTechnicalInstructionCsvItem } from 'features/bookPublicationForm/utils'
import { sorterStringCompare, sorterDateCompare } from 'common/utils'

const { Option } = Select

const ModalForm: FC<any> = (props, ref) => {
  const [form] = Form.useForm()
  const { visible, onCancel, onCreate, authors } = props

  useImperativeHandle(ref, () => ({
    form: form,
  }))

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onCreate}
      title="Προσθήκη Επικοινωνίας"
      okText="Προσθήκη"
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Συγγραφέας"
          name="author"
          rules={[{ required: true, message: Configs.Messages.RequiredField }]}
        >
          <SelectWide placeholder="Επιλέξτε Συγγραφέα">
            {authors.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.firstname} {item.lastname} - {item.email}
              </Option>
            ))}
          </SelectWide>
        </Form.Item>
        {/* <Form.Item label="Υπεύθυνος επικοινωνίας" name="supervisor">
        </Form.Item> */}
        <Form.Item
          label="Ημερομηνία επικοινωνίας"
          name="sentDate"
          rules={[{ required: true, message: Configs.Messages.RequiredField }]}
        >
          <DatePicker
            format={Configs.Date.Format}
            placeholder={Configs.Date.Placeholder}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ModalAddForm = forwardRef(ModalForm)

const EditableInstructionsTable: FC<any> = (props) => {
  const defaults: UITechnicalInstruction[] = props.defaults
  const authors: UIAuthor[] = props.authors
  const { disabled, setHasFormChanged } = props

  const [dataSource, setDataSource] = useState<UITechnicalInstruction[]>(
    defaults || []
  )
  const [availableAuthors, setAvailableAuthors] = useState<UIAuthor[]>([])
  const [modalDisplay, setModalDisplay] = useState<boolean>(false)

  const csvHeaders = [
    { label: 'Συγγραφέας', key: 'author' },
    { label: 'Ημερομηνία Επικοινωνίας', key: 'sentDate' },
  ]
  const [csvData, setCsvData] = useState<any>([])

  useEffect(() => {
    // we want to exclude authors already in the table
    if (dataSource) {
      setAvailableAuthors(
        authors.filter(
          (x) => !dataSource.map((el) => el.author.id).includes(x.id)
        )
      )
      setCsvData(dataSource.map((el) => getTechnicalInstructionCsvItem(el)))
    }
  }, [authors, dataSource])

  const dataColumns = [
    {
      title: 'Συγγραφέας',
      dataIndex: 'author',
      sorter: (a, b) =>
        sorterStringCompare(a.author.lastname, b.author.lastname),
      render: (record: UIAuthor) => (
        <span>
          {record.lastname} {record.firstname}
        </span>
      ),
      editable: false,
    },
    {
      title: 'Ημερομηνία Επικοινωνίας',
      dataIndex: 'sentDate',
      sorter: (a, b) => sorterDateCompare(a.sentDate, b.sentDate),
      render: (record) => (!record ? '-' : record.format(Configs.Date.Format)),
      editable: true,
      inputType: 'date',
    },
  ]

  const getNewRowKey = useCallback(() => {
    return dataSource.length > 0
      ? Math.max(...dataSource.map((item) => +item.key!)) + 1
      : 0
  }, [dataSource])

  // Whenever we've got an dataSource state change
  // inform the dataRef of the parent component
  useEffect(() => {
    if (props.dataRef && dataSource) {
      // update but keep ids from newly POSTed entities
      const currInstructionsSentTable: UITechnicalInstruction[] =
        props.dataRef.current.instructionsSentTable
      props.dataRef.current.instructionsSentTable = dataSource.map((item) => ({
        ...item,
        id:
          item.id ??
          currInstructionsSentTable.find(
            (el) => el.author.id === item.author.id
          )?.id,
      }))
    }
    if (dataSource !== defaults) {
      setHasFormChanged()
    }
  }, [dataSource]) // eslint-disable-line

  const modalOpenHandler = useCallback(() => {
    if (availableAuthors.length) {
      setModalDisplay(true)
    } else if (!authors.length) {
      // no authors have been added yet
      Modal.warning({
        title: Configs.Messages.NoAuthors.title,
        content: Configs.Messages.NoAuthors.content,
      })
    } else {
      Modal.warning({
        title: Configs.Messages.NoAvailableAuthors.title,
        content: Configs.Messages.NoAvailableAuthors.content,
      })
    }
  }, [authors.length, availableAuthors.length])

  const modalCancelHandler = useCallback(() => {
    const { form } = modalAddFormRef.current
    setModalDisplay(false)
    form.resetFields()
  }, [])

  const modalCreateHandler = useCallback(() => {
    const { form } = modalAddFormRef.current

    form.validateFields().then((values) => {
      values.author = authors.find((item) => item.id === values.author)
      setDataSource((prevValues) => [
        ...prevValues,
        { ...values, key: getNewRowKey() },
      ])
      form.resetFields()
      setModalDisplay(false)
    })
  }, [getNewRowKey, authors])

  const modalAddFormRef = useRef<any>(null)

  return (
    <TableDiv>
      <EditableTable
        disabled={disabled}
        dataSource={dataSource}
        dataColumns={dataColumns}
        setParentData={(newData) => setDataSource(newData)}
      />
      <Row justify="space-between">
        <TableAddButton onClick={modalOpenHandler} disabled={disabled}>
          Προσθήκη Αποστολής
        </TableAddButton>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          separator={';'}
          style={{ marginTop: 7 }}
        >
          <Button icon={<DownloadOutlined />}>Εξαγωγή</Button>
        </CSVLink>
      </Row>
      <ModalAddForm
        visible={modalDisplay}
        onCancel={modalCancelHandler}
        onCreate={modalCreateHandler}
        authors={availableAuthors}
        ref={(form) => (modalAddFormRef.current = form)}
      />
    </TableDiv>
  )
}

export default EditableInstructionsTable
