import React, { FC } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { RootState } from 'redux/types'
import Routes from 'configs/routes'

const AuthenticatedRoute: FC<any> = (props) => {
  const { isAuthenticated, children, setToken, ...rest } = props

  const location = useLocation()

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect to={{ pathname: Routes.Home, state: { from: location } }} />
      )}
    </Route>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: state.user.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps)(AuthenticatedRoute)
