import { combineReducers } from 'redux'

import ActionNames from './actionNames'
import { CommonActionTypes, IUIState } from 'common/types'

const initialState: IUIState = {
  loading: false,
}

function UIReducer(state = initialState, action: CommonActionTypes): IUIState {
  switch (action.type) {
    case ActionNames.COMMON_UI_LOADING_STARTED:
      return {
        loading: true,
      }

    case ActionNames.COMMON_UI_LOADING_FINISHED:
      return {
        loading: false,
      }

    default:
      return state
  }
}

export default combineReducers({
  ui: UIReducer,
})
