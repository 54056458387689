const PREFIX = 'common'

const ActionNames = {
  COMMON_UI_LOADING_STARTED: `${PREFIX}/ui/loading/started`,
  COMMON_UI_LOADING_FINISHED: `${PREFIX}/ui/loading/finished`,

  RESET_UI: `${PREFIX}/ui/reset`,
}

export default ActionNames
