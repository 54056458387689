import ActionNames from './actionNames'

import { CommonActionTypes } from 'common/types'

function uiLoadingStarted(): CommonActionTypes {
  return { type: ActionNames.COMMON_UI_LOADING_STARTED }
}

function uiLoadingFinished(): CommonActionTypes {
  return { type: ActionNames.COMMON_UI_LOADING_FINISHED }
}

function uiReset(): CommonActionTypes {
  return { type: ActionNames.RESET_UI }
}

export default {
  uiLoadingStarted,
  uiLoadingFinished,
  uiReset,
}
