import React, { FC } from 'react'

import Header from 'common/components/Header'
import Footer from 'common/components/Footer'
import { ContentWrapper, CenteredContent } from './styles'
import Sidebar from 'common/components/Sidebar'
import { Layout } from 'antd'
import { ErrorBoundary } from 'common/components/ErrorBoundary'

const UserLayout: FC<any> = (props) => {
  const { selectedKey, menuCollapsed, setMenuCollapsed, children } = props

  return (
    <Layout>
      <Sidebar
        selectedKey={selectedKey}
        menuCollapsed={menuCollapsed}
        setMenuCollapsed={setMenuCollapsed}
      />
      <Layout style={{ minHeight: '100vh' }}>
        <Header />
        <CenteredContent>
          <ContentWrapper>
            <ErrorBoundary>{children}</ErrorBoundary>
          </ContentWrapper>
        </CenteredContent>
        <Footer />
      </Layout>
    </Layout>
  )
}

export default UserLayout
