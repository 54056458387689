import React, {
  useState,
  useCallback,
  forwardRef,
  FC,
  useImperativeHandle,
  useRef,
} from 'react'

import { Form, Space } from 'antd'
import {
  FormHeader,
  StepForm,
} from 'features/bookPublicationForm/screens/styles'
import AuthoringProgressCard, {
  TotalProgressCard,
} from './AuthoringProgressCard'
import AddDeliverablesModal from './AddDeliverablesModal'
import AddDeliverablesCard from './AddDeliverablesCard'
import EditableAuthoringProgressTable from './EditableAuthoringProgressTable'
import CompletionSection from 'features/bookPublicationForm/screens/CompletionSection'
import styled from 'styled-components'
import { UIAuthor, AuthoringStepData } from 'features/bookPublicationForm/types'

import { Dispatch, bindActionCreators } from 'redux'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'
import { useLazyEffect } from 'common/hooks/useLazyEffect'
import _ from 'lodash'

// TODO Remove this styled component after upgrading to version 4.9.0
// as Space will support spacing in both axis
const CardWrapper = styled.div`
  margin: 12px 0;
`

// TODO Remove this styled component after upgrading to version 4.9.0
// as Space will support wrap
const FlexSpace = styled(Space)`
  display: flex;
  flex-flow: row wrap;
`

const Authoring: FC<any> = forwardRef((props, ref) => {
  let defaults: AuthoringStepData = props.defaults
  const authors: UIAuthor[] = props.authors
  const {
    stepDisabled,
    setHasFormChanged,
    actions,
    stepSubmitting,
    stepSubmittedData,
    stepSubmittedErrors,
    allUsers,
  } = props

  const [form] = Form.useForm()
  const [currIndex, setCurrIndex] = useState(-1)

  const [activeAuthors, setActiveAuthors] = useState(
    [...Array(authors.length)].map(() => false)
  )

  const [authorsProgressTable, setAuthorsProgressTable] = useState<any>(
    [...Array(authors.length)].map((_, i) => ({
      author: { ...authors[i], key: undefined }, // only a single author per progressTable, no need for key
      deliverables: defaults.authorsProgressTable[i]?.deliverables?.length
        ? defaults.authorsProgressTable[i].deliverables
        : [],
      key: i,
      id: defaults.authorsProgressTable[i]?.id,
    }))
  )

  /**
   * useImperativeHandle customizes the instance value
   * that is exposed to parent components when using ref.
   *
   * We need to expose only the handle submit to the parent
   * instance to handle the validation of the current form
   * from the next/prev step buttons.
   */
  useImperativeHandle(ref, () => ({
    submitHandler,
  }))

  useLazyEffect(() => {
    if (
      !stepSubmitting &&
      !stepSubmittedErrors.length &&
      !_.isEmpty(stepSubmittedData)
    ) {
      setAuthorsProgressTable(
        stepSubmittedData.authoring.authorsProgressTable.map((el) => ({
          ...el,
        }))
      )
      defaults = stepSubmittedData.authoring
    }
  }, [stepSubmitting, stepSubmittedData, stepSubmittedErrors])

  const submitStepValues = useCallback(
    (values) => {
      if (values.completion?.assignees) {
        values.completion.assignees = values.completion.assignees.map(
          (assigneeMail) => allUsers.find((user) => user.email === assigneeMail)
        )
      }
      values.completion.stepEntityId = defaults.completion.stepEntityId
      actions.submitAuthoringRequested(
        {
          ...values,
          authorsProgressTable,
        },
        defaults
      )
    },
    [actions, defaults, allUsers, authorsProgressTable]
  )

  /**
   * Step form submit callback used
   * by the parent component
   */
  const submitHandler = useCallback(() => {
    if (form.getFieldValue(['completion', 'stepCompleted'])) {
      form.validateFields().then((values) => submitStepValues(values))
    } else {
      submitStepValues(form.getFieldsValue())
    }
  }, [form, submitStepValues])

  const joinDeliverableLists = (originalList, newList) => {
    let nextKey = originalList.length
      ? Math.max(...originalList.map((item) => +item.key)) + 1
      : 0
    return [
      ...originalList,
      ...newList.map((v, i) => ({
        ...v,
        key: nextKey + i,
      })),
    ]
  }

  const onDeliverablesAdded = useCallback(
    (node) => {
      if (currIndex >= 0) {
        let tempTable = [...authorsProgressTable]
        if (tempTable[currIndex].deliverables !== node) {
          tempTable[currIndex] = {
            ...tempTable[currIndex],
            deliverables: joinDeliverableLists(
              authorsProgressTable[currIndex].deliverables,
              node
            ),
          }
          let tempActive = [...activeAuthors]
          tempActive[currIndex] = true
          setActiveAuthors(tempActive)
        }
        setAuthorsProgressTable(tempTable)
      }
    },
    [currIndex, activeAuthors] // eslint-disable-line
  )

  const onDeliverablesEdited = useCallback(
    (node, index) => {
      if (node != null) {
        let tempTable = [...authorsProgressTable]
        if (tempTable[index].deliverables !== node) {
          tempTable[index].deliverables = node
          let tempActive = [...activeAuthors]
          tempActive[index] = true
          setActiveAuthors(tempActive)
        }
        setAuthorsProgressTable(tempTable)
      }
    },
    [authorsProgressTable, activeAuthors]
  )

  const openModalRef = useRef<any>()

  const openModal = useCallback((index) => {
    setCurrIndex(index)
    openModalRef.current.modalOpenHandler()
  }, [])

  return (
    <StepForm
      form={form}
      initialValues={defaults}
      $stepDisabled={stepDisabled}
      onFieldsChange={() => setHasFormChanged(true)}
    >
      <FormHeader style={{ marginBottom: 5 }}>Παραδοτέα συγγραφέων</FormHeader>
      <TotalProgressCard
        chuncksTotalNum={authorsProgressTable.reduce(
          (acc, cur) => acc + cur.deliverables.length,
          0
        )}
        chuncksDeliveredNum={authorsProgressTable.reduce(
          (acc, cur) =>
            acc +
            cur.deliverables.reduce(
              (acc, cur) => (!!cur.deliveredDate ? acc + 1 : acc),
              0
            ),
          0
        )}
      />
      <FlexSpace align={'start'} size={12}>
        {authors.map((item: UIAuthor, index) => (
          <CardWrapper key={`${item.lastname} ${item.firstname}`}>
            {!authorsProgressTable[index].deliverables.length ? (
              <AddDeliverablesCard
                title={`${item.lastname} ${item.firstname}`}
                key={item.email}
                onButtonClick={() => openModal(index)}
              />
            ) : (
              <AuthoringProgressCard
                title={`${item.lastname} ${item.firstname}`}
                active={activeAuthors[index]}
                key={item.email}
                onClick={() => {
                  let tempActive = [...activeAuthors]
                  tempActive[index] = !tempActive[index]
                  setActiveAuthors(tempActive)
                }}
                chuncksTotalNum={
                  authorsProgressTable[index].deliverables.length
                }
                chuncksDeliveredNum={authorsProgressTable[
                  index
                ].deliverables.reduce(
                  (acc, cur) => (!!cur.deliveredDate ? acc + 1 : acc),
                  0
                )}
                deliverablesTable={
                  <EditableAuthoringProgressTable
                    index={index}
                    // I really think that this is a bad name, as dataRef is not a ref
                    setDataRef={onDeliverablesEdited}
                    defaults={authorsProgressTable[index].deliverables}
                    onButtonClick={() => openModal(index)}
                    disabled={stepDisabled}
                    setHasFormChanged={() => setHasFormChanged(true)}
                  />
                }
              />
            )}
          </CardWrapper>
        ))}
      </FlexSpace>
      <AddDeliverablesModal
        ref={openModalRef}
        // I really think that this is a bad name, as dataRef is not a ref
        setDataRef={onDeliverablesAdded}
        defaults={authorsProgressTable}
        setHasFormChanged={() => setHasFormChanged(true)}
      />
      <FormHeader style={{ marginTop: 20 }}>
        Ολοκλήρωση Παραλαβής Παραδοτέων Συγγραφέων
      </FormHeader>
      <CompletionSection defaults={defaults.completion} form={form} />
    </StepForm>
  )
})

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allUsers: state.entities.getData.users,
    usersErrors: state.entities.errors.getUsersErrors,

    stepSubmitting: state.entities.stepData.stepSubmitting,
    stepSubmittedData: state.entities.stepData.stepSubmittedData,
    stepSubmittedErrors: state.entities.stepData.stepSubmittedErrors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Authoring) as any
