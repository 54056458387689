import React, { FC, forwardRef, useCallback, useImperativeHandle } from 'react'

import { Row, Col, Form, DatePicker } from 'antd'
import { FormHeader, StepForm } from './styles'

import Configs from 'configs/configs'
import TextArea from 'antd/lib/input/TextArea'
import CompletionSection from './CompletionSection'

import { Dispatch, bindActionCreators } from 'redux'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { GenericActionType, RootState } from 'redux/types'

const PublicationVerification: FC<any> = forwardRef((props, ref) => {
  const { defaults, stepDisabled, setHasFormChanged, actions, allUsers } = props

  const [form] = Form.useForm()

  /**
   * useImperativeHandle customizes the instance value
   * that is exposed to parent components when using ref.
   *
   * We need to expose only the handle submit to the parent
   * instance to handle the validation of the current form
   * from the next/prev step buttons.
   */
  useImperativeHandle(ref, () => ({
    submitHandler,
  }))

  const submitStepValues = useCallback(
    (values) => {
      if (values.completion?.assignees) {
        values.completion.assignees = values.completion.assignees.map(
          (assigneeMail) => allUsers.find((user) => user.email === assigneeMail)
        )
      }
      values.completion.stepEntityId = defaults.completion.stepEntityId
      actions.submitPublicationVerificationRequested(values, defaults)
    },
    [actions, defaults, allUsers]
  )

  /**
   * Step form submit callback used
   * by the parent component
   */
  const submitHandler = useCallback(() => {
    if (form.getFieldValue(['completion', 'stepCompleted'])) {
      form.validateFields().then((values) => submitStepValues(values))
    } else {
      submitStepValues(form.getFieldsValue())
    }
  }, [form, submitStepValues])

  return (
    <StepForm
      form={form}
      initialValues={defaults}
      $stepDisabled={stepDisabled}
      onFieldsChange={() => setHasFormChanged(true)}
    >
      <FormHeader>Έλεγχος Διορθωτή</FormHeader>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Ημερομηνία παράδοσης σε Διορθωτή"
            name="checkerDeliveryDate"
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
          <Form.Item
            label="Εκτιμώμενη παραλαβή από Διορθωτή"
            name="checkerExpectedCompletionDate"
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
          <Form.Item
            label="Ημερομηνία παραλαβής από Διορθωτή"
            name="checkerActualCompletionDate"
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="checkerNotes" style={{ width: '95%' }}>
            <TextArea rows={4} placeholder="Σχόλια Διορθωτή" />
          </Form.Item>
        </Col>
      </Row>
      <FormHeader>Έλεγχος Σελιδοποίησης</FormHeader>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Ημερομηνία παράδοσης προς σελιδοποίηση"
            name="paginationDeliveryDate"
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
          <Form.Item
            label="Εκτιμώμενη ολοκλήρωση σελιδοποίησης"
            name="paginationExpectedCompletionDate"
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
          <Form.Item
            label="Ημερομηνία ολοκλήρωσης σελιδοποίησης"
            name="paginationActualCompletionDate"
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="paginationNotes" style={{ width: '95%' }}>
            <TextArea rows={4} placeholder="Σχόλια σελιδοποίησης" />
          </Form.Item>
        </Col>
      </Row>
      <FormHeader>Τελικός Έλεγχος Έκδοσης</FormHeader>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Ημερομηνία Τελικής Παράδοσης"
            name="deliveredDate"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
          <Form.Item
            label="Επιθυμητή Ημερομηνία Έκδοσης"
            name="expectedPublicationDate"
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="verificationComments" style={{ width: '95%' }}>
            <TextArea rows={4} placeholder="Σχόλια Έκδοσης" />
          </Form.Item>
        </Col>
      </Row>
      <FormHeader>Ολοκλήρωση Τελικού Ελέγχου Έκδοσης</FormHeader>
      <CompletionSection defaults={defaults.completion} form={form} />
    </StepForm>
  )
})

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allUsers: state.entities.getData.users,
    usersErrors: state.entities.errors.getUsersErrors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PublicationVerification) as any
