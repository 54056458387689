import moment from 'moment'
import 'moment/locale/el'
import { UIBookPublication } from 'features/bookPublicationForm/types'

/* ----------- STATE TYPES -------------  */

export interface IBookPublicationsState {
  bookPublications: UIBookPublication[]
}

export type IBookPublicationsErrorState = {
  bookPublicationsErrors: any[]
}

/* ----------- ENTITY TYPES -------------  */

// enum-like structure that exposes both a numeric and a string value:
export class PublicationStep {
  static readonly BookDetails = new PublicationStep(1, 'Πληροφορίες Βιβλίου')
  static readonly PublicationDetails = new PublicationStep(
    2,
    'Συμφωνία Έκδοσης'
  )
  static readonly ContractSigning = new PublicationStep(
    3,
    'Υπογραφή Συμβολαίων'
  )
  static readonly TechnicalInstructions = new PublicationStep(
    4,
    'Αποστολή Οδηγιών'
  )
  static readonly Authoring = new PublicationStep(5, 'Χρόνοι Παράδοσης')
  static readonly ManuscriptVerification = new PublicationStep(
    6,
    'Έλεγχος Χειρογράφων'
  )
  static readonly PublicationVerification = new PublicationStep(
    7,
    'Έλεγχος Έκδοσης'
  )
  static readonly PublicationReview = new PublicationStep(
    -1,
    'Επισκόπηση Πρότασης'
  )

  // private to disallow creating other instances of this type
  private constructor(
    public readonly index: number,
    private readonly value: any
  ) {}

  toString() {
    return this.value
  }

  static getAllAsArray(withReview?: boolean) {
    const formSteps = [
      PublicationStep.BookDetails,
      PublicationStep.PublicationDetails,
      PublicationStep.ContractSigning,
      PublicationStep.TechnicalInstructions,
      PublicationStep.Authoring,
      PublicationStep.ManuscriptVerification,
      PublicationStep.PublicationVerification,
    ]
    return withReview
      ? [...formSteps, PublicationStep.PublicationReview]
      : formSteps
  }
}

export interface BookPublicationsListItem {
  seqId: number
  bookTitle?: string
  unfinishedSteps: PublicationStep[]
  updatedDate: moment.Moment
  resolutionDate?: moment.Moment
  key?: number
}

/* ----------- LISTS ENUM TYPES -------------  */
export enum BookPublicationsListType {
  SelfPending,
  Pending,
  Rejected,
  Completed,
  All,
}
