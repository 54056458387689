import React, { FC, useState, useEffect } from 'react'
import { Form } from 'antd'
import Configs from 'configs/configs'
import {
  TableDiv,
  TableAddButton,
} from 'features/bookPublicationForm/screens/styles'
import { UIDeliverable } from 'features/bookPublicationForm/types'
import EditableTable from 'common/components/EditableTable'

const EditableAuthoringProgressTable: FC<any> = (props) => {
  const defaults: UIDeliverable[] = props.defaults
  const { index, disabled, setDataRef, setHasFormChanged } = props

  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState<UIDeliverable[]>(defaults || [])

  // Whenever we've got an dataSource state change
  // perform the update on the parent component
  useEffect(() => {
    setDataRef(dataSource, index)
    if (dataSource !== defaults) {
      setHasFormChanged()
    }
  }, [dataSource]) // eslint-disable-line

  useEffect(() => {
    setDataSource(defaults)
  }, [defaults])

  const dataColumns = [
    {
      title: 'ID',
      dataIndex: 'key',
      render: (record) => record + 1,
      editable: false,
    },
    {
      title: 'Όνομα',
      dataIndex: 'name',
      render: (record) => record ?? '-',
      editable: false,
      inputType: 'text',
      width: '35%',
    },
    {
      title: 'Επιθυμητή Ημερομηνία',
      dataIndex: 'expectedDate',
      render: (record) => (!record ? '-' : record.format(Configs.Date.Format)),
      editable: false,
      inputType: 'date',
      width: '20%',
    },
    {
      title: 'Ημερομηνία Παράδοσης',
      dataIndex: 'deliveredDate',
      render: (record) => (!record ? '-' : record.format(Configs.Date.Format)),
      editable: true,
      inputType: 'date',
      width: '20%',
    },
  ]

  return (
    <TableDiv>
      <Form form={form} component={false}>
        <EditableTable
          disabled={disabled}
          dataSource={dataSource}
          dataColumns={dataColumns}
          setParentData={(newData) => setDataSource(newData)}
        />
      </Form>
      <TableAddButton onClick={props.onButtonClick} disabled={disabled}>
        Προσθήκη Παραδοτέων
      </TableAddButton>
    </TableDiv>
  )
}

export default EditableAuthoringProgressTable
