export const WrittenFormatList = [
  'Word',
  'Word με MathType',
  'Word με Equator Manager',
  'Latex',
  'Χειρόγραφο / Γραφομηχανή',
]

export const BookDimensions = [
  '12 x 17',
  '14 x 21',
  '17 x 24',
  '22 x 24',
  '21 x 28',
  'Άλλο',
]
