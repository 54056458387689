import React, { FC } from 'react'
import { Button } from 'antd'
import { CardWrapper } from 'features/bookPublicationForm/screens/styles'

const AddProgressCard: FC<any> = (props) => {
  return (
    <CardWrapper
      title={props.title}
      actions={[
        <Button type="primary" shape="round" onClick={props.onButtonClick}>
          Προσθέστε
        </Button>,
      ]}
      style={{ backgroundColor: '#fff' }}
      bodyStyle={{ padding: '0' }}
    />
  )
}

export default AddProgressCard
