import React, {
  useState,
  useEffect,
  forwardRef,
  FC,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import { Form, DatePicker, Select, Modal, Row, Col, Button } from 'antd'
import Configs from 'configs/configs'
import {
  TableDiv,
  TableAddButton,
  SelectWide,
  ModalColFormItem,
  FormHeader,
} from 'features/bookPublicationForm/screens/styles'
import EditableTable from 'common/components/EditableTable'
import { UIContract, UIAuthor } from 'features/bookPublicationForm/types'
import { CSVLink } from 'react-csv'
import { DownloadOutlined } from '@ant-design/icons'
import { getContractCsvItem } from 'features/bookPublicationForm/utils'
import { sorterDateCompare, sorterStringCompare } from 'common/utils'

const { Option } = Select
const { RangePicker } = DatePicker

const ModalForm: FC<any> = (props, ref) => {
  const [form] = Form.useForm()
  const { visible, onCancel, onCreate, authors } = props

  useImperativeHandle(ref, () => ({
    form: form,
  }))

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onCreate}
      title="Προσθήκη Αποστολής"
      okText="Προσθήκη"
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Συγγραφέας"
          name="author"
          rules={[{ required: true, message: Configs.Messages.RequiredField }]}
        >
          <SelectWide placeholder="Επιλέξτε Συγγραφέα">
            {authors.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.firstname} {item.lastname} - {item.email}
              </Option>
            ))}
          </SelectWide>
        </Form.Item>
        <FormHeader>Σχέδιο Συμφωνίας</FormHeader>
        <Row>
          <Col span={12}>
            <ModalColFormItem
              label="Ημερομηνία Αποστολής Σχεδίου Συμφωνίας"
              name="draftSentDate"
            >
              <DatePicker
                format={Configs.Date.Format}
                placeholder={Configs.Date.Placeholder}
              />
            </ModalColFormItem>
          </Col>
          <Col span={12}>
            <ModalColFormItem
              label="Ημερομηνία Αποδοχής Σχεδίου Συμφωνίας"
              name="draftApprovalDate"
            >
              <DatePicker
                format={Configs.Date.Format}
                placeholder={Configs.Date.Placeholder}
              />
            </ModalColFormItem>
          </Col>
        </Row>
        <FormHeader>Συμβόλαιο</FormHeader>
        <Row>
          <Col span={12}>
            <ModalColFormItem
              label="Ημερομηνία Αποστολής συμβολαίου"
              name="finalSentDate"
            >
              <DatePicker
                format={Configs.Date.Format}
                placeholder={Configs.Date.Placeholder}
              />
            </ModalColFormItem>
          </Col>
          <Col span={12}>
            <ModalColFormItem
              label="Ημερομηνία Υπογραφής συμβολαίου"
              name="signDate"
            >
              <DatePicker
                format={Configs.Date.Format}
                placeholder={Configs.Date.Placeholder}
              />
            </ModalColFormItem>
          </Col>
        </Row>
        <Form.Item label="Νομική ισχύς συμβολαίου" name="contractEffectDates">
          <RangePicker
            format={Configs.Date.Format}
            placeholder={['Έναρξη', 'Λήξη']}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ModalAddForm = forwardRef(ModalForm)

const EditableContractsTable: FC<any> = (props) => {
  const defaults: UIContract[] = props.defaults
  const authors: UIAuthor[] = props.authors
  const { disabled, setHasFormChanged } = props

  const [dataSource, setDataSource] = useState<UIContract[]>(defaults || [])
  const [availableAuthors, setAvailableAuthors] = useState<UIAuthor[]>([])
  const [modalDisplay, setModalDisplay] = useState<boolean>(false)

  const csvHeaders = [
    { label: 'Συγγραφέας', key: 'author' },
    { label: 'Αποστολή Σχεδίου Συμφωνίας', key: 'draftSentDate' },
    { label: 'Αποδοχή Σχεδίου Συμφωνίας	', key: 'draftApprovalDate' },
    { label: 'Αποστολή Συμβολαίου	', key: 'finalSentDate' },
    { label: 'Υπογραφή Συμβολαίου	', key: 'signDate' },
    { label: 'Έναρξη Ισχύος Συμβολαίου', key: 'contractStartDate' },
    { label: 'Λήξη Ισχύος Συμβολαίου', key: 'contractEndDate' },
  ]
  const [csvData, setCsvData] = useState<any>([])

  useEffect(() => {
    setDataSource(defaults)
  }, [defaults])

  useEffect(() => {
    // we want to exclude authors already in the table
    if (dataSource) {
      setAvailableAuthors(
        authors.filter(
          (x) => !dataSource.map((el) => el.author.id).includes(x.id)
        )
      )
      setCsvData(dataSource.map((el) => getContractCsvItem(el)))
    }
  }, [authors, dataSource])

  const dataColumns = [
    {
      title: 'Συγγραφέας',
      dataIndex: 'author',
      sorter: (a, b) =>
        sorterStringCompare(a.author.lastname, b.author.lastname),
      render: (record: UIAuthor) => (
        <span>
          {record.lastname} {record.firstname}
        </span>
      ),
      editable: false,
    },
    {
      title: 'Αποστολή Σχεδίου Συμφωνίας',
      dataIndex: 'draftSentDate',
      sorter: (a, b) => sorterDateCompare(a.draftSentDate, b.draftSentDate),
      render: (record) => (!record ? '-' : record.format(Configs.Date.Format)),
      editable: true,
      inputType: 'date',
    },
    {
      title: 'Αποδοχή Σχεδίου Συμφωνίας',
      dataIndex: 'draftApprovalDate',
      sorter: (a, b) =>
        sorterDateCompare(a.draftApprovalDate, b.draftApprovalDate),
      render: (record) => (!record ? '-' : record.format(Configs.Date.Format)),
      editable: true,
      inputType: 'date',
    },
    {
      title: 'Αποστολή Συμβολαίου',
      dataIndex: 'finalSentDate',
      sorter: (a, b) => sorterDateCompare(a.finalSentDate, b.finalSentDate),
      render: (record) => (!record ? '-' : record.format(Configs.Date.Format)),
      editable: true,
      inputType: 'date',
    },
    {
      title: 'Υπογραφή Συμβολαίου',
      dataIndex: 'signDate',
      sorter: (a, b) => sorterDateCompare(a.signDate, b.signDate),
      render: (record) => (!record ? '-' : record.format(Configs.Date.Format)),
      editable: true,
      inputType: 'date',
    },
    {
      title: 'Ισχύς Συμβολαίου',
      dataIndex: 'contractEffectDates',
      sorter: (a, b) =>
        sorterDateCompare(
          a.contractEffectDates?.[0],
          b.contractEffectDates?.[0]
        ),
      render: (record) =>
        !(record && record[0] && record[1]) ? (
          '-'
        ) : (
          <span>
            {record[0].format(Configs.Date.Format)} -{' '}
            {record[1].format(Configs.Date.Format)}
          </span>
        ),
      editable: true,
      inputType: 'dateRange',
    },
  ]

  const getNewRowKey = useCallback(() => {
    return dataSource.length > 0
      ? Math.max(...dataSource.map((item) => +item.key!)) + 1
      : 0
  }, [dataSource])

  // Whenever we've got an data state change
  // inform the dataRef of the parent component
  useEffect(() => {
    if (props.dataRef && dataSource) {
      // update but keep ids from newly POSTed entities
      const currContractsSentTable: UIContract[] =
        props.dataRef.current.contractsSentTable
      props.dataRef.current.contractsSentTable = dataSource.map((item) => ({
        ...item,
        id:
          item.id ??
          currContractsSentTable.find((el) => el.author.id === item.author.id)
            ?.id,
      }))
    }
    if (dataSource !== defaults) {
      setHasFormChanged()
    }
  }, [dataSource]) // eslint-disable-line

  const modalOpenHandler = useCallback(() => {
    if (availableAuthors.length) {
      setModalDisplay(true)
    } else if (!authors.length) {
      // no authors have been added yet
      Modal.warning({
        title: Configs.Messages.NoAuthors.title,
        content: Configs.Messages.NoAuthors.content,
      })
    } else {
      Modal.warning({
        title: Configs.Messages.NoAvailableAuthors.title,
        content: Configs.Messages.NoAvailableAuthors.content,
      })
    }
  }, [authors.length, availableAuthors.length])

  const modalCancelHandler = useCallback(() => {
    const { form } = modalAddFormRef.current
    setModalDisplay(false)
    form.resetFields()
  }, [])

  const modalCreateHandler = useCallback(() => {
    const { form } = modalAddFormRef.current

    form.validateFields().then((values) => {
      values.author = authors.find((item) => item.id === values.author)
      setDataSource((prevValues) => [
        ...prevValues,
        { ...values, key: getNewRowKey() },
      ])
      form.resetFields()
      setModalDisplay(false)
    })
  }, [getNewRowKey, authors])

  const modalAddFormRef = useRef<any>(null)

  return (
    <TableDiv>
      <EditableTable
        disabled={disabled}
        dataSource={dataSource}
        dataColumns={dataColumns}
        setParentData={(newData) => setDataSource(newData)}
      />
      <Row justify="space-between">
        <TableAddButton onClick={modalOpenHandler} disabled={disabled}>
          Προσθήκη Αποστολής
        </TableAddButton>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          separator={';'}
          style={{ marginTop: 7 }}
        >
          <Button icon={<DownloadOutlined />}>Εξαγωγή</Button>
        </CSVLink>
      </Row>
      <ModalAddForm
        visible={modalDisplay}
        onCancel={modalCancelHandler}
        onCreate={modalCreateHandler}
        authors={availableAuthors}
        ref={(form) => (modalAddFormRef.current = form)}
      />
    </TableDiv>
  )
}

export default EditableContractsTable
