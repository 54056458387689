import {
  call,
  put,
  all,
  takeLatest,
  takeEvery,
  select,
  take,
} from 'redux-saga/effects'

import Services from 'services'
import ActionNames from './actionNames'
import ActionCreators from './actionCreators'
import Endpoints from 'api/endpoints'
import { CommonActionCreators } from 'common/ducks'
import Dtos, {
  IBookCategory,
  IBookProducer,
  ISeries,
  IUser,
} from '@hypernetica/gutenberg-bpm-common'
import { IDataObject } from '@hypernetica/json-api'
import {
  AuthoringStepData,
  BookDetailsStepData,
  ContractSigningStepData,
  IEntitiesErrorState,
  IGetEntitiesState,
  IPostEntitiesState,
  ManuscriptVerificationStepData,
  PublicationDetailsStepData,
  PublicationVerificationStepData,
  TechnicalInstructionsStepData,
  UIAuthor,
  UIAuthorProgress,
  UIBook,
  UIBookSeries,
  UIContract,
  UIDistribution,
  UITechnicalInstruction,
} from 'features/bookPublicationForm/types'
import * as Selectors from './selectors'
import _ from 'lodash'
import {
  ForceArray,
  includesIgnoringKey,
  removeEmptyObjects,
} from 'common/utils'
import {
  getBookContentsFromBookDetailsStep,
  mapAuthoringProgressUiToDto,
  mapBookCategoryUiToDto,
  mapBookDetailsUiToDto,
  mapBookProducerUiToDto,
  mapBookPublicationUiToDto,
  mapCommentUiToDto,
  mapContractUiToDto,
  mapDistributionDetailsUiToDto,
  mapSeriesUiToDto,
  mapStepSectionUiToDto,
  mapTechnicalDetailsUiToDto,
} from 'api/mappers'

/* ----------- AUTHORS -------------  */
function* getAuthorsFlowWatcher() {
  yield takeLatest(ActionNames.GET_AUTHORS_REQUESTED, getAuthors)
}

function* getAuthors() {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.get as any,
      Endpoints.Entities.Authors,
      {}
    )

    const returnDto: IBookProducer[] = ForceArray(result).map((el) =>
      Dtos.BookProducerDto.fromIDataObject(el)
    )
    yield put(ActionCreators.getAuthorsSucceeded(returnDto))
  } catch (error) {
    yield put(ActionCreators.getAuthorsFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* postAuthorFlowWatcher() {
  yield takeEvery(ActionNames.POST_AUTHOR_REQUESTED, postAuthor)
}

function* postAuthor(action) {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postAuthor } = action.payload.author
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.Authors,
      {
        body: Dtos.BookProducerDto.toIDataObject(
          new Dtos.BookProducerDto(mapBookProducerUiToDto(postAuthor))
        ),
      }
    )

    yield put(ActionCreators.postAuthorSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postAuthorFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchAuthorFlowWatcher() {
  yield takeEvery(ActionNames.PATCH_AUTHOR_REQUESTED, patchAuthor)
}

function* patchAuthor(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    yield call(
      Services.Api.patch as any,
      Endpoints.Entities.Authors + '/' + payload.author.id,
      {
        body: Dtos.BookProducerDto.toIDataObject(
          new Dtos.BookProducerDto(mapBookProducerUiToDto(payload.author))
        ),
      }
    )

    yield put(ActionCreators.patchAuthorSucceeded())
  } catch (error) {
    yield put(ActionCreators.patchAuthorFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- INSTITUTION DETAILS -------------  */
function* getInstitutionDetailsFlowWatcher() {
  yield takeLatest(
    ActionNames.GET_INSTITUTION_DETAILS_REQUESTED,
    getInstitutionDetails
  )
}

function* getInstitutionDetails() {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.get as any,
      Endpoints.Entities.InstitutionDetails,
      {}
    )

    const returnDto = ForceArray(result).map((el) =>
      Dtos.InstitutionDetailsDto.fromIDataObject(el)
    )
    yield put(ActionCreators.getInstitutionDetailsSucceeded(returnDto))
  } catch (error) {
    yield put(ActionCreators.getInstitutionDetailsFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- BOOK CATEGORIES -------------  */
function* getBookCategoriesFlowWatcher() {
  yield takeLatest(ActionNames.GET_BOOK_CATEGORIES_REQUESTED, getBookCategories)
}

function* getBookCategories() {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result: IDataObject[] = yield call(
      Services.Api.get as any,
      Endpoints.Entities.BookCategories,
      {}
    )

    const returnDto: IBookCategory[] = ForceArray(result).map((el) =>
      Dtos.BookCategoryDto.fromIDataObject(el)
    )
    yield put(ActionCreators.getBookCategoriesSucceeded(returnDto))
  } catch (error) {
    yield put(ActionCreators.getBookCategoriesFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* postBookCategoryFlowWatcher() {
  yield takeEvery(ActionNames.POST_BOOK_CATEGORY_REQUESTED, postBookCategory)
}

function* postBookCategory(action) {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postBookCategory } = action.payload.bookCategory
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.BookCategories,
      {
        body: Dtos.BookCategoryDto.toIDataObject(
          new Dtos.BookCategoryDto(mapBookCategoryUiToDto(postBookCategory))
        ),
      }
    )

    yield put(ActionCreators.postBookCategorySucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postBookCategoryFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- BOOKS -------------  */
function* postBookFlowWatcher() {
  yield takeEvery(ActionNames.POST_BOOK_REQUESTED, postBook)
}

function* postBook(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postBook } = payload.book
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.Book,
      {
        body: Dtos.BookDto.toIDataObject(
          new Dtos.BookDto(mapBookDetailsUiToDto(postBook))
        ),
      }
    )

    yield put(ActionCreators.postBookSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postBookFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchBookFlowWatcher() {
  yield takeEvery(ActionNames.PATCH_BOOK_REQUESTED, patchBook)
}

function* patchBook(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.patch as any,
      Endpoints.Entities.Book + '/' + payload.book.id,
      {
        body: Dtos.BookDto.toIDataObject(
          new Dtos.BookDto(mapBookDetailsUiToDto(payload.book))
        ),
      }
    )

    yield put(ActionCreators.patchBookSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.patchBookFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- BOOK DISTRIBUTIONS -------------  */
function* postBookDistributionFlowWatcher() {
  yield takeEvery(
    ActionNames.POST_BOOK_DISTRIBUTION_REQUESTED,
    postBookDistribution
  )
}

function* postBookDistribution(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postBookDistribution } = payload.bookDistribution
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.BookDistributions,
      {
        body: Dtos.DistributionDetailsDto.toIDataObject(
          new Dtos.DistributionDetailsDto(
            mapDistributionDetailsUiToDto(postBookDistribution)
          )
        ),
      }
    )

    yield put(ActionCreators.postBookDistributionSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postBookDistributionFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchBookDistributionFlowWatcher() {
  yield takeEvery(
    ActionNames.PATCH_BOOK_DISTRIBUTION_REQUESTED,
    patchBookDistribution
  )
}

function* patchBookDistribution(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.patch as any,
      Endpoints.Entities.BookDistributions + '/' + payload.bookDistribution.id,
      {
        body: Dtos.DistributionDetailsDto.toIDataObject(
          new Dtos.DistributionDetailsDto(
            mapDistributionDetailsUiToDto(payload.bookDistribution)
          )
        ),
      }
    )

    yield put(ActionCreators.patchBookDistributionSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.patchBookDistributionFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- CONTRACTS -------------  */
function* postContractFlowWatcher() {
  yield takeEvery(ActionNames.POST_CONTRACT_REQUESTED, postContract)
}

function* postContract(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postContract } = payload.contract
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.Contracts,
      {
        body: Dtos.ContractDto.toIDataObject(
          new Dtos.ContractDto(mapContractUiToDto(postContract))
        ),
      }
    )

    yield put(ActionCreators.postContractSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postContractFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchContractFlowWatcher() {
  yield takeEvery(ActionNames.PATCH_CONTRACT_REQUESTED, patchContract)
}

function* patchContract(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.patch as any,
      Endpoints.Entities.Contracts + '/' + payload.contract.id,
      {
        body: Dtos.ContractDto.toIDataObject(
          new Dtos.ContractDto(mapContractUiToDto(payload.contract))
        ),
      }
    )

    yield put(ActionCreators.patchContractSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.patchContractFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- TECHNICAL INSTRUCTIONS -------------  */
function* postTechnicalInstructionFlowWatcher() {
  yield takeEvery(
    ActionNames.POST_TECHNICAL_INSTRUCTION_REQUESTED,
    postTechnicalInstruction
  )
}

function* postTechnicalInstruction(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postTechnicalInstruction } = payload.technicalInstruction
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.TechnicalInstructions,
      {
        body: Dtos.TechnicalDetailsDto.toIDataObject(
          new Dtos.TechnicalDetailsDto(
            mapTechnicalDetailsUiToDto(postTechnicalInstruction)
          )
        ),
      }
    )

    yield put(ActionCreators.postTechnicalInstructionSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postTechnicalInstructionFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchTechnicalInstructionFlowWatcher() {
  yield takeEvery(
    ActionNames.PATCH_TECHNICAL_INSTRUCTION_REQUESTED,
    patchTechnicalInstruction
  )
}

function* patchTechnicalInstruction(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.patch as any,
      Endpoints.Entities.TechnicalInstructions +
        '/' +
        payload.technicalInstruction.id,
      {
        body: Dtos.TechnicalDetailsDto.toIDataObject(
          new Dtos.TechnicalDetailsDto(
            mapTechnicalDetailsUiToDto(payload.technicalInstruction)
          )
        ),
      }
    )

    yield put(ActionCreators.patchTechnicalInstructionSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.patchTechnicalInstructionFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- AUTHORING PROGRESS -------------  */
function* postAuthoringProgressFlowWatcher() {
  yield takeEvery(
    ActionNames.POST_AUTHORING_PROGRESS_REQUESTED,
    postAuthoringProgress
  )
}

function* postAuthoringProgress(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postAuthoringProgress } = payload.authoringProgress
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.AuthoringProgress,
      {
        body: Dtos.AuthoringProgressDto.toIDataObject(
          new Dtos.AuthoringProgressDto(
            mapAuthoringProgressUiToDto(postAuthoringProgress)
          )
        ),
      }
    )

    yield put(ActionCreators.postAuthoringProgressSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postAuthoringProgressFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchAuthoringProgressFlowWatcher() {
  yield takeEvery(
    ActionNames.PATCH_AUTHORING_PROGRESS_REQUESTED,
    patchAuthoringProgress
  )
}

function* patchAuthoringProgress(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.patch as any,
      Endpoints.Entities.AuthoringProgress + '/' + payload.authoringProgress.id,
      {
        body: Dtos.AuthoringProgressDto.toIDataObject(
          new Dtos.AuthoringProgressDto(
            mapAuthoringProgressUiToDto(payload.authoringProgress)
          )
        ),
      }
    )

    yield put(ActionCreators.patchAuthoringProgressSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.patchAuthoringProgressFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- BOOK SERIES -------------  */
function* getBookSeriesFlowWatcher() {
  yield takeLatest(ActionNames.GET_BOOK_SERIES_REQUESTED, getBookSeries)
}

function* getBookSeries() {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result: IDataObject[] = yield call(
      Services.Api.get as any,
      Endpoints.Entities.BookSeries,
      {}
    )

    const returnDto: ISeries[] = ForceArray(result).map((el) =>
      Dtos.SeriesDto.fromIDataObject(el)
    )
    yield put(ActionCreators.getBookSeriesSucceeded(returnDto))
  } catch (error) {
    yield put(ActionCreators.getBookSeriesFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* postBookSeriesFlowWatcher() {
  yield takeEvery(ActionNames.POST_BOOK_SERIES_REQUESTED, postBookSeries)
}

function* postBookSeries(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postBookSeries } = payload.bookSeries
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.BookSeries,
      {
        body: Dtos.SeriesDto.toIDataObject(
          new Dtos.SeriesDto(mapSeriesUiToDto(postBookSeries))
        ),
      }
    )

    yield put(ActionCreators.postBookSeriesSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postBookSeriesFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- USERS -------------  */
function* getUsersFlowWatcher() {
  yield takeLatest(ActionNames.GET_USERS_REQUESTED, getUsers)
}

function* getUsers() {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result: IDataObject[] = yield call(
      Services.Api.get as any,
      Endpoints.Entities.Users,
      {}
    )

    const returnDto: IUser[] = ForceArray(result).map((el) =>
      Dtos.UserDto.fromIDataObject(el)
    )
    yield put(ActionCreators.getUsersSucceeded(returnDto))
  } catch (error) {
    yield put(ActionCreators.getUsersFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- COMMENTS -------------  */
function* postCommentFlowWatcher() {
  yield takeLatest(ActionNames.POST_COMMENT_REQUESTED, postComment)
}

function* postComment(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const { id, ...postComment } = payload.comment
    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.Comments,
      {
        body: Dtos.CommentDto.toIDataObject(
          new Dtos.CommentDto(mapCommentUiToDto(postComment))
        ),
      }
    )

    // Connect comment with step:
    yield call(
      Services.Api.post as any,
      Endpoints.Entities.Steps + '/' + payload.stepId + '/relationships/notes',
      {
        body: result,
      }
    )

    yield put(ActionCreators.postCommentSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.postCommentFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchCommentFlowWatcher() {
  yield takeLatest(ActionNames.PATCH_COMMENT_REQUESTED, patchComment)
}

function* patchComment(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.patch as any,
      Endpoints.Entities.Comments + '/' + payload.comment.id,
      {
        body: Dtos.CommentDto.toIDataObject(
          new Dtos.CommentDto(mapCommentUiToDto(payload.comment))
        ),
      }
    )

    yield put(ActionCreators.patchCommentSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.patchCommentFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* deleteCommentFlowWatcher() {
  yield takeLatest(ActionNames.DELETE_COMMENT_REQUESTED, deleteComment)
}

function* deleteComment(action) {
  const { commentId, stepId } = action.payload
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    // Delete relationship with step first:
    yield call(
      Services.Api.delete as any,
      Endpoints.Entities.Steps +
        '/' +
        stepId +
        '/relationships/notes/' +
        commentId,
      {}
    )

    // Delete the comment itself:
    yield call(
      Services.Api.delete as any,
      Endpoints.Entities.Comments + '/' + commentId,
      {}
    )

    yield put(ActionCreators.deleteCommentSucceeded())
  } catch (error) {
    yield put(ActionCreators.deleteCommentFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- STEPS -------------  */
function* getStepFlowWatcher() {
  yield takeLatest(ActionNames.GET_STEP_REQUESTED, getStep)
}

function* getStep(action) {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result: IDataObject = yield call(
      Services.Api.get as any,
      Endpoints.Entities.Steps + '/' + action.payload.id,
      {}
    )

    const returnDto = Dtos.StepDto.fromIDataObject(result)
    yield put(ActionCreators.getStepSucceeded(returnDto))
  } catch (error) {
    yield put(ActionCreators.getStepFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchStepFlowWatcher() {
  yield takeLatest(ActionNames.PATCH_STEP_REQUESTED, patchStep)
}

function* patchStep(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.patch as any,
      Endpoints.Entities.Steps + '/' + payload.step.stepEntityId,
      {
        body: Dtos.StepDto.toIDataObject(
          new Dtos.StepDto(mapStepSectionUiToDto(payload.step))
        ),
      }
    )

    yield put(ActionCreators.patchStepSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.patchStepFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- BOOK PUBLICATION -------------  */
function* initBookPublicationFlowWatcher() {
  yield takeEvery(
    ActionNames.INIT_BOOK_PUBLICATION_REQUESTED,
    initBookPublication
  )
}

function* initBookPublication() {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.post as any,
      Endpoints.Entities.BookPublication,
      {}
    )

    yield put(ActionCreators.initBookPublicationSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.initBookPublicationFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* patchBookPublicationFlowWatcher() {
  yield takeEvery(
    ActionNames.PATCH_BOOK_PUBLICATION_REQUESTED,
    patchBookPublication
  )
}

function* patchBookPublication(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.patch as any,
      Endpoints.Entities.BookPublication + '/' + payload.bookPublication.id,
      {
        body: Dtos.BookPublicationDto.toIDataObject(
          new Dtos.BookPublicationDto(
            mapBookPublicationUiToDto(payload.bookPublication)
          )
        ),
      }
    )

    yield put(ActionCreators.patchBookPublicationSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.patchBookPublicationFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* deleteBookPublicationFlowWatcher() {
  yield takeEvery(
    ActionNames.DELETE_BOOK_PUBLICATION_REQUESTED,
    deleteBookPublication
  )
}

function* deleteBookPublication(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.delete as any,
      Endpoints.Entities.BookPublication + '/' + payload.id,
      {}
    )

    yield put(ActionCreators.deleteBookPublicationSucceeded(result))
  } catch (error) {
    yield put(ActionCreators.deleteBookPublicationFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/* ----------- STEP SUBMISSION -------------  */
/* Submission logic:
  1) For every entity included in a step:
    1a) Do nothing if it was unchanged (included in 'defaults') 
    1b) If it was created/edited during this step then dispatch the respective POST/PATCH in parallel.
  2) Block until all POST/PATCH calls have completed (successfully or not).
  3) If any call failed, submission fails.
  4) Else, update stepData with the POSTed entities' ids.
  5) stepData can now be PATCH to BookPublication.
*/

function* submitBookDetailsFlowWatcher() {
  yield takeEvery(ActionNames.SUBMIT_BOOK_DETAILS_REQUESTED, submitBookDetails)
}

function* submitBookDetails(action) {
  let stepData: BookDetailsStepData = action.payload.stepData
  const defaults: BookDetailsStepData = action.payload.defaults
  const currAuthors: UIAuthor[] = stepData.authors
  const currBookSeries: UIBookSeries | undefined = stepData.bookSeries

  try {
    yield put(CommonActionCreators.uiLoadingStarted())
    yield put(ActionCreators.submitStepStarted())

    if (
      !_.isEqual(removeEmptyObjects(stepData), removeEmptyObjects(defaults))
    ) {
      // POST/PATCH Book related entities
      for (let item of currAuthors) {
        if (!includesIgnoringKey(defaults.authors, item)) {
          if (item.id) {
            yield put(ActionCreators.patchAuthorRequested(item))
          } else {
            yield put(ActionCreators.postAuthorRequested(item))
          }
        }
      }
      // TODO: for item of defaults not in instructionsSentTable: DELETE

      // We aren't POSTing BookSeries if it already exists
      const stateGetData: IGetEntitiesState = yield select(Selectors.getData)
      if (
        currBookSeries?.name &&
        defaults.bookSeries !== currBookSeries &&
        defaults.bookSeries?.name !== currBookSeries?.name &&
        !stateGetData.bookSeries
          .map((el) => el.name)
          .includes(currBookSeries?.name)
      ) {
        yield put(ActionCreators.postBookSeriesRequested(currBookSeries))
      }

      // Wait actions to complete:
      for (let item of currAuthors) {
        if (!includesIgnoringKey(defaults.authors, item)) {
          if (item.id) {
            yield take([
              ActionNames.PATCH_AUTHOR_SUCCEEDED,
              ActionNames.PATCH_AUTHOR_FAILED,
            ])
          } else {
            yield take([
              ActionNames.POST_AUTHOR_SUCCEEDED,
              ActionNames.POST_AUTHOR_FAILED,
            ])
          }
        }
      }
      if (
        currBookSeries?.name &&
        defaults.bookSeries !== currBookSeries &&
        defaults.bookSeries?.name !== currBookSeries?.name &&
        !stateGetData.bookSeries
          .map((el) => el.name)
          .includes(currBookSeries?.name)
      ) {
        yield take([
          ActionNames.POST_BOOK_SERIES_SUCCEEDED,
          ActionNames.POST_BOOK_SERIES_FAILED,
        ])
      }

      let statePostData: IPostEntitiesState = yield select(Selectors.postData)
      let stateErrors: IEntitiesErrorState = yield select(Selectors.errors)
      if (
        stateErrors.postAuthorErrors.length ||
        stateErrors.patchAuthorErrors.length ||
        stateErrors.postBookCategoryErrors.length ||
        stateErrors.postBookSeriesErrors.length
      ) {
        throw stateErrors.postAuthorErrors.concat(
          stateErrors.patchAuthorErrors,
          stateErrors.postBookCategoryErrors,
          stateErrors.postBookSeriesErrors
        )
      }

      // Update included entities' ids from those just POSTed:
      stepData.authors = currAuthors.map((el) =>
        el.id
          ? el
          : {
              ...statePostData.authors.find((item) => item.email === el.email)!,
              key: el.key,
            }
      )
      if (currBookSeries && !currBookSeries.id) {
        stepData.bookSeries = statePostData.bookSeries!
      }

      // POST/PATCH Book entity itself
      const currBook = {
        ...getBookContentsFromBookDetailsStep(stepData),
        id: defaults.bookEntityId,
      } as UIBook
      const defaultsBook = {
        ...getBookContentsFromBookDetailsStep(defaults),
        id: defaults.bookEntityId,
      } as UIBook

      if (
        !_.isEqual(
          removeEmptyObjects(currBook),
          removeEmptyObjects(defaultsBook)
        )
      ) {
        // TODO: proper defaults checking
        if (defaults.bookEntityId) {
          yield put(ActionCreators.patchBookRequested(currBook))
        } else {
          yield put(ActionCreators.postBookRequested(currBook))
        }

        if (defaults.bookEntityId) {
          yield take([
            ActionNames.PATCH_BOOK_SUCCEEDED,
            ActionNames.PATCH_BOOK_FAILED,
          ])
        } else {
          yield take([
            ActionNames.POST_BOOK_SUCCEEDED,
            ActionNames.POST_BOOK_FAILED,
          ])
        }

        statePostData = yield select(Selectors.postData)
        stateErrors = yield select(Selectors.errors)
        if (
          stateErrors.postBookErrors.length ||
          stateErrors.patchBookErrors.length
        ) {
          throw stateErrors.postBookErrors.concat(stateErrors.patchBookErrors)
        }

        if (!stepData.bookEntityId) {
          stepData.bookEntityId = statePostData.book!.id
        }
      }

      if (
        !_.isEqual(
          removeEmptyObjects(stepData.completion),
          removeEmptyObjects(defaults.completion)
        )
      ) {
        yield put(ActionCreators.patchStepRequested(stepData.completion))
      }

      yield put(
        ActionCreators.submitBookDetailsSucceeded({
          bookDetails: stepData,
        })
      )
    }
  } catch (error) {
    yield put(ActionCreators.submitBookDetailsFailed(error))
  } finally {
    yield put(ActionCreators.submitStepFinished())
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* submitPublicationDetailsFlowWatcher() {
  yield takeEvery(
    ActionNames.SUBMIT_PUBLICATION_DETAILS_REQUESTED,
    submitPublicationDetails
  )
}

function* submitPublicationDetails(action) {
  let stepData: PublicationDetailsStepData = action.payload.stepData
  const defaults: PublicationDetailsStepData = action.payload.defaults
  const currDistributionsTable: UIDistribution[] = stepData.distributionsTable

  try {
    yield put(CommonActionCreators.uiLoadingStarted())
    yield put(ActionCreators.submitStepStarted())

    if (
      !_.isEqual(removeEmptyObjects(stepData), removeEmptyObjects(defaults))
    ) {
      // POST/PATCH Distributions' authors
      for (let item of currDistributionsTable) {
        for (let tutor of item.tutors) {
          if (tutor.id) {
            yield put(ActionCreators.patchAuthorRequested(tutor))
          } else {
            yield put(ActionCreators.postAuthorRequested(tutor))
          }
        }
      }
      // TODO: for item of defaults not in instructionsSentTable: DELETE

      // Wait actions to complete:
      for (let item of currDistributionsTable) {
        for (let tutor of item.tutors) {
          if (tutor.id) {
            yield take([
              ActionNames.PATCH_AUTHOR_SUCCEEDED,
              ActionNames.PATCH_AUTHOR_FAILED,
            ])
          } else {
            yield take([
              ActionNames.POST_AUTHOR_SUCCEEDED,
              ActionNames.POST_AUTHOR_FAILED,
            ])
          }
        }
      }

      let statePostData: IPostEntitiesState = yield select(Selectors.postData)
      let stateErrors: IEntitiesErrorState = yield select(Selectors.errors)
      if (
        stateErrors.postAuthorErrors.length ||
        stateErrors.patchAuthorErrors.length
      ) {
        throw stateErrors.postAuthorErrors.concat(stateErrors.patchAuthorErrors)
      }

      // Update included entities' ids from those just POSTed:
      stepData.distributionsTable.forEach((_el, index) => {
        stepData.distributionsTable[index].tutors = currDistributionsTable[
          index
        ].tutors.map((el) =>
          el.id
            ? { ...el }
            : {
                ...statePostData.authors.find(
                  (item) => item.email === el.email
                )!,
                key: el.key,
              }
        )
      })

      // POST/PATCH Distributions entities themselves
      for (let item of currDistributionsTable) {
        if (!includesIgnoringKey(defaults.distributionsTable, item)) {
          if (item.id) {
            yield put(ActionCreators.patchBookDistributionRequested(item))
          } else {
            yield put(ActionCreators.postBookDistributionRequested(item))
          }
        }
      }
      // TODO: for item of defaults not in instructionsSentTable: DELETE

      for (let item of currDistributionsTable) {
        if (!includesIgnoringKey(defaults.distributionsTable, item)) {
          if (item.id) {
            yield take([
              ActionNames.PATCH_BOOK_DISTRIBUTION_SUCCEEDED,
              ActionNames.PATCH_BOOK_DISTRIBUTION_FAILED,
            ])
          } else {
            yield take([
              ActionNames.POST_BOOK_DISTRIBUTION_SUCCEEDED,
              ActionNames.POST_BOOK_DISTRIBUTION_FAILED,
            ])
          }
        }
      }

      statePostData = yield select(Selectors.postData)
      stateErrors = yield select(Selectors.errors)

      if (
        stateErrors.postBookDistributionErrors.length ||
        stateErrors.patchBookDistributionErrors.length
      ) {
        throw stateErrors.postBookDistributionErrors.concat(
          stateErrors.patchBookDistributionErrors
        )
      }

      stepData.distributionsTable = currDistributionsTable.map((el) =>
        el.id
          ? el
          : {
              ...statePostData.bookDistributions.find(
                // TODO: improve - more precise?
                (item) =>
                  item.institutionDetails?.university ===
                    el.institutionDetails?.university &&
                  item.institutionDetails?.department ===
                    el.institutionDetails?.department
              )!,
              key: el.key,
            }
      )

      if (
        !_.isEqual(
          removeEmptyObjects(stepData.completion),
          removeEmptyObjects(defaults.completion)
        )
      ) {
        yield put(ActionCreators.patchStepRequested(stepData.completion))
      }

      yield put(
        ActionCreators.submitPublicationDetailsSucceeded({
          publicationDetails: stepData,
        })
      )
    }
  } catch (error) {
    yield put(ActionCreators.submitPublicationDetailsFailed(error))
  } finally {
    yield put(ActionCreators.submitStepFinished())
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* submitContractSigningFlowWatcher() {
  yield takeEvery(
    ActionNames.SUBMIT_CONTRACT_SIGNING_REQUESTED,
    submitContractSigning
  )
}

function* submitContractSigning(action) {
  let stepData: ContractSigningStepData = action.payload.stepData
  const defaults: ContractSigningStepData = action.payload.defaults
  const currContractsSentTable: UIContract[] = stepData.contractsSentTable

  try {
    yield put(CommonActionCreators.uiLoadingStarted())
    yield put(ActionCreators.submitStepStarted())

    if (
      !_.isEqual(removeEmptyObjects(stepData), removeEmptyObjects(defaults))
    ) {
      for (let item of currContractsSentTable) {
        if (!includesIgnoringKey(defaults.contractsSentTable, item)) {
          if (item.id) {
            yield put(ActionCreators.patchContractRequested(item))
          } else {
            yield put(ActionCreators.postContractRequested(item))
          }
        }
      }
      // TODO: for item of defaults not in instructionsSentTable: DELETE

      // Wait actions to complete:
      for (let item of currContractsSentTable) {
        if (!includesIgnoringKey(defaults.contractsSentTable, item)) {
          if (item.id) {
            yield take([
              ActionNames.PATCH_CONTRACT_SUCCEEDED,
              ActionNames.PATCH_CONTRACT_FAILED,
            ])
          } else {
            yield take([
              ActionNames.POST_CONTRACT_SUCCEEDED,
              ActionNames.POST_CONTRACT_FAILED,
            ])
          }
        }
      }

      const statePostData: IPostEntitiesState = yield select(Selectors.postData)
      const stateErrors: IEntitiesErrorState = yield select(Selectors.errors)
      if (
        stateErrors.postContractErrors.length ||
        stateErrors.patchContractErrors.length
      ) {
        throw stateErrors.postContractErrors.concat(
          stateErrors.patchContractErrors
        )
      }

      // Update included entities' ids from those just POSTed:
      stepData.contractsSentTable = currContractsSentTable.map((el) =>
        el.id
          ? el
          : {
              ...statePostData.contracts.find(
                (item) => item.author.id === el.author.id
              )!,
              key: el.key,
            }
      )

      if (
        !_.isEqual(
          removeEmptyObjects(stepData.completion),
          removeEmptyObjects(defaults.completion)
        )
      ) {
        yield put(ActionCreators.patchStepRequested(stepData.completion))
      }

      yield put(
        ActionCreators.submitContractSigningSucceeded({
          contractSigning: stepData,
        })
      )
    }
  } catch (error) {
    yield put(ActionCreators.submitContractSigningFailed(error))
  } finally {
    yield put(ActionCreators.submitStepFinished())
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* submitTechnicalInstructionsFlowWatcher() {
  yield takeEvery(
    ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_REQUESTED,
    submitTechnicalInstructions
  )
}

function* submitTechnicalInstructions(action) {
  let stepData: TechnicalInstructionsStepData = action.payload.stepData
  const defaults: TechnicalInstructionsStepData = action.payload.defaults
  const currInstructionsSentTable: UITechnicalInstruction[] =
    stepData.instructionsSentTable

  try {
    yield put(CommonActionCreators.uiLoadingStarted())
    yield put(ActionCreators.submitStepStarted())

    if (
      !_.isEqual(removeEmptyObjects(stepData), removeEmptyObjects(defaults))
    ) {
      for (let item of currInstructionsSentTable) {
        if (!includesIgnoringKey(defaults.instructionsSentTable, item)) {
          if (item.id) {
            yield put(ActionCreators.patchTechnicalInstructionRequested(item))
          } else {
            yield put(ActionCreators.postTechnicalInstructionRequested(item))
          }
        }
      }
      // TODO: for item of defaults not in instructionsSentTable: DELETE

      // Wait actions to complete:
      for (let item of currInstructionsSentTable) {
        if (!includesIgnoringKey(defaults.instructionsSentTable, item)) {
          if (item.id) {
            yield take([
              ActionNames.PATCH_TECHNICAL_INSTRUCTION_SUCCEEDED,
              ActionNames.PATCH_TECHNICAL_INSTRUCTION_FAILED,
            ])
          } else {
            yield take([
              ActionNames.POST_TECHNICAL_INSTRUCTION_SUCCEEDED,
              ActionNames.POST_TECHNICAL_INSTRUCTION_FAILED,
            ])
          }
        }
      }

      const statePostData: IPostEntitiesState = yield select(Selectors.postData)
      const stateErrors: IEntitiesErrorState = yield select(Selectors.errors)
      if (
        stateErrors.postTechnicalInstructionErrors.length ||
        stateErrors.patchTechnicalInstructionErrors.length
      ) {
        throw stateErrors.postTechnicalInstructionErrors.concat(
          stateErrors.patchTechnicalInstructionErrors
        )
      }

      // Update included entities' ids from those just POSTed:
      stepData.instructionsSentTable = currInstructionsSentTable.map((el) =>
        el.id
          ? el
          : {
              ...statePostData.technicalInstructions.find(
                (item) => item.author.id === el.author.id
              )!,
              key: el.key,
            }
      )

      if (
        !_.isEqual(
          removeEmptyObjects(stepData.completion),
          removeEmptyObjects(defaults.completion)
        )
      ) {
        yield put(ActionCreators.patchStepRequested(stepData.completion))
      }

      yield put(
        ActionCreators.submitTechnicalInstructionsSucceeded({
          technicalInstructions: stepData,
        })
      )
    }
  } catch (error) {
    yield put(ActionCreators.submitTechnicalInstructionsFailed(error))
  } finally {
    yield put(ActionCreators.submitStepFinished())
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* submitAuthoringFlowWatcher() {
  yield takeEvery(ActionNames.SUBMIT_AUTHORING_REQUESTED, submitAuthoring)
}

function* submitAuthoring(action) {
  let stepData: AuthoringStepData = action.payload.stepData
  const defaults: AuthoringStepData = action.payload.defaults
  const currAuthorsProgressTable: UIAuthorProgress[] =
    stepData.authorsProgressTable

  try {
    yield put(CommonActionCreators.uiLoadingStarted())
    yield put(ActionCreators.submitStepStarted())

    if (
      !_.isEqual(removeEmptyObjects(stepData), removeEmptyObjects(defaults))
    ) {
      for (let item of currAuthorsProgressTable) {
        if (!includesIgnoringKey(defaults.authorsProgressTable, item)) {
          if (item.id) {
            yield put(ActionCreators.patchAuthoringProgressRequested(item))
          } else {
            yield put(ActionCreators.postAuthoringProgressRequested(item))
          }
        }
      }
      // TODO: for item of defaults not in authorsProgressTable: DELETE

      // Wait actions to complete:
      for (let item of currAuthorsProgressTable) {
        if (!includesIgnoringKey(defaults.authorsProgressTable, item)) {
          if (item.id) {
            yield take([
              ActionNames.PATCH_AUTHORING_PROGRESS_SUCCEEDED,
              ActionNames.PATCH_AUTHORING_PROGRESS_FAILED,
            ])
          } else {
            yield take([
              ActionNames.POST_AUTHORING_PROGRESS_SUCCEEDED,
              ActionNames.POST_AUTHORING_PROGRESS_FAILED,
            ])
          }
        }
      }

      const statePostData: IPostEntitiesState = yield select(Selectors.postData)
      const stateErrors: IEntitiesErrorState = yield select(Selectors.errors)
      if (
        stateErrors.postAuthoringProgressErrors.length ||
        stateErrors.patchAuthoringProgressErrors.length
      ) {
        throw stateErrors.postAuthoringProgressErrors.concat(
          stateErrors.patchAuthoringProgressErrors
        )
      }

      // Update included entities' ids from those just POSTed:
      stepData.authorsProgressTable = currAuthorsProgressTable.map((el) =>
        el.id
          ? el
          : {
              ...statePostData.authoringProgress.find(
                (item) => item.author.id === el.author.id
              )!,
              key: el.key,
            }
      )

      if (
        !_.isEqual(
          removeEmptyObjects(stepData.completion),
          removeEmptyObjects(defaults.completion)
        )
      ) {
        yield put(ActionCreators.patchStepRequested(stepData.completion))
      }

      yield put(
        ActionCreators.submitAuthoringSucceeded({
          authoring: stepData,
        })
      )
    }
  } catch (error) {
    yield put(ActionCreators.submitAuthoringFailed(error))
  } finally {
    yield put(ActionCreators.submitStepFinished())
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* submitManuscriptVerificationFlowWatcher() {
  yield takeEvery(
    ActionNames.SUBMIT_MANUSCRIPT_VERIFICATION_REQUESTED,
    submitManuscriptVerification
  )
}

function* submitManuscriptVerification(action) {
  let stepData: ManuscriptVerificationStepData = action.payload.stepData
  const defaults: ManuscriptVerificationStepData = action.payload.defaults

  try {
    yield put(CommonActionCreators.uiLoadingStarted())
    yield put(ActionCreators.submitStepStarted())

    // Pass-through due to lack of POST/PATCHed related entities - kept for consistency reasons
    if (
      !_.isEqual(removeEmptyObjects(stepData), removeEmptyObjects(defaults))
    ) {
      if (
        !_.isEqual(
          removeEmptyObjects(stepData.completion),
          removeEmptyObjects(defaults.completion)
        )
      ) {
        yield put(ActionCreators.patchStepRequested(stepData.completion))
      }

      yield put(
        ActionCreators.submitManuscriptVerificationSucceeded({
          manuscriptVerification: stepData,
        })
      )
    }
  } catch (error) {
    yield put(ActionCreators.submitManuscriptVerificationFailed(error))
  } finally {
    yield put(ActionCreators.submitStepFinished())
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

function* submitPublicationVerificationFlowWatcher() {
  yield takeEvery(
    ActionNames.SUBMIT_PUBLICATION_VERIFICATION_REQUESTED,
    submitPublicationVerification
  )
}

function* submitPublicationVerification(action) {
  let stepData: PublicationVerificationStepData = action.payload.stepData
  const defaults: PublicationVerificationStepData = action.payload.defaults

  try {
    yield put(CommonActionCreators.uiLoadingStarted())
    yield put(ActionCreators.submitStepStarted())

    // Pass-through due to lack of POST/PATCHed related entities - kept for consistency reasons
    if (
      !_.isEqual(removeEmptyObjects(stepData), removeEmptyObjects(defaults))
    ) {
      if (
        !_.isEqual(
          removeEmptyObjects(stepData.completion),
          removeEmptyObjects(defaults.completion)
        )
      ) {
        yield put(ActionCreators.patchStepRequested(stepData.completion))
      }

      yield put(
        ActionCreators.submitPublicationVerificationSucceeded({
          publicationVerification: stepData,
        })
      )
    }
  } catch (error) {
    yield put(ActionCreators.submitPublicationVerificationFailed(error))
  } finally {
    yield put(ActionCreators.submitStepFinished())
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

// Single entry point to start all sagas at once
export default function* rootSaga() {
  yield all([
    getAuthorsFlowWatcher(),
    postAuthorFlowWatcher(),
    patchAuthorFlowWatcher(),

    getInstitutionDetailsFlowWatcher(),

    getBookCategoriesFlowWatcher(),
    postBookCategoryFlowWatcher(),

    postBookFlowWatcher(),
    patchBookFlowWatcher(),

    postBookDistributionFlowWatcher(),
    patchBookDistributionFlowWatcher(),

    postContractFlowWatcher(),
    patchContractFlowWatcher(),

    postTechnicalInstructionFlowWatcher(),
    patchTechnicalInstructionFlowWatcher(),

    postAuthoringProgressFlowWatcher(),
    patchAuthoringProgressFlowWatcher(),

    getBookSeriesFlowWatcher(),
    postBookSeriesFlowWatcher(),

    getUsersFlowWatcher(),

    postCommentFlowWatcher(),
    patchCommentFlowWatcher(),
    deleteCommentFlowWatcher(),

    getStepFlowWatcher(),
    patchStepFlowWatcher(),

    initBookPublicationFlowWatcher(),
    patchBookPublicationFlowWatcher(),
    deleteBookPublicationFlowWatcher(),

    submitBookDetailsFlowWatcher(),
    submitPublicationDetailsFlowWatcher(),
    submitContractSigningFlowWatcher(),
    submitTechnicalInstructionsFlowWatcher(),
    submitAuthoringFlowWatcher(),
    submitManuscriptVerificationFlowWatcher(),
    submitPublicationVerificationFlowWatcher(),
  ])
}
