import React, { useState, useEffect, FC } from 'react'
import { Table, Form, DatePicker, Popconfirm, Input, InputNumber } from 'antd'
import Configs from 'configs/configs'
import {
  EditOutlined,
  CheckCircleTwoTone,
  CloseCircleOutlined,
  MinusCircleTwoTone,
} from '@ant-design/icons'

const { RangePicker } = DatePicker

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    (inputType === 'date' && (
      <DatePicker
        format={Configs.Date.Format}
        placeholder={Configs.Date.Placeholder}
      />
    )) ||
    (inputType === 'dateRange' && (
      <RangePicker
        format={Configs.Date.Format}
        placeholder={['Έναρξη', 'Λήξη']}
      />
    )) ||
    (inputType === 'number' && <InputNumber min={0} />) ||
    (inputType === 'text' && <Input />)

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const EditableTable: FC<any> = (props) => {
  const { disabled, dataSource, dataColumns } = props
  const [form] = Form.useForm()
  const [data, setData] = useState(dataSource)
  const [editingKey, setEditingKey] = useState('')

  useEffect(() => {
    setData(dataSource)
  }, [dataSource])

  const isEditing = (record) => record.key === editingKey

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex((item) => key === item.key)

      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const columns = [
    ...dataColumns,
    {
      title: ' ',
      dataIndex: 'operation',
      width: 85,
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <>
            <CheckCircleTwoTone
              onClick={() => save(record.key)}
              twoToneColor="LimeGreen"
              style={{
                fontSize: 20,
                marginRight: 8,
              }}
            />
            <CloseCircleOutlined
              onClick={() => cancel()}
              style={{ fontSize: 20 }}
            />
          </>
        ) : (
          <>
            <EditOutlined
              onClick={() => edit(record)}
              style={{ fontSize: 18, marginRight: 8 }}
            />
            <Popconfirm
              title="Θέλετε σίγουρα να διαγράψετε αυτή την εγγραφή;"
              onConfirm={() =>
                setData(data.filter((item) => item?.key !== record?.key))
              }
            >
              <MinusCircleTwoTone twoToneColor="red" style={{ fontSize: 18 }} />
            </Popconfirm>
          </>
        )
      },
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  useEffect(() => {
    props.setParentData(data)
  }, [data]) // eslint-disable-line

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={disabled ? mergedColumns.slice(0, -1) : mergedColumns}
        style={{ marginTop: 12 }}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  )
}

export default EditableTable
