import { combineReducers } from 'redux'

import ActionNames from './actionNames'
import {
  IBookPublicationsState,
  IBookPublicationsErrorState,
} from 'features/bookPublicationsList/types'
import { mapBookPublicationDtoToUi } from 'api/mappers'
import { GenericActionType } from 'redux/types'
import { ForceArray } from 'common/utils'
import { UIBookPublication } from 'features/bookPublicationForm/types'

const initialBookPublicationsState: IBookPublicationsState = {
  bookPublications: [],
}

function BookPublicationsReducer(
  state = initialBookPublicationsState,
  action: GenericActionType
): IBookPublicationsState {
  switch (action.type) {
    case ActionNames.GET_BOOK_PUBLICATIONS_SUCCEEDED:
      const bookPublicationsJson = (action as any).payload.bookPublications
      return {
        ...state,
        bookPublications: ForceArray(bookPublicationsJson).map((el) =>
          mapBookPublicationDtoToUi(el)
        ) as UIBookPublication[],
      }

    default:
      return state
  }
}

const initialErrorState: IBookPublicationsErrorState = {
  bookPublicationsErrors: [],
}

function ErrorReducer(
  state = initialErrorState,
  action: GenericActionType
): IBookPublicationsErrorState {
  switch (action.type) {
    case ActionNames.GET_BOOK_PUBLICATIONS_SUCCEEDED:
      return {
        ...state,
        bookPublicationsErrors: [],
      }
    case ActionNames.GET_BOOK_PUBLICATIONS_FAILED: {
      const { message } = (action as any).payload.error
      try {
        return {
          ...state,
          bookPublicationsErrors: [...JSON.parse(message)],
        }
      } catch {
        return {
          ...state,
          bookPublicationsErrors: [message],
        }
      }
    }

    default:
      return state
  }
}

export default combineReducers({
  data: BookPublicationsReducer,
  errors: ErrorReducer,
})
