import ActionNames from './actionNames'
import makeActionCreator from 'redux/utils'

const getBookPublicationsRequested = makeActionCreator(
  ActionNames.GET_BOOK_PUBLICATIONS_REQUESTED
)
const getBookPublicationsSucceeded = makeActionCreator(
  ActionNames.GET_BOOK_PUBLICATIONS_SUCCEEDED,
  'bookPublications'
)
const getBookPublicationsFailed = makeActionCreator(
  ActionNames.GET_BOOK_PUBLICATIONS_FAILED,
  'error'
)

export default {
  getBookPublicationsRequested,
  getBookPublicationsSucceeded,
  getBookPublicationsFailed,
}
