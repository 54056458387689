import {
  BookOutlined,
  SolutionOutlined,
  ProfileOutlined,
  DeleteOutlined,
  MenuOutlined,
  CheckSquareOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Button, Layout, Menu } from 'antd'
import MenuItem from 'antd/lib/menu/MenuItem'
import Configs from 'configs/configs'
import Routes from 'configs/routes'
import React, { FC, forwardRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const BurgerWrapper = styled.div`
  text-align: center;
  margin: 10px 0 10px 0;
`

const Sidebar: FC<any> = (props, ref) => {
  const { selectedKey, menuCollapsed, setMenuCollapsed } = props
  const [isCollapsed, setCollapse] = useState(menuCollapsed)
  return (
    <Layout.Sider
      collapsible
      collapsed={isCollapsed}
      trigger={null}
      width={205}
    >
      <BurgerWrapper>
        <Button
          icon={<MenuOutlined />}
          style={{
            backgroundColor: '#232323',
            color: '#e4e4e4',
            borderColor: '#232323',
          }}
          onClick={() => {
            setMenuCollapsed(!isCollapsed)
            setCollapse(!isCollapsed)
          }}
        />
      </BurgerWrapper>
      <Menu mode="inline" theme="dark" selectedKeys={[selectedKey]}>
        <MenuItem key={1}>
          <Link to={Routes.BookPublicationForm}>
            <BookOutlined />
            <span>Νέα Πρόταση Βιβλίου</span>
          </Link>
        </MenuItem>
        <MenuItem key={2}>
          <Link to={Routes.MyTasks}>
            <SolutionOutlined />
            <span>{Configs.MainTabTitles.SelfPending}</span>
          </Link>
        </MenuItem>
        <MenuItem key={3}>
          <Link to={Routes.BookPublicationsList}>
            <ProfileOutlined />
            <span>{Configs.MainTabTitles.Pending}</span>
          </Link>
        </MenuItem>
        <MenuItem key={4}>
          <Link to={Routes.RejectedBookPublicationsList}>
            <DeleteOutlined />
            <span>{Configs.MainTabTitles.Rejected}</span>
          </Link>
        </MenuItem>
        <MenuItem key={5}>
          <Link to={Routes.CompletedBookPublicationsList}>
            <CheckSquareOutlined />
            <span>{Configs.MainTabTitles.Completed}</span>
          </Link>
        </MenuItem>
        <MenuItem key={6}>
          <Link to={Routes.AllBookPublicationsList}>
            <UnorderedListOutlined />
            <span>{Configs.MainTabTitles.All}</span>
          </Link>
        </MenuItem>
      </Menu>
    </Layout.Sider>
  )
}

export default forwardRef(Sidebar)
