import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { Row, Col, Form, Checkbox, Radio, DatePicker, Typography } from 'antd'
import {
  FormHeader,
  StepForm,
} from 'features/bookPublicationForm/screens/styles'

import TextArea from 'antd/lib/input/TextArea'
import Configs from 'configs/configs'
import CompletionSection from 'features/bookPublicationForm/screens/CompletionSection'
import EditableDistributionsTable from './EditableDistributionsTable'
import {
  PublicationDetailsStepData,
  UIDistribution,
} from 'features/bookPublicationForm/types'

import { Dispatch, bindActionCreators } from 'redux'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'
import { useLazyEffect } from 'common/hooks/useLazyEffect'
import _ from 'lodash'

const PublicationDetails: FC<any> = forwardRef((props, ref) => {
  let defaults: PublicationDetailsStepData = props.defaults
  const {
    stepDisabled,
    setHasFormChanged,
    actions,
    stepSubmitting,
    stepSubmittedData,
    stepSubmittedErrors,
    allUsers,
    allInstitutionDetails,
    involvedBookProducers,
  } = props

  const [form] = Form.useForm()
  const [resolutionText, setResolutionText] = useState(
    defaults.resolution ? 'Έγκριση' : 'Απόρριψη'
  )
  const [isDistributable, setIsDistributable] = useState<boolean>(
    defaults.isDistributable
  )
  const [totalCopies, setTotalCopies] = useState<number>(0)

  useEffect(() => {
    actions.getInstitutionDetailsRequested()
  }, [actions])

  /**
   * useImperativeHandle customizes the instance value
   * that is exposed to parent components when using ref.
   *
   * We need to expose only the handle submit to the parent
   * instance to handle the validation of the current form
   * from the next/prev step buttons.
   */
  useImperativeHandle(ref, () => ({
    submitHandler,
  }))

  useLazyEffect(() => {
    if (
      !stepSubmitting &&
      !stepSubmittedErrors.length &&
      !_.isEmpty(stepSubmittedData)
    ) {
      dataRef.current.distributionsTable = stepSubmittedData.publicationDetails.distributionsTable.map(
        (el) => ({ ...el })
      )
      defaults = stepSubmittedData.publicationDetails
    }
  }, [stepSubmitting, stepSubmittedData, stepSubmittedErrors])

  const submitStepValues = useCallback(
    (values) => {
      dataRef.current.distributionsTable = dataRef.current.distributionsTable.map(
        (dist) => ({
          ...dist,
          institutionDetails: allInstitutionDetails.find(
            (item) =>
              item.university === dist.institutionDetails?.university &&
              item.department === dist.institutionDetails?.department
          ),
          tutors:
            dist.tutors?.map((el) => ({
              ...el,
              institutionDetails: allInstitutionDetails.find(
                (item) =>
                  item.university === el.institutionDetails?.university &&
                  item.department === el.institutionDetails?.department
              ),
            })) ?? [],
        })
      )
      if (values.completion?.assignees) {
        values.completion.assignees = values.completion.assignees.map(
          (assigneeMail) => allUsers.find((user) => user.email === assigneeMail)
        )
      }
      values.completion.stepEntityId = defaults.completion.stepEntityId
      actions.submitPublicationDetailsRequested(
        {
          ...values,
          ...dataRef.current,
        },
        defaults
      )
    },
    [actions, defaults, allInstitutionDetails, allUsers]
  )

  /**
   * Step form submit callback used
   * by the parent component
   */
  const submitHandler = useCallback(() => {
    if (form.getFieldValue(['completion', 'stepCompleted'])) {
      form.validateFields().then((values) => submitStepValues(values))
    } else {
      submitStepValues(form.getFieldsValue())
    }
  }, [form, submitStepValues])

  const toggleResolution = useCallback(
    () => {
      if (form.getFieldValue('resolution')) {
        setResolutionText('Έγκριση')
      } else {
        setResolutionText('Απόρριψη')
      }
    },
    [form] // eslint-disable-line
  )

  /**
   * Use this ref data structure to gather
   * the submitted values of the inner components.
   *
   * It will help us determine/validate the state of this step
   */
  const dataRef = useRef<{ distributionsTable: UIDistribution[] }>({
    distributionsTable: [],
  })

  return (
    <StepForm
      form={form}
      initialValues={defaults}
      $stepDisabled={stepDisabled}
      onFieldsChange={() => setHasFormChanged(true)}
    >
      <FormHeader>Πληροφορίες Έκδοσης</FormHeader>
      <Row>
        <Col span={24}>
          <Form.Item
            name="publicationDate"
            label="Ημερομηνία Καταχώρησης Πρότασης"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <DatePicker format={Configs.Date.Format} placeholder="__/__/____" />
          </Form.Item>
          <Form.Item name="publicationComments">
            <TextArea rows={4} placeholder="Σχόλια για την πρόταση" />
          </Form.Item>
          <Form.Item
            name="resolution"
            label="Η πρόταση"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <Radio.Group onChange={toggleResolution}>
              <Radio value={true}>Εγκρίνεται</Radio>
              <Radio value={false}>Απορρίπτεται</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="resolutionDate"
            label={'Ημερομηνία ' + resolutionText + 'ς'}
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <DatePicker format={Configs.Date.Format} placeholder="__/__/____" />
          </Form.Item>
          <Form.Item name="resolutionComments">
            <TextArea rows={4} placeholder={'Σχόλια ' + resolutionText + 'ς'} />
          </Form.Item>
          <Form.Item
            name="desiredReleaseDate"
            label="Εκτιμώμενη ημερομηνία παράδοσης"
          >
            <DatePicker format={Configs.Date.Format} placeholder="__/__/____" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormHeader>Πανεπιστημιακές Διανομές Βιβλίου</FormHeader>
          <Form.Item
            name="isDistributable"
            valuePropName="checked"
            label="Το βιβλίο διατίθεται για πανεπιστημιακή διανομή"
          >
            <Checkbox onChange={() => setIsDistributable(!isDistributable)} />
          </Form.Item>
          {isDistributable && (
            <>
              <EditableDistributionsTable
                dataRef={dataRef}
                defaults={defaults.distributionsTable}
                disabled={stepDisabled}
                setHasFormChanged={() => setHasFormChanged(true)}
                allInstitutionDetails={allInstitutionDetails}
                involvedBookProducers={involvedBookProducers}
                setTotalCopies={setTotalCopies}
              />
              <div style={{ padding: '1em 0 1em 0' }}>
                <Typography.Text strong style={{ fontSize: 16 }}>
                  Σύνολο αντιτύπων: {totalCopies}
                </Typography.Text>
              </div>
            </>
          )}
        </Col>
      </Row>
      <FormHeader>Ολοκλήρωση Εισαγωγής Πληροφοριών Έκδοσης</FormHeader>
      <CompletionSection defaults={defaults.completion} form={form} />
    </StepForm>
  )
})

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allUsers: state.entities.getData.users,
    usersErrors: state.entities.errors.getUsersErrors,

    allInstitutionDetails: state.entities.getData.institutionDetails,
    institutionDetailsErrors: state.entities.errors.getInstitutionDetailsErrors,

    stepSubmitting: state.entities.stepData.stepSubmitting,
    stepSubmittedData: state.entities.stepData.stepSubmittedData,
    stepSubmittedErrors: state.entities.stepData.stepSubmittedErrors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PublicationDetails) as any
