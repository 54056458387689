import {
  IEntitiesErrorState,
  IGetEntitiesState,
  IPostEntitiesState,
} from 'features/bookPublicationForm/types'

export const getData = (state): IGetEntitiesState => state.entities.getData

export const postData = (state): IPostEntitiesState => state.entities.postData

export const errors = (state): IEntitiesErrorState => state.entities.errors
