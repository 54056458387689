import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Steps, Button, Row, Col, message, Tooltip } from 'antd'
import BookDetails from './BookDetails/index'
import PublicationDetails from './PublicationDetails/index'
import ContractSigning from './ContractSigning/index'
import TechnicalInstructions from './TechnicalInstructions/index'
import Authoring from './Authoring'
import PublicationReview from './PublicationReview'
import ManuscriptVerification from './ManuscriptVerification'
import PublicationVerification from './PublicationVerification'
import {
  SaveOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  CheckCircleOutlined,
  BookOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import Utils from 'common/utils'
import { useLocation, Prompt } from 'react-router-dom'
import Configs from 'configs/configs'
import CommentsSection from './CommentsSection'
import { UIAuthor, UIBookPublication } from 'features/bookPublicationForm/types'
import { DeepPartial } from 'redux'

import { Dispatch, bindActionCreators } from 'redux'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'
import { useLazyEffect } from 'common/hooks/useLazyEffect'
import _ from 'lodash'

const SectionContent = styled.div<{ enabled: boolean }>`
  display: ${(props) => (props.enabled ? 'block' : 'none')}
  width: 100%;
  margin-top: 30px;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 25px;
`

const StepAction = styled.div`
  margin-top: 16px;
`

const FormButton = styled(Button)`
  margin: 0 4px;
`

enum SubmitType {
  Next,
  Previous,
  Save,
  Complete,
  Unset,
}

const NewBookPublication: FC<any> = (props) => {
  const {
    allBookPublications,
    actions,
    stepSubmitting,
    stepSubmittedData,
    stepSubmittedErrors,
    stepSubmissionComplete,
    bookPublicationData,
  } = props

  const location = useLocation()
  const bookPublication:
    | DeepPartial<UIBookPublication>
    | undefined = allBookPublications.find(
    (item) => item.seqId === location?.state?.seqId
  )
  // const bookPublication:
  //   | DeepPartial<UIBookPublication>
  //   | undefined = TestPublicationsData.find(
  //   (item) => item.id === location?.state?.bookPublicationId
  // )

  const submittedFlag = useRef<boolean>(!!location?.state?.seqId)

  const [currentStep, setCurrentStep] = useState(
    bookPublication?.currentStep || 0
  )
  const [currentSubmitType, setCurrentSubmitType] = useState<SubmitType>(
    SubmitType.Save
  )

  const stepFormRef = useRef<any>(null)

  const dataRef = useRef<DeepPartial<UIBookPublication>>(
    bookPublication
      ? _.cloneDeep({ ...InitialFormData, ...bookPublication })
      : _.cloneDeep({ ...InitialFormData })
  )
  // const dataRef = useRef<DeepPartial<UIBookPublication>>({ ...InitialFormData, ...TestPublicationsData[0]})    // use this line instead of the above for test data

  const [hasFormChanged, setHasFormChanged] = useState(false)

  useEffect(() => {
    actions.getUsersRequested()
    // If new, init BookPublication in back-end:
    if (!location?.state?.seqId) {
      actions.initBookPublicationRequested()
    }
  }, [location, actions])

  useEffect(() => {
    if (bookPublicationData) {
      if (!submittedFlag.current) {
        Utils.logger.info(
          `State :: Book Publication :: Init ::`,
          bookPublicationData
        )
        dataRef.current = _.merge(dataRef.current, bookPublicationData)
      } else {
        Utils.logger.info(
          `State :: Book Publication :: Step-${currentStep} :: After submission ::`,
          dataRef.current
        )
      }
    }
  }, [bookPublicationData, currentStep])

  const afterSubmitHandler = useCallback(() => {
    setHasFormChanged(false)
    switch (currentSubmitType) {
      case SubmitType.Next:
        setCurrentStep((prevStep) => prevStep + 1)
        break
      case SubmitType.Previous:
        setCurrentStep((prevStep) => prevStep - 1)
        break
      case SubmitType.Save:
      case SubmitType.Complete:
        message.success('Τα στοιχεία της φόρμας αποθηκεύτηκαν!', 2)
        break
    }
  }, [currentSubmitType])

  useLazyEffect(() => {
    if (!stepSubmitting && stepSubmissionComplete) {
      if (stepSubmittedErrors.length) {
        console.log('Submission failed', stepSubmittedErrors)
        // TODO: proper error message
        message.error(stepSubmittedErrors, 2)
        return
      }
      if (!_.isEmpty(stepSubmittedData)) {
        submittedFlag.current = true
        Utils.logger.info(
          `User :: Book Publication :: Step-${currentStep} :: Save ::`,
          stepSubmittedData
        )
        Utils.logger.info(
          `User :: Book Publication :: Step-${currentStep} :: Before submission ::`,
          stepSubmittedData
        )

        dataRef.current = {
          ...dataRef.current,
          ...stepSubmittedData,
        }

        Utils.logger.info(
          `User :: Book Publication :: Step-${currentStep} :: After submission ::`,
          stepSubmittedData
        )
        actions.patchBookPublicationRequested(dataRef.current)
      }
      afterSubmitHandler()
      setCurrentSubmitType(SubmitType.Unset)
    }
  }, [
    stepSubmissionComplete,
    stepSubmittedData,
    stepSubmittedErrors,
    actions,
    afterSubmitHandler,
  ])

  // To solve the issue of having different entries for book producers at the 1st and 2nd step
  // (both of which contain a PersonAddArray component, where a new author can be created)
  // we need to check at creation time if chosen producers exists somewhere in the publication
  // So we generate an array containing every involved producer of this publication
  const involvedBookProducers = _.uniqWith(
    _.concat(
      dataRef.current.bookDetails?.authors,
      dataRef.current.publicationDetails?.distributionsTable?.reduce(
        (acc, el) =>
          el?.tutors ? _.concat(acc, el.tutors as UIAuthor[]) : acc,
        [] as UIAuthor[]
      )
    ),
    (a, b) => a?.email === b?.email
  )

  const FormSteps = useMemo(
    () => [
      {
        index: 0,
        title: ['Πληροφορίες', <br key={0} />, 'Βιβλίου'],
        content: (
          <BookDetails
            defaults={dataRef.current.bookDetails}
            involvedBookProducers={involvedBookProducers}
            ref={(form) => (stepFormRef.current = form)}
            setHasFormChanged={setHasFormChanged}
          />
        ),
        description: ' ',
        stepCompleted: dataRef.current.bookDetails?.completion?.stepCompleted,
      },
      {
        index: 1,
        title: ['Συμφωνία', <br key={1} />, 'Έκδοσης'],
        content: (
          <PublicationDetails
            defaults={dataRef.current.publicationDetails}
            involvedBookProducers={involvedBookProducers}
            ref={(form) => (stepFormRef.current = form)}
            setHasFormChanged={setHasFormChanged}
          />
        ),
        description: ' ',
        stepCompleted:
          dataRef.current.publicationDetails?.completion?.stepCompleted,
      },
      {
        index: 2,
        title: ['Υπογραφή', <br key={2} />, 'Συμβολαίων'],
        content: (
          <ContractSigning
            authors={dataRef.current.bookDetails?.authors}
            defaults={dataRef.current.contractSigning}
            ref={(form) => (stepFormRef.current = form)}
            setHasFormChanged={setHasFormChanged}
          />
        ),
        description: ' ',
        stepCompleted:
          dataRef.current.contractSigning?.completion?.stepCompleted,
      },
      {
        index: 3,
        title: ['Αποστολή', <br key={3} />, 'Οδηγιών'],
        content: (
          <TechnicalInstructions
            authors={dataRef.current.bookDetails?.authors}
            defaults={dataRef.current.technicalInstructions}
            ref={(form) => (stepFormRef.current = form)}
            setHasFormChanged={setHasFormChanged}
          />
        ),
        description: ' ',
        stepCompleted:
          dataRef.current.technicalInstructions?.completion?.stepCompleted,
      },
      {
        index: 4,
        title: ['Χρόνοι', <br key={4} />, 'Παράδοσης'],
        content: (
          <Authoring
            authors={dataRef.current.bookDetails?.authors}
            defaults={dataRef.current.authoring}
            ref={(form) => (stepFormRef.current = form)}
            setHasFormChanged={setHasFormChanged}
          />
        ),
        description: ' ',
        stepCompleted: dataRef.current.authoring?.completion?.stepCompleted,
      },
      {
        index: 5,
        title: ['Έλεγχος', <br key={5} />, 'Χειρογράφων'],
        content: (
          <ManuscriptVerification
            defaults={dataRef.current.manuscriptVerification}
            ref={(form) => (stepFormRef.current = form)}
            setHasFormChanged={setHasFormChanged}
          />
        ),
        description: ' ',
        stepCompleted:
          dataRef.current.manuscriptVerification?.completion?.stepCompleted,
      },
      {
        index: 6,
        title: ['Έλεγχος', <br key={6} />, 'Έκδοσης'],
        content: (
          <PublicationVerification
            defaults={dataRef.current.publicationVerification}
            ref={(form) => (stepFormRef.current = form)}
            setHasFormChanged={setHasFormChanged}
          />
        ),
        description: ' ',
        stepCompleted:
          dataRef.current.publicationVerification?.completion?.stepCompleted,
      },
    ],
    [dataRef.current, currentStep] // eslint-disable-line
  )

  /**
   * Handle step form submit functionality.
   * We use refs to get the submit handler of
   * each form step.
   */
  const stepSubmitHandler = (submitType: SubmitType) => {
    if (!stepFormRef || !stepFormRef.current) {
      return
    }
    setCurrentSubmitType(submitType)
    stepFormRef.current.submitHandler()
  }

  const onStepChange = useCallback(
    (index) => {
      if (!hasFormChanged || window.confirm(Configs.Messages.LeaveUnsaved)) {
        setCurrentStep(index)
        setHasFormChanged(false)
      }
    },
    [hasFormChanged]
  )

  const getStepStatus = useCallback(
    (index, stepCompleted) => {
      if (index === currentStep) {
        return 'process'
      }
      return stepCompleted ? 'finish' : 'wait'
    },
    [currentStep]
  )

  const getCurrentStepId = useCallback(() => {
    switch (currentStep) {
      case 0:
        return dataRef.current.bookDetails?.completion?.stepEntityId
      case 1:
        return dataRef.current.publicationDetails?.completion?.stepEntityId
      case 2:
        return dataRef.current.contractSigning?.completion?.stepEntityId
      case 3:
        return dataRef.current.technicalInstructions?.completion?.stepEntityId
      case 4:
        return dataRef.current.authoring?.completion?.stepEntityId
      case 5:
        return dataRef.current.manuscriptVerification?.completion?.stepEntityId
      case 6:
        return dataRef.current.publicationVerification?.completion?.stepEntityId
      default:
        return undefined
    }
  }, [currentStep])

  useEffect(() => {
    // prevent reload if form has changed
    if (hasFormChanged) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = null
    }
  }, [hasFormChanged])

  useEffect(() => {
    return () => {
      // If BookPublication is new and never PATCHED, DELETE on leave
      if (!submittedFlag.current) {
        actions.deleteBookPublicationRequested(dataRef.current.id)
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    // Scroll to top on step change:
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [currentStep])

  return (
    <>
      <Steps current={currentStep} size="small" onChange={onStepChange}>
        {FormSteps.sort((a, b) => a.index - b.index).map((item) => (
          <Steps.Step
            key={item.index}
            title={item.title}
            status={getStepStatus(item.index, item.stepCompleted)}
            description={item.description}
          />
        ))}
      </Steps>

      {/* Using "currentStep == -1" to for PublicationReview page */}
      {currentStep >= 0 ? (
        <SectionContent enabled={currentStep === FormSteps[currentStep].index}>
          <Prompt
            when={hasFormChanged}
            message={Configs.Messages.LeaveUnsaved}
          />

          {FormSteps[currentStep].content}
        </SectionContent>
      ) : (
        <SectionContent enabled={true}>
          <PublicationReview formData={dataRef.current} />
        </SectionContent>
      )}

      {currentStep >= 0 && (
        <StepAction>
          <Row align="middle" justify="space-between">
            <Col>
              <FormButton
                onClick={() => onStepChange(-1)}
                icon={<BookOutlined />}
              >
                Καρτέλα
              </FormButton>
            </Col>
            <Col>
              <Tooltip title="Αποθήκευση">
                <FormButton
                  htmlType="submit"
                  onClick={() => stepSubmitHandler(SubmitType.Save)}
                  icon={<SaveOutlined />}
                />
              </Tooltip>
              {currentStep > 0 && (
                <FormButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => stepSubmitHandler(SubmitType.Previous)}
                  icon={<LeftCircleOutlined />}
                >
                  Προηγούμενο
                </FormButton>
              )}
              {currentStep < FormSteps.length - 1 && (
                <FormButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => stepSubmitHandler(SubmitType.Next)}
                >
                  Επόμενο
                  <RightCircleOutlined />
                </FormButton>
              )}
              {currentStep === FormSteps.length - 1 && (
                <FormButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => stepSubmitHandler(SubmitType.Complete)}
                >
                  Ολοκλήρωση
                  <CheckCircleOutlined />
                </FormButton>
              )}
            </Col>
          </Row>
        </StepAction>
      )}

      {currentStep >= 0 && (
        <SectionContent enabled={true}>
          <CommentsSection currentStepId={getCurrentStepId()} />
        </SectionContent>
      )}
    </>
  )
}

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allBookPublications: state.bookPublications.data.bookPublications,

    bookPublicationData: state.entities.postData.bookPublicationData,
    patchBookPublicationErrors:
      state.entities.errors.patchBookPublicationErrors,

    stepSubmitting: state.entities.stepData.stepSubmitting,
    stepSubmissionComplete: state.entities.stepData.stepSubmissionComplete,
    stepSubmittedData: state.entities.stepData.stepSubmittedData,
    stepSubmittedErrors: state.entities.stepData.stepSubmittedErrors,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBookPublication) as any

const InitialFormData: DeepPartial<UIBookPublication> = {
  // TODO: completion default selfUser
  bookDetails: {
    authors: [],
    completion: { stepCompleted: false },
  },
  publicationDetails: {
    resolution: true,
    isDistributable: true,
    distributionsTable: [],
    completion: { stepCompleted: false },
  },
  contractSigning: {
    contractsSentTable: [],
    completion: { stepCompleted: false },
  },
  technicalInstructions: {
    instructionsSentTable: [],
    completion: { stepCompleted: false },
  },
  authoring: {
    authorsProgressTable: [],
    completion: { stepCompleted: false },
  },
  manuscriptVerification: {
    checkboxes: [],
    completion: { stepCompleted: false },
  },
  publicationVerification: {
    completion: { stepCompleted: false },
  },
}
