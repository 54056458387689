const PREFIX = 'features/authentication'

const ActionNames = {
  AUTH_LOGIN_REQUESTED: `${PREFIX}/login/requested`,
  AUTH_LOGIN_SUCCEEDED: `${PREFIX}/login/succeeded`,
  AUTH_LOGIN_FAILED: `${PREFIX}/login/failed`,

  AUTH_LOGOUT: `${PREFIX}/logout`,

  AUTH_REGISTER_REQUESTED: `${PREFIX}/register/requested`,
  AUTH_REGISTER_SUCCEEDED: `${PREFIX}/register/succeeded`,
  AUTH_REGISTER_FAILED: `${PREFIX}/register/failed`,

  AUTH_FORGOT_PASSWORD_REQUESTED: `${PREFIX}/forgot-password/requested`,
  AUTH_FORGOT_PASSWORD_SUCCEEDED: `${PREFIX}/forgot-password/succeeded`,
  AUTH_FORGOT_PASSWORD_FAILED: `${PREFIX}/forgot-password/failed`,

  AUTH_RESET_PASSWORD_REQUESTED: `${PREFIX}/reset-password/requested`,
  AUTH_RESET_PASSWORD_SUCCEEDED: `${PREFIX}/reset-password/succeeded`,
  AUTH_RESET_PASSWORD_FAILED: `${PREFIX}/reset-password/failed`,

  AUTH_REFRESH_REQUESTED: `${PREFIX}/refresh/requested`,
  AUTH_REFRESH_SUCCEEDED: `${PREFIX}/refresh/succeeded`,
  AUTH_REFRESH_FAILED: `${PREFIX}/refresh/failed`,

  TOKEN_REFRESHING_STARTED: `${PREFIX}/token/refreshing/started`,
  TOKEN_REFRESHING_FINISHED: `${PREFIX}/token/refreshing/finished`,
}

export default ActionNames
