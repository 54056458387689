import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, Input, Button, Layout, Typography } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Routes from 'configs/routes'
import Utils, { getQueryParams } from 'common/utils'
import Configs from 'configs/configs'
import { Dispatch, bindActionCreators } from 'redux'
import { AuthenticationActionNames } from 'features/authentication/types'
import { AuthenticationActionCreators } from 'features/authentication/ducks'
import { connect } from 'react-redux'
import { RootState } from 'redux/types'
import { CommonActionCreators } from 'common/ducks'

const CardMargin = styled(Layout)`
  width: min(100vw, 450px);
  align: center;
  padding: 20px 30px 0 30px;
  border-radius: 3px;
`

const ResetWrapper = styled(Form.Item)`
  text-align: center;
  width: 100%;
`

const ResetButton = styled(Button)`
  min-width: 120px;
  font-weight: 500;
  width: 50%;
`

const ResetPassword: FC<any> = (props) => {
  const [form] = Form.useForm()
  const [redirect, setRedirect] = useState(false)
  const { hasResetPassword, loading, actions } = props
  const location = useLocation()

  const query = getQueryParams(location)
  useEffect(() => {
    if (hasResetPassword) {
      setTimeout(() => {
        setRedirect(true)
      }, 5 * 1000)
      return () => {
        actions.uiReset()
      }
    }
  }, [hasResetPassword, actions])

  const handleSubmit = useCallback(() => {
    form.validateFields().then((values) => {
      Utils.logger.info(
        `User :: Authentication :: Reset Password Request :: `,
        values
      )
      actions.resetPasswordRequested(
        query.username,
        values.new_password,
        query.token
      )
    })
  }, [form, actions, query])

  return (
    <CardMargin>
      <Typography.Title style={{ fontSize: 24 }}>
        Επαναφορά κωδικού
      </Typography.Title>
      {!hasResetPassword && (
        <Typography.Paragraph>
          Εισάγετε και επαληθεύστε τον καινούργιο κωδικό για το λογαριασμό σας.
        </Typography.Paragraph>
      )}
      {!hasResetPassword ? (
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Νέος κωδικός"
            name="new_password"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>
          <Form.Item
            label="Επαλήθευση νέου κωδικού"
            name="confirm_password"
            dependencies={['new_password']}
            hasFeedback
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('Οι δύο κωδικοί δεν ταιριάζουν!')
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>
          <ResetWrapper>
            <ResetButton type="primary" htmlType="submit" loading={loading}>
              Επαναφορά κωδικού
            </ResetButton>
          </ResetWrapper>
        </Form>
      ) : (
        <>
          <Typography.Paragraph>
            Ο κωδικός σας άλλαξε με επιτυχία. Θα μεταφερθείτε σύντομα στην
            αρχική σελίδα για σύνδεση.
          </Typography.Paragraph>
          {redirect && (
            <Redirect
              to={{ pathname: Routes.Home, state: { from: location } }}
            />
          )}
        </>
      )}
    </CardMargin>
  )
}

export const mapDispatchToProps = (
  dispatch: Dispatch<AuthenticationActionNames>
) => {
  return {
    actions: bindActionCreators(
      { ...AuthenticationActionCreators, ...CommonActionCreators },
      dispatch
    ),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    hasResetPassword: state.user.auth.hasResetPassword,
    loading: state.common.ui.loading,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword) as any
