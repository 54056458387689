const Configs = {
  Date: {
    Format: 'DD/MM/YYYY',
    FormatWithTime: 'DD/MM/YYYY HH:mm',
    FormatFull: 'LLLL',
    Placeholder: '__/__/____',
  },
  Messages: {
    RequiredField: 'Το πεδίο είναι υποχρεωτικό',
    InvalidEmail: 'Το email δεν είναι έγκυρο',
    DuplicateAuthors:
      'Δεν επιτρέπεται η εισαγωγή πολλαπλών συγγραφέων/διδασκόντων με το ίδιο email.',
    LeaveUnsaved:
      'Υπάρχουν αλλαγές που δεν έχουν αποθηκευτεί. Θέλετε σίγουρα να φύγετε από τη σελίδα;',
    NoAuthors: {
      title: 'Δεν υπάρχουν συγγραφείς/επιμελητές για αυτό το βιβλίο',
      content:
        'Εισάγετε πρώτα συγγραφείς/επιμελητές στο Βήμα 1: Πληροφορίες Βιβλίου.',
    },
    NoAvailableAuthors: {
      title: 'Όλοι οι συγγραφείς/επιμελητές έχουν ήδη εισαχθεί στον πίνακα',
      content:
        'Μπορείτε να επεξεργαστείτε τις εγγραφές του πίνακα πατώντας το σχετικό κουμπί στα δεξιά της κάθε μίας.',
    },
    UntitledBookPublication: 'Άτιτλη Πρόταση Έκδοσης',
    CompletedBookPublication: 'Ολοκληρώθηκε',
  },
  ErrorMessages: {
    BadResponse: 'Κάτι πήγε λάθος',
    RequestFailed: 'Το αίτημα απέτυχε',
    ContactUs:
      'Δοκιμάστε ξανά και αν το πρόβλημα παραμένει επικοινωνήστε μαζί μας.',
    ServerDown: 'Δεν είναι δυνατή η επικοινωνία με τον server',
    Authentication: {
      Default: 'Η σύνδεση ήταν ανεπιτυχής',
      UserUnknown: 'Ο χρήστης δεν υπάρχει.',
      WrongPassword: 'Ο κωδικός χρήστη ήταν λάθος.',
    },
    Authorization: {
      Default: 'Η ενέργεια αυτή δεν επιτρέπεται',
      UnauthorizedUser:
        'Ο χρήστης δεν έχει τα δικαιώματα για να εκτελέσει αυτήν την ενέργεια.',
    },
  },
  LogoutNotificationThresholdSec: 120,
  InactivityTimer: 60 * 15,
  MainTabTitles: {
    PublicationForm: 'Φόρμα Πρότασης Βιβλίου',
    SelfPending: 'Οι Εκκρεμότητές μου',
    Pending: 'Όλες οι Εκκρεμότητες',
    Rejected: 'Απορριφθείσες Προτάσεις',
    Completed: 'Εκδοθέντα Βιβλία',
    All: 'Ολα τα Βιβλία',
  },
}

export default Configs
