import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Radio,
  Select,
} from 'antd'
import { BookOutlined } from '@ant-design/icons'
import {
  FormHeader,
  StepForm,
  ModalColFormItem,
} from 'features/bookPublicationForm/screens/styles'
import ExtendableSelect from 'common/components/ExtendableSelect'

import TextArea from 'antd/lib/input/TextArea'
import PersonAddArray, { ScreenType } from 'common/components/PersonAddArray'
import CompletionSection from 'features/bookPublicationForm/screens/CompletionSection'
import Configs from 'configs/configs'
import {
  WrittenFormatList,
  BookDimensions,
} from 'features/bookPublicationForm/screens/constantData'

import { Dispatch, bindActionCreators } from 'redux'
import {
  BookDetailsStepData,
  UIAuthor,
  UIBookCategory,
} from 'features/bookPublicationForm/types'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'
import { useLazyEffect } from 'common/hooks/useLazyEffect'
import _ from 'lodash'

const { Option } = Select

const BookDetails: FC<any> = forwardRef((props, ref) => {
  let defaults: BookDetailsStepData = props.defaults
  const allBookCategories: UIBookCategory[] = props.allBookCategories
  const {
    involvedBookProducers,
    stepDisabled,
    setHasFormChanged,
    actions,
    allBookSeries,
    allInstitutionDetails,
    allUsers,
    stepSubmitting,
    stepSubmittedData,
    stepSubmittedErrors,
  } = props

  const [form] = Form.useForm()
  const writtenFormatList = WrittenFormatList
  const bookDimensions = BookDimensions

  /**
   * useImperativeHandle customizes the instance value
   * that is exposed to parent components when using ref.
   *
   * We need to expose only the handle submit to the parent
   * instance to handle the validation of the current form
   * from the next/prev step buttons.
   */
  useImperativeHandle(ref, () => ({
    submitHandler,
  }))

  useLazyEffect(() => {
    if (
      !stepSubmitting &&
      !stepSubmittedErrors.length &&
      !_.isEmpty(stepSubmittedData)
    ) {
      dataRef.current.authors = stepSubmittedData.bookDetails.authors
      // Changing props subobject seems a pretty bad idea
      defaults = stepSubmittedData.bookDetails
    }
  }, [stepSubmitting, stepSubmittedData, stepSubmittedErrors])

  const submitStepValues = useCallback(
    (values) => {
      dataRef.current.authors = dataRef.current.authors.map((el) => ({
        ...el,
        institutionDetails: allInstitutionDetails.find(
          (item) =>
            item.university === el.institutionDetails?.university &&
            item.department === el.institutionDetails?.department
        ),
      }))
      if (values.bookCategoryChild) {
        values.bookCategory = allBookCategories.find(
          (item) =>
            item.parentCategory?.name === values.bookCategoryParent &&
            item.name === values.bookCategoryChild
        )
      } else if (values.bookCategoryParent) {
        // only parent category selected
        values.bookCategory = allBookCategories.find(
          (item) => item.name === values.bookCategoryParent
        )
      }
      if (values.bookSeries?.name) {
        values.bookSeries =
          allBookSeries.find((item) => item.name === values.bookSeries.name) ??
          values.bookSeries
      }
      if (values.completion?.assignees) {
        values.completion.assignees = values.completion.assignees.map(
          (assigneeMail) =>
            allUsers.find((user) => user.username === assigneeMail)
        )
      }
      values.completion.stepEntityId = defaults.completion.stepEntityId
      actions.submitBookDetailsRequested(
        {
          ...values,
          ...dataRef.current,
          bookEntityId: defaults.bookEntityId,
        },
        defaults
      )
    },
    [
      actions,
      defaults,
      allBookCategories,
      allBookSeries,
      allInstitutionDetails,
      allUsers,
    ]
  )

  /**
   * Step form submit callback used
   * by the parent component
   */
  const submitHandler = useCallback(() => {
    if (form.getFieldValue(['completion', 'stepCompleted'])) {
      form.validateFields().then((values) => submitStepValues(values))
    } else {
      submitStepValues(form.getFieldsValue())
    }
  }, [form, submitStepValues])

  /**
   * Use this ref data structure to gather
   * the submitted values of the inner components.
   *
   * It will help us determine/validate the state of this step
   */
  const dataRef = useRef<{ authors: UIAuthor[] }>({
    authors: [],
  })

  useEffect(() => {
    actions.getBookCategoriesRequested()
    actions.getBookSeriesRequested()
  }, [actions])

  const [currentChildBookCategories, setCurrentChildBookCategories] = useState<
    string[]
  >([])

  useEffect(() => {
    setCurrentChildBookCategories(
      allBookCategories
        .filter((el) => el.parentCategory?.name === defaults.bookCategoryParent)
        .map((el) => el.name)
    )
  }, [allBookCategories, defaults])

  const handleParentBookCategoryChange = (value) => {
    form.setFieldsValue({ bookCategoryChild: undefined })
    if (value) {
      setCurrentChildBookCategories(
        allBookCategories
          .filter((el) => el.parentCategory?.name === value)
          .map((el) => el.name)
      )
    } else {
      setCurrentChildBookCategories([])
    }
  }

  return (
    <StepForm
      form={form}
      initialValues={defaults}
      $stepDisabled={stepDisabled}
      onFieldsChange={() => setHasFormChanged(true)}
    >
      <FormHeader>Πληροφορίες Βιβλίου</FormHeader>
      <Form.Item
        label="Τίτλος"
        name="title"
        rules={[{ required: true, message: Configs.Messages.RequiredField }]}
      >
        <Input
          prefix={
            <>
              <BookOutlined />
              &nbsp;
            </>
          }
        />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Row>
            <PersonAddArray
              required={true}
              parentForm={form}
              screenType={ScreenType.Authors}
              dataRef={dataRef}
              defaults={defaults.authors}
              disabled={stepDisabled}
              setHasFormChanged={() => setHasFormChanged(true)}
              involvedBookProducers={involvedBookProducers}
            />
          </Row>
          <Form.Item
            label="Αριθμός Σελίδων Word"
            name="numPages"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <InputNumber min={1} style={{ width: 120 }} />
          </Form.Item>
          <Form.Item
            label="Τύπος Αρχείου"
            name="oneFile"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <Radio.Group>
              <Radio value={true}>
                Ενιαίο Αρχείο <i>(συνίσταται)</i>
              </Radio>
              <Radio value={false}>Ανά κεφάλαιο</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Χρώμα"
            name="colour"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <Radio.Group>
              <Radio value={true}>Ασπρόμαυρο</Radio>
              <Radio value={false}>Τετράχρωμο</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Πρόγραμμα Συγγραφής"
            name="writtenFormat"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <ExtendableSelect
              form={form}
              fieldName="writtenFormat"
              defaultVal={defaults.writtenFormat}
              data={writtenFormatList}
              setHasFormChanged={setHasFormChanged}
            />
          </Form.Item>
          <Form.Item label="Κατηγορία Βιβλίου" name={'bookCategoryParent'}>
            <Select
              allowClear
              style={{ minWidth: 120 }}
              onChange={handleParentBookCategoryChange}
            >
              {[
                ...new Set(
                  allBookCategories
                    .filter((el) => !el.parentCategory)
                    .map((el) => el.name)
                ),
              ].map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Υποκατηγορία Βιβλίου" name={'bookCategoryChild'}>
            <Select
              allowClear
              style={{ minWidth: 120 }}
              disabled={!form.getFieldValue('bookCategoryParent')}
            >
              {currentChildBookCategories.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Σειρά Βιβλίου" name={['bookSeries', 'name']}>
            <ExtendableSelect
              form={form}
              fieldName="bookSeries"
              nestedFieldName="name"
              defaultVal={defaults.bookSeries?.name}
              data={allBookSeries}
              setHasFormChanged={setHasFormChanged}
            />
          </Form.Item>
          <Form.Item label="Σχήμα Βιβλίου" name="bookDimensions">
            <Select allowClear style={{ width: 120 }} placeholder="Επιλέξτε">
              {bookDimensions.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <ModalColFormItem name="bookDimensionsComments">
            <TextArea rows={4} placeholder="Σχόλια για το σχήμα του βιβλίου" />
          </ModalColFormItem>
        </Col>
        <Col span={12}>
          <Form.Item name="equations" valuePropName="checked">
            <Checkbox>Εξισώσεις</Checkbox>
          </Form.Item>
          <Form.Item name="equationsComments">
            <TextArea rows={4} placeholder="Σχόλια για τις εξισώσεις" />
          </Form.Item>
          <Form.Item name="technical" valuePropName="checked">
            <Checkbox>Τεχνικό</Checkbox>
          </Form.Item>
          <Form.Item name="tables" valuePropName="checked">
            <Checkbox>Πίνακες</Checkbox>
          </Form.Item>
          <Form.Item name="diagrams" valuePropName="checked">
            <Checkbox>Γραφήματα</Checkbox>
          </Form.Item>
          <Form.Item name="photos" valuePropName="checked">
            <Checkbox>Φωτογραφίες</Checkbox>
          </Form.Item>
          <Form.Item name="picturesCopyRight" valuePropName="checked">
            <Checkbox>Εικόνες με πνευματικά δικαιώματα</Checkbox>
          </Form.Item>
          <Form.Item name="picturesCopyRightComments">
            <TextArea
              rows={4}
              placeholder="Σχόλια για τις εικόνες με πνευματικά δικαιώματα"
            />
          </Form.Item>
          <Form.Item name="textCopyRight" valuePropName="checked">
            <Checkbox>Κείμενο με πνευματικά δικαιώματα</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <FormHeader>Ολοκλήρωση Εισαγωγής Πληροφοριών Βιβλίου</FormHeader>
      <CompletionSection defaults={defaults.completion} form={form} />
    </StepForm>
  )
})

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allBookCategories: state.entities.getData.bookCategories,
    allBookSeries: state.entities.getData.bookSeries,
    allInstitutionDetails: state.entities.getData.institutionDetails,
    allUsers: state.entities.getData.users,
    bookCategoriesErrors: state.entities.errors.getBookCategoriesErrors,
    bookSeriesErrors: state.entities.errors.getBookSeriesErrors,
    institutionDetailsErrors: state.entities.errors.getInstitutionDetailsErrors,
    usersErrors: state.entities.errors.getUsersErrors,

    stepSubmitting: state.entities.stepData.stepSubmitting,
    stepSubmittedData: state.entities.stepData.stepSubmittedData,
    stepSubmittedErrors: state.entities.stepData.stepSubmittedErrors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BookDetails) as any
