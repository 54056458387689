import React, { useState, useEffect, FC, useCallback, useRef } from 'react'
import { Col, Form, Avatar, Badge, Button, message } from 'antd'

import {
  PlusOutlined,
  UserOutlined,
  CloseCircleTwoTone,
} from '@ant-design/icons'
import styled from 'styled-components'
import { FormSubHeader } from 'features/bookPublicationForm/screens/styles'

import { Dispatch, bindActionCreators } from 'redux'
import { UIAuthor } from 'features/bookPublicationForm/types'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'
import {
  AuthorCreateForm,
  AuthorEditForm,
} from 'features/bookPublicationForm/screens/BookDetails/AuthorForm'
import Configs from 'configs/configs'
import _ from 'lodash'
import { useLazyEffect } from 'common/hooks/useLazyEffect'

// Avatars:
const AvatarFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
`

const AuthorAvatar = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 7px 15px 8px 0;
`

const AuthorTextAvatar = styled.div`
  margin: 7px 15px 7px 0;
`

const AvatarIcon = styled(Avatar)`
  &.ant-avatar {
    margin: 0 0 5px 0;
  }
`

const AvatarName = styled.span`
  font-weight: 300;
`

const AvatarBadge = styled<any>(Badge)`
  &.ant-badge .anticon-close-circle {
    top: 0px;
    right: 3px;
    color: #f5222d;
    cursor: pointer;
  }
`

const AvatarButton = styled(Button)`
  width: 63px;
  height: 63px;
  margin: 0 0 5px 0;
`

const AvatarButtonName = styled.span`
  font-weight: 400;
`

export enum ScreenType {
  Authors,
  Tutors,
  TutorsEdit,
}

const PersonAddArray: FC<any> = (props) => {
  const {
    defaults,
    disabled,
    parentForm,
    allAuthors,
    allInstitutionDetails,
    setHasFormChanged,
    screenType,
    rowKey,
    clearPersonAddArray,
    involvedBookProducers,
    required,
  } = props

  const [modalCDisplay, setModalCDisplay] = useState(false)
  const [modalEDisplay, setModalEDisplay] = useState(false)
  const [currentEditAuthor, setCurrentEditAuthor] = useState<any>()
  const [authors, setAuthors] = useState<UIAuthor[]>(
    screenType !== ScreenType.Tutors ? defaults || [] : []
  )
  const fieldName = screenType === ScreenType.Authors ? 'authors' : 'tutors'

  // Used to clear tutors after adding a distribution
  useLazyEffect(() => {
    if (screenType === ScreenType.Tutors) {
      setAuthors([])
    }
  }, [clearPersonAddArray])

  // Whenever we've got an authors state change
  // inform the dataRef of the parent component
  useEffect(() => {
    if (props.dataRef) {
      // preserve author/tutor ids when updating dataRef
      if (screenType === ScreenType.Authors) {
        props.dataRef.current.authors = authors.map((el) => ({
          ...el,
          id:
            el.id ??
            props.dataRef.current.authors?.find(
              (item) => item.email === el.email
            )?.id,
        }))
      } else if (
        screenType === ScreenType.TutorsEdit &&
        props.dataRef.current.distributionsTable.find((el) => el.key === rowKey)
      ) {
        props.dataRef.current.distributionsTable.find(
          (el) => el.key === rowKey
        ).tutors = authors.map((el) => ({
          ...el,
          // If we want to PATCH instead of POST, we want to retrieve id from allAuthors here:
          // TODO probably deprecated. Should be checked in id simplification story
          id:
            el.id ??
            props.dataRef.current.distributionsTable
              .find((el) => el.key === rowKey)
              .tutors?.find((item) => item.email === el.email)?.id,
        }))
      }
    }
    if (screenType !== ScreenType.Tutors && !_.isEqual(authors, defaults)) {
      setHasFormChanged()
    }
  }, [authors, screenType]) // eslint-disable-line

  // Might want to remove this and use email as key?
  const getNewKey = useCallback(() => {
    return authors.length > 0
      ? Math.max(...authors.map((item) => +item.key!)) + 1
      : 0
  }, [authors])

  const modalCCancelHandler = useCallback(() => {
    const { form } = authorCFormRef.current
    setModalCDisplay(false)
    form.resetFields()
  }, [])

  const modalCreateHandler = useCallback(() => {
    const { form } = authorCFormRef.current

    form.validateFields().then((values) => {
      if (authors.map((el) => el.email).includes(values.email)) {
        message.error(Configs.Messages.DuplicateAuthors)
        return
      }
      // Iterate over this bookPublications authors to check if said producer
      // has been added to another step
      values['id'] = involvedBookProducers.filter(
        (el) => el.email === values.email
      )[0]?.id
      if (screenType !== ScreenType.TutorsEdit) {
        parentForm.setFieldsValue({
          [fieldName]: [...authors, { ...values, key: getNewKey() }],
        })
        parentForm.setFields([
          {
            name: fieldName,
            errors: null,
          },
        ])
      }
      setAuthors([...authors, { ...values, key: getNewKey() }])
      form.resetFields()
      setModalCDisplay(false)
    })
  }, [
    authors,
    parentForm,
    screenType,
    fieldName,
    getNewKey,
    involvedBookProducers,
  ])
  const modalECancelHandler = useCallback(() => {
    const { form } = authorEFormRef.current
    setModalEDisplay(false)
    form.resetFields()
  }, [])

  const modalEditHandler = useCallback(() => {
    const { form } = authorEFormRef.current

    form.validateFields().then((values) => {
      let tempAuthors = [...authors]
      tempAuthors[currentEditAuthor.key] = {
        ...values,
        key: currentEditAuthor.key,
      }
      if (
        new Set(tempAuthors.map((el) => el.email)).size !== tempAuthors.length
      ) {
        message.error(Configs.Messages.DuplicateAuthors)
        return
      }
      setAuthors(tempAuthors)
      form.resetFields()
      setModalEDisplay(false)
    })
  }, [authors, currentEditAuthor])

  const authorAddHandler = useCallback(() => {
    props.actions.getAuthorsRequested()
    props.actions.getInstitutionDetailsRequested()
    setModalCDisplay(true)
  }, [props.actions])

  const authorEditHandler = useCallback(
    (_event, author) => {
      props.actions.getAuthorsRequested()
      props.actions.getInstitutionDetailsRequested()
      setCurrentEditAuthor(author)
      setModalEDisplay(true)
    },
    [props.actions]
  )

  const authorDeleteHandler = useCallback(
    (_event, author) => {
      _event.stopPropagation()
      setAuthors(authors.filter((prevAuthor) => prevAuthor.key !== author.key))
    },
    [authors]
  )

  const authorCFormRef = useRef<any>(null)
  const authorEFormRef = useRef<any>(null)

  return (
    <>
      {screenType === ScreenType.TutorsEdit && (
        <AvatarFlexContainer>
          {authors.length > 0 &&
            (authors as any).map((author) => (
              <AuthorTextAvatar
                key={author.key}
                style={{ pointerEvents: 'auto' }}
              >
                {disabled ? (
                  <Button
                    size="small"
                    onClick={(e) => authorEditHandler(e, author)}
                  >
                    {author.firstname.charAt(0)}. {author.lastname}
                  </Button>
                ) : (
                  <Badge
                    count={
                      <CloseCircleTwoTone
                        twoToneColor="red"
                        onClick={(e) => authorDeleteHandler(e, author)}
                      />
                    }
                  >
                    <Button
                      size="small"
                      onClick={(e) => authorEditHandler(e, author)}
                    >
                      {author.firstname.charAt(0)}. {author.lastname}
                    </Button>
                  </Badge>
                )}
              </AuthorTextAvatar>
            ))}

          {!disabled && (
            <AuthorTextAvatar>
              <Button
                shape="circle"
                icon={<PlusOutlined />}
                onClick={authorAddHandler}
                size="small"
                disabled={disabled}
              />
            </AuthorTextAvatar>
          )}
        </AvatarFlexContainer>
      )}

      {screenType !== ScreenType.TutorsEdit && (
        // Use of Col without Row might be a bad idea?
        <Col style={{ marginBottom: 20 }}>
          {screenType === ScreenType.Authors && (
            <FormSubHeader>Συγγραφείς ή/και Επιμελητές</FormSubHeader>
          )}
          {screenType === ScreenType.Tutors && (
            <FormSubHeader>Διδάσκοντες</FormSubHeader>
          )}

          <AvatarFlexContainer>
            <AuthorAvatar>
              <AvatarButton
                shape="circle"
                icon={<PlusOutlined />}
                onClick={authorAddHandler}
                disabled={disabled}
              />
              <AvatarButtonName>Προσθήκη</AvatarButtonName>
            </AuthorAvatar>

            <Form.Item
              {...(screenType === ScreenType.Authors
                ? {}
                : { name: fieldName })}
              rules={[
                () => ({
                  validator() {
                    if (!required || authors.length) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Απαιτείται τουλάχιστον 1 συγγραφέας')
                  },
                  validateTrigger: 'onSubmit',
                }),
              ]}
              noStyle
            >
              {authors.length >= 1 &&
                (authors as any).map((author) => (
                  <AuthorAvatar
                    key={author.key}
                    style={{ pointerEvents: 'auto' }}
                  >
                    {disabled ? (
                      <AvatarBadge
                        onClick={(e) => authorEditHandler(e, author)}
                      >
                        <AvatarIcon size={63} icon={<UserOutlined />} />
                      </AvatarBadge>
                    ) : (
                      <AvatarBadge
                        count={
                          <CloseCircleTwoTone
                            twoToneColor="red"
                            onClick={(e) => authorDeleteHandler(e, author)}
                          />
                        }
                        onClick={(e) => authorEditHandler(e, author)}
                      >
                        <AvatarIcon size={63} icon={<UserOutlined />} />
                      </AvatarBadge>
                    )}
                    <AvatarName>
                      {author.firstname.charAt(0)}. {author.lastname}
                    </AvatarName>
                  </AuthorAvatar>
                ))}
            </Form.Item>
          </AvatarFlexContainer>
        </Col>
      )}

      {/* TODO I really want to merge the "two" forms */}
      <AuthorCreateForm
        visible={modalCDisplay}
        onCancel={modalCCancelHandler}
        onCreate={modalCreateHandler}
        rowKey={rowKey}
        ref={(form) => (authorCFormRef.current = form)}
        allAuthors={allAuthors}
        allInstitutionDetails={allInstitutionDetails}
      />

      <AuthorEditForm
        visible={modalEDisplay}
        onCancel={modalECancelHandler}
        onEdit={modalEditHandler}
        rowKey={rowKey}
        editAuthor={currentEditAuthor}
        disabled={disabled}
        ref={(form) => (authorEFormRef.current = form)}
        allAuthors={allAuthors}
        allInstitutionDetails={allInstitutionDetails}
      />
    </>
  )
}

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allAuthors: state.entities.getData.authors,
    allInstitutionDetails: state.entities.getData.institutionDetails,
    authorsErrors: state.entities.errors.getAuthorsErrors,
    institutionDetailsErrors: state.entities.errors.getInstitutionDetailsErrors,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonAddArray) as any
