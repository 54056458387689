import * as ls from 'local-storage'

// @TODO: We wrapped ls method to add a logger

function set<T>(...args) {
  return ls.set<T>(args[0], args[1])
}

function get<T>(key: string) {
  return ls.get<T>(key)
}

function remove(key: string): void {
  ls.remove(key)
}

function clear(): void {
  ls.clear()
}

export default {
  set,
  get,
  remove,
  clear,
}
