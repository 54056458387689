import { combineReducers } from 'redux'

import CommonReducer from 'common/ducks'
import AuthReducer from 'features/authentication/ducks'
import EntitiesReducer from 'features/bookPublicationForm/ducks'
import BookPublicationsReducer from 'features/bookPublicationsList/ducks'

const rootReducer = combineReducers({
  common: CommonReducer,
  user: AuthReducer,
  entities: EntitiesReducer,
  bookPublications: BookPublicationsReducer,
})

export default rootReducer
