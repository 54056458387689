import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, Input, Button, Layout, Tooltip, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { MailOutlined } from '@ant-design/icons'
import Routes from 'configs/routes'
import Utils from 'common/utils'
import Configs from 'configs/configs'
import { Dispatch, bindActionCreators } from 'redux'
import { AuthenticationActionNames } from 'features/authentication/types'
import { AuthenticationActionCreators } from 'features/authentication/ducks'
import { connect } from 'react-redux'
import { RootState } from 'redux/types'
import { CommonActionCreators } from 'common/ducks'

const CardMargin = styled(Layout)`
  width: min(100vw, 450px);
  align: center;
  padding: 20px 30px 0 30px;
  border-radius: 3px;
`

const ButtonWrapper = styled(Form.Item)`
  width: 50%;
`

const NextButton = styled(Button)`
  min-width: 90px;
  font-weight: 500;
  width: 100%;
`

const ForgotPassword: FC<any> = (props) => {
  const [form] = Form.useForm()
  const { hasSentForgotPasswordEmail, loading, actions } = props
  const [userMail, setUserMail] = useState()

  const handleSubmit = useCallback(() => {
    form.validateFields().then((values) => {
      Utils.logger.info(
        `User :: Authentication :: Forgot Password Request :: `,
        values
      )
      actions.forgotPasswordRequested(values.email)
      setUserMail(values.email)
    })
  }, [form, actions])

  useEffect(() => {
    return () => {
      actions.uiReset()
    }
  }, [actions])

  return (
    <CardMargin>
      <Typography.Title style={{ fontSize: 24 }}>
        Επαναφορά κωδικού
      </Typography.Title>
      {!hasSentForgotPasswordEmail && (
        <Typography.Paragraph>
          Εισάγετε το mail του λογαριασμού σας για να σας σταλεί σύνδεσμος
          επαναφοράς κωδικού.
        </Typography.Paragraph>
      )}
      {!hasSentForgotPasswordEmail ? (
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
              {
                type: 'email',
                message: Configs.Messages.InvalidEmail,
                validateTrigger: 'onBlur',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>
          <ButtonWrapper style={{ float: 'left' }}>
            <Link to={Routes.Home}>
              <Tooltip title="Πίσω">
                <Button
                  icon={<ArrowLeftOutlined />}
                  shape="circle"
                  type="text"
                />
              </Tooltip>
            </Link>
          </ButtonWrapper>
          <ButtonWrapper style={{ float: 'right' }}>
            <NextButton type="primary" htmlType="submit" loading={loading}>
              Συνέχεια
            </NextButton>
          </ButtonWrapper>
        </Form>
      ) : (
        <Typography.Paragraph>
          Εστάλη mail για αλλαγή κωδικού στο{' '}
          {<Typography.Text strong>{userMail}</Typography.Text>}. Μην ξεχάσετε
          να ελέγξετε και το φάκελο των ανεπιθύμητων.
        </Typography.Paragraph>
      )}
    </CardMargin>
  )
}

export const mapDispatchToProps = (
  dispatch: Dispatch<AuthenticationActionNames>
) => {
  return {
    actions: bindActionCreators(
      { ...AuthenticationActionCreators, ...CommonActionCreators },
      dispatch
    ),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    hasSentForgotPasswordEmail: state.user.auth.hasSentForgotPasswordEmail,
    loading: state.common.ui.loading,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword) as any
