import { UIObject } from 'common/types'
import _ from 'lodash'
import * as QueryString from 'query-string'
import HyperneticaLogger from './Logger'

const logger = new HyperneticaLogger('BPM')

export default {
  logger,
}

/* ----------- HELPER FUNCTIONS -------------  */

const purifyObject = (obj: UIObject): UIObject => {
  const { id, key, ...pureObj } = obj
  return pureObj
}

export const uiObjectChanged = (obj1: UIObject, obj2: UIObject): boolean => {
  const pureObj1 = purifyObject(obj1)
  const pureObj2 = purifyObject(obj2)
  return _.isEqual(pureObj1, pureObj2)
}

export const includesIgnoringKey = (
  list: UIObject[],
  obj: UIObject
): boolean => {
  return list?.some((item) =>
    _.isEqual(_.omit(item, ['key']), _.omit(obj, ['key']))
  )
}

export const removeEmptyObjects = (obj: Object): Partial<Object> => {
  return _.omitBy(
    JSON.parse(JSON.stringify(obj)),
    (prop) => _.isPlainObject(prop) && _.isEmpty(prop)
  )
}

export function ForceArray<T>(obj: T | T[]): T[] {
  if (Array.isArray(obj)) {
    return obj
  }
  return [obj]
}

export const padLeading = (n: number | string, width: number, z?: string) => {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const keepLastOccurrence = (arr) => {
  // magic StackOverflow one-liner
  return arr
    .slice()
    .reverse()
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
    .reverse()
}

export const sorterStringCompare = (str1, str2) => {
  return (str1 || '').localeCompare(str2 || '')
}

export const sorterNumberCompare = (num1, num2) => {
  return (num1 || 0) - (num2 || 0)
}

export const sorterDateCompare = (date1, date2) => {
  const date1str = date1 ? date1.format('YYYYMMDD') : ''
  const date2str = date2 ? date2.format('YYYYMMDD') : ''
  return date1str - date2str
}

export const getQueryParams = (location) => QueryString.parse(location.search)
