import React, { FC, useCallback, useEffect, useState } from 'react'
import { Col, Layout, Menu, notification, Row } from 'antd'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import Services from 'services'
import Constants from 'common/constants'

import Routes from 'configs/routes'
import Logo from 'assets/images/logo.png'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { RootState } from 'redux/types'
import { AuthenticationActionCreators } from 'features/authentication/ducks'
import { connect } from 'react-redux'
import { UIUser } from 'features/authentication/types'

const MenuItem = styled(Menu.Item)`
  height: 66px;

  .ant-menu-dark.ant-menu-horizontal > &.ant-menu-item {
    border-bottom: 2px solid transparent;
    transition: none;
    padding-top: 9px;
  }

  .ant-menu-dark &.ant-menu-item.ant-menu-item-active {
    background: #e4e4e447;
  }

  .ant-menu-dark &.ant-menu-item .anticon {
    margin-right: 0;
  }
`

const MenuWrapper = styled(Menu)`
  line-height: 48px;

  &.ant-menu-dark.ant-menu-horizontal {
    background: transparent;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    &.ant-menu-root {
      margin-top: -9px;
    }
  }
`

const LogoImg = styled.img`
  object-fit: contain;
  height: 46px;
  width: 150px;
`

const LayoutHeader = styled(Layout.Header)`
  width: 100%;
  height: 48px;
  padding: 0 25px 0 25px;
  background-image: linear-gradient(to right, #848484, #202020);
`

const HeaderBar: FC<any> = (props) => {
  const { logout, isAuthenticated } = props

  const history = useHistory()
  const [currentUser, setCurrentUser] = useState<UIUser>()

  const logoutClickHandler = useCallback(() => {
    notification.destroy()
    logout()
    history.push(Routes.Home)
  }, [history, logout])

  useEffect(() => {
    if (isAuthenticated) {
      setCurrentUser(
        JSON.parse(Services.LocalStorage.get(Constants.STORAGE_TOKEN_USER_DATA))
      )
    }
  }, [isAuthenticated])

  return (
    <LayoutHeader>
      <Row>
        <Col span={4}>
          <Link to={Routes.Home} style={{ display: 'flex' }}>
            <LogoImg src={Logo} alt="logo" />
          </Link>
        </Col>
        <Col span={12} offset={8}>
          <MenuWrapper theme="dark" mode="horizontal">
            {props.isAuthenticated && (
              <MenuWrapper theme="dark" mode="horizontal">
                <MenuItem key="1">
                  {currentUser?.firstname} {currentUser?.lastname} &nbsp;
                  <UserOutlined style={{ fontSize: 18 }} />
                </MenuItem>
                <MenuItem key="2" onClick={logoutClickHandler}>
                  Αποσύνδεση &nbsp;
                  <LogoutOutlined style={{ fontSize: 16 }} />
                </MenuItem>
              </MenuWrapper>
            )}
          </MenuWrapper>
        </Col>
      </Row>
    </LayoutHeader>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: state.user.auth.isAuthenticated,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(AuthenticationActionCreators.logoutRequested()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar)
