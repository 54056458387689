import React, { FC } from 'react'
import { Progress } from 'antd'
import { CardWrapper } from 'features/bookPublicationForm/screens/styles'
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const ProgressWrap = styled(Progress)`
  margin-top: 10px !important;

  .ant-progress-text {
    font-size: 18px;
  }
`

const TotalProgressWrap = styled(Progress)`
  .ant-progress-outer {
    width: 98%;
  }

  .ant-progress-text {
    font-size: 18px;
  }
`

const calcProg = (current: string, total: string): number => {
  return Math.round((+current / +total) * 100)
}

const AuthoringProgressCard: FC<any> = (props) => {
  const { chuncksDeliveredNum, chuncksTotalNum, active, onClick } = props

  return (
    <CardWrapper
      title={props.title}
      extra={
        active ? <ShrinkOutlined onClick={onClick} /> : <ArrowsAltOutlined />
      }
      style={{ pointerEvents: 'auto' }}
      hoverable={!active}
      onClick={!active ? onClick : () => void 0}
      bodyStyle={{ padding: active ? '' : '0 0 24px 0' }}
      cover={
        <ProgressWrap
          type="circle"
          percent={calcProg(chuncksDeliveredNum, chuncksTotalNum)}
          format={() => `${chuncksDeliveredNum} / ${chuncksTotalNum}`}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      }
    >
      {active && props.deliverablesTable}
    </CardWrapper>
  )
}

export const TotalProgressCard: FC<any> = (props) => {
  const { chuncksDeliveredNum, chuncksTotalNum } = props

  return (
    <TotalProgressWrap
      strokeWidth={14}
      percent={calcProg(chuncksDeliveredNum, chuncksTotalNum)}
      format={() => `${chuncksDeliveredNum} / ${chuncksTotalNum}`}
      strokeColor={{
        '0%': '#108ee9',
        '100%': '#87d068',
      }}
    />
  )
}

export default AuthoringProgressCard
