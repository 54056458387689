import React, { FC, useEffect, useState } from 'react'
import { Button, Col, notification, Row, Table, Tag, Tooltip } from 'antd'
import styled from 'styled-components'
import Configs from 'configs/configs'
import {
  BookPublicationsListItem,
  BookPublicationsListType,
  PublicationStep,
} from 'features/bookPublicationsList/types'
import { Link } from 'react-router-dom'
import Routes from 'configs/routes'
import Services from 'services'
import Constants from 'common/constants'

import { Dispatch, bindActionCreators } from 'redux'
import { BookPublicationsActionCreators } from 'features/bookPublicationsList/ducks'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'
import {
  getListItemFromBookPublication,
  getPublicationCsvItem,
  isEmptyBookPublication,
  isPublicationCompleted,
  isPublicationPendingOnUser,
} from 'features/bookPublicationsList/utils'
import { UIUser } from 'features/authentication/types'
import { CSVLink } from 'react-csv'
import { DownloadOutlined } from '@ant-design/icons'
import { UIBookPublication } from 'features/bookPublicationForm/types'
import {
  sorterDateCompare,
  sorterNumberCompare,
  sorterStringCompare,
} from 'common/utils'

const FormHeader = styled.h3`
  width: 99%;
  border-bottom: 0.5px solid;
  font-weight: strong;
  margin-bottom: 20px;
  text-align: center;
`

const LegendHeader = styled.h4`
  font-size: 110%;
  font-weight: 500;
  color: #505050;
  text-decoration: underline;
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: left;
`

const pageSize = 20

const BookPublicationsList: FC<any> = (props) => {
  const bookPublications: UIBookPublication[] = props.bookPublications
  const { actions, type, isAuthenticated } = props

  const generateConfiguration = (listType: BookPublicationsListType) => {
    const defaultConf = {
      selfAssignee: false,
      rejected: false,
      completed: false,
      all: false,
    }
    switch (listType) {
      case BookPublicationsListType.SelfPending:
        return {
          ...defaultConf,
          selfAssignee: true,
          title: Configs.MainTabTitles.SelfPending,
        }
      case BookPublicationsListType.Pending:
        return { ...defaultConf, title: Configs.MainTabTitles.Pending }
      case BookPublicationsListType.Rejected:
        return {
          ...defaultConf,
          rejected: true,
          title: Configs.MainTabTitles.Rejected,
        }
      case BookPublicationsListType.Completed:
        return {
          ...defaultConf,
          completed: true,
          title: Configs.MainTabTitles.Completed,
        }
      case BookPublicationsListType.All:
        return { ...defaultConf, all: true, title: Configs.MainTabTitles.All }
    }
  }
  const includeElement = (
    listType: BookPublicationsListType,
    publication: UIBookPublication
  ) => {
    if (!isEmptyBookPublication(publication)) {
      switch (listType) {
        case BookPublicationsListType.All:
          return true
        case BookPublicationsListType.SelfPending:
          return (
            publication.publicationDetails.resolution &&
            isPublicationPendingOnUser(publication, currentUser?.id!) &&
            !isPublicationCompleted(publication)
          )
        case BookPublicationsListType.Pending:
          return (
            publication.publicationDetails.resolution &&
            !isPublicationCompleted(publication)
          )
        case BookPublicationsListType.Rejected:
          return (
            !publication.publicationDetails.resolution &&
            !isPublicationCompleted(publication)
          )
        case BookPublicationsListType.Completed:
          return (
            publication.publicationDetails.resolution &&
            isPublicationCompleted(publication)
          )
      }
    }
    return false
  }

  const {
    selfAssignee,
    rejected,
    completed,
    all,
    title,
  } = generateConfiguration(type)

  const [bookPublicationsData, setBookPublicationsData] = useState<
    BookPublicationsListItem[]
  >([])
  const [currentUser, setCurrentUser] = useState<UIUser>()

  useEffect(() => {
    // destroy possible leftover error notifications from login
    notification.destroy()
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      setCurrentUser(
        JSON.parse(Services.LocalStorage.get(Constants.STORAGE_TOKEN_USER_DATA))
      )
    }
  }, [isAuthenticated])

  useEffect(() => {
    actions.getBookPublicationsRequested()
  }, [actions])

  useEffect(() => {
    if (bookPublications.length) {
      setBookPublicationsData(
        bookPublications.reduce((acc, el, i) => {
          if (includeElement(type, el)) {
            return acc.concat({
              ...getListItemFromBookPublication(el),
              key: i,
            })
          }
          return acc
        }, [] as BookPublicationsListItem[])
      )
    }
    // setBookPublicationsData(TestPublicationListData)     // use this line instead of the above for test data
  }, [bookPublications, selfAssignee]) // eslint-disable-line

  const [csvData, setCsvData] = useState<any>([])

  useEffect(() => {
    if (bookPublicationsData.length) {
      setCsvData(bookPublicationsData.map((el) => getPublicationCsvItem(el)))
    }
  }, [bookPublicationsData])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'seqId',
      sorter: (a, b) => sorterNumberCompare(+a.seqId, +b.seqId),
      render: (record) => record, // present hex as a decimal number
      width: 100,
    },
    {
      title: 'Τίτλος Βιβλίου',
      dataIndex: 'bookTitle',
      sorter: (a, b) => sorterStringCompare(a.bookTitle, b.bookTitle),
      render: (record, row) => (
        <Link
          to={{
            pathname: Routes.BookPublicationForm,
            state: {
              seqId: row.seqId,
            },
          }}
        >
          {record ?? <i>{Configs.Messages.UntitledBookPublication}</i>}
        </Link>
      ),
      ellipsis: true,
    },
    {
      title: 'Εκκρεμή Στάδια',
      dataIndex: 'unfinishedSteps',
      filters: PublicationStep.getAllAsArray().map((step) => ({
        text: (
          <>
            <Tag color="geekblue" key={step.index}>
              {step.index}
            </Tag>
            {step.toString()}
          </>
        ),
        value: step.index,
      })),
      onFilter: (value, record) =>
        record.unfinishedSteps.map((step) => step.index).includes(value),
      render: (unfinishedSteps) => (
        <span>
          {unfinishedSteps.length ? (
            unfinishedSteps.map((step) => {
              return (
                <Tag color="geekblue" key={step.index}>
                  {step.index}
                </Tag>
              )
            })
          ) : (
            <i>{Configs.Messages.CompletedBookPublication}</i>
          )}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: 'Τελευταία Ανανέωση',
      dataIndex: 'updatedDate',
      sorter: (a, b) => sorterDateCompare(a.updatedDate, b.updatedDate),
      render: (record) =>
        record ? record.format(Configs.Date.FormatWithTime) : '-',
    },
    {
      title: rejected
        ? 'Ημερομηνία Απόρριψης'
        : 'Ημερομηνία Έγκρισης/Απόρριψης',
      dataIndex: 'resolutionDate',
      sorter: (a, b) => sorterDateCompare(a.resolutionDate, b.resolutionDate),
      render: (record) =>
        record ? record.format(Configs.Date.FormatWithTime) : '-',
    },
  ].filter(
    (el) =>
      all ||
      ((rejected || el.dataIndex !== 'resolutionDate') &&
        (!completed || el.dataIndex !== 'unfinishedSteps'))
  )

  return (
    <>
      <Row>
        <Col span={23}>
          <FormHeader>{title}</FormHeader>
        </Col>
        <Col span={1}>
          <Tooltip title="Εξαγωγή">
            <CSVLink
              data={csvData}
              headers={columns.map((el) => ({
                label: el.title,
                key: el.dataIndex,
              }))}
              separator={';'}
            >
              <Button icon={<DownloadOutlined />} />
            </CSVLink>
          </Tooltip>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={bookPublicationsData}
        bordered
        pagination={{
          total: bookPublicationsData.length,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          // hideOnSinglePage: true,
        }}
      />
      <LegendHeader>Υπόμνημα σταδίων πρότασης</LegendHeader>
      <Row gutter={[16, 16]} style={{ maxWidth: '700px' }}>
        {PublicationStep.getAllAsArray().map((step, idx) => (
          <Col xs={24} sm={12} key={step.index}>
            <div>
              <Tag color="geekblue">{step.index}</Tag>
              <span style={{ fontWeight: 500 }}>{step.toString()}</span>
            </div>
          </Col>
        ))}
      </Row>
    </>
  )
}

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators(
      { ...BookPublicationsActionCreators },
      dispatch
    ),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: state.user.auth.isAuthenticated,

    bookPublications: state.bookPublications.data.bookPublications,
    bookPublicationsErrors:
      state.bookPublications.errors.bookPublicationsErrors,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookPublicationsList) as any
