const Endpoints = {
  User: {
    Login: '/user/login',
    Refresh: '/user/refresh',
    Register: '/user/register',
    ForgotPassword: '/user/forgot-password',
    ResetPassword: '/user/reset-password',
  },
  Entities: {
    BookPublication: '/book-publication',
    Authors: '/book-producer',
    InstitutionDetails: '/institution-details',
    BookCategories: '/book-category',
    Book: '/book',
    BookDistributions: '/distribution-details',
    Contracts: '/contract',
    TechnicalInstructions: '/technical-details',
    AuthoringProgress: '/authoring-progress',
    BookSeries: '/series',
    Users: '/user',
    Comments: '/comment',
    Steps: '/step',
  },
}

export default Endpoints
