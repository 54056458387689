import Configs from 'configs/configs'
import { UIContract, UIDistribution, UITechnicalInstruction } from './types'

export const getDistributableCsvItem = (distribution: UIDistribution) => {
  return {
    university: distribution.institutionDetails?.university,
    department: distribution.institutionDetails?.department,
    course: distribution.course,
    numCopies: distribution.numCopies,
    tutors: distribution.tutors?.map((el) => el.lastname + ' ' + el.firstname),
  }
}

export const getContractCsvItem = (contract: UIContract) => {
  return {
    author: contract.author.lastname + ' ' + contract.author.firstname,
    draftSentDate: contract.draftSentDate?.format(Configs.Date.Format),
    draftApprovalDate: contract.draftApprovalDate?.format(Configs.Date.Format),
    finalSentDate: contract.finalSentDate?.format(Configs.Date.Format),
    signDate: contract.signDate?.format(Configs.Date.Format),
    contractStartDate: contract.contractEffectDates?.[0]?.format(
      Configs.Date.Format
    ),
    contractEndDate: contract.contractEffectDates?.[1]?.format(
      Configs.Date.Format
    ),
  }
}

export const getTechnicalInstructionCsvItem = (
  instruction: UITechnicalInstruction
) => {
  return {
    author: instruction.author.lastname + ' ' + instruction.author.firstname,
    sentDate: instruction.sentDate?.format(Configs.Date.Format),
  }
}
