import { Button, Card, Form, InputNumber, Select } from 'antd'
import styled from 'styled-components'

// Form related:
export const StepForm = styled(Form)<{ $stepDisabled: boolean }>`
  pointer-events: ${(props) => (props.$stepDisabled ? 'none' : 'auto')};
`

export const FormHeader = styled.h3`
  width: 100%;
  border-bottom: 0.5px solid;
  font-weight: strong;
  margin-bottom: 20px;
`

export const FormSubHeader = styled.h3`
  width: 96%;
  border-bottom: 0.5px solid;
  font-weight: strong;
`

export const ModalColFormItem = styled(Form.Item)`
  width: 95%;
`

// Inputs:
export const InputNumberPure = styled(InputNumber)`
  width: 100%;

  &.ant-input-number {
    width: 100%;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`

export const SelectWide = styled(Select)`
  width: 400px;
`

// Misc:
export const CardWrapper = styled(Card)`
  text-align: center;

  // Bad practice but I really couldn't find a way to style this part
  .ant-card-actions {
    background-color: #fff;
  }
`

export const TableDiv = styled.div`
  margin-bottom: 12px;
  // width: 96%;

  .ant-table {
    // on tables with 2 lines at least this height is required
    line-height: 1.3 !important;
  }
`

export const TableAddButton = styled(Button)<{ disabled: boolean }>`
  display: ${(props) => (props.disabled ? 'none' : '')};
  margin-top: 7px;
`
