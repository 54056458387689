import { IDataObject, JsonApi } from '@hypernetica/json-api'
import { DomainError, ErrorCodes } from '@hypernetica/error'
import { notification } from 'antd'

import Utils from 'common/utils'
import Configs from 'configs/configs'

class Api {
  path: string = '/'

  /**
   * Helper method to validate
   * and store the request path.
   */
  private setPath = (path: string) => {
    if (!/^\/[a-z0-9]/i.test(path)) {
      // @TODO: thrown exception and log it
      void 0
    }
    this.path = path

    return this
  }

  /**
   * Validates the JSONAPI spec against the request's body
   * and performs a POST request.
   */
  public post = async (
    path: string,
    meta: { body?: {}; options: { token: string } }
  ): Promise<{} | null> => {
    if (meta.body && !this.validateJsonApi(meta.body)) {
      Utils.logger.warn(
        'Service :: API :: post :: Invalid JSON_API request %o',
        meta
      )
      return null
    }

    return await this.setPath(path).call('POST', meta)
  }

  /**
   * Validates the JSONAPI spec against the request's body
   * and performs a PATCH request.
   */
  public patch = async (
    path: string,
    meta: { body: {}; options: { token: string } }
  ): Promise<{} | null> => {
    if (!this.validateJsonApi(meta.body)) {
      Utils.logger.warn(
        'Service :: API :: patch :: Invalid JSON_API request %o',
        meta
      )
      return null
    }

    return await this.setPath(path).call('PATCH', meta)
  }

  /**
   * Performs the GET request.
   */
  public get = async (
    path: string,
    meta: { options: { token: string } }
  ): Promise<{}> => {
    return await this.setPath(path).call('GET', meta)
  }

  /**
   * Performs the DELETE request.
   */
  public delete = async (
    path: string,
    meta: { options: { token: string } }
  ): Promise<{}> => {
    return await this.setPath(path).call('DELETE', meta)
  }

  /**
   * Main service method that set the proper headers for the request
   * and validates based on the format the api response.
   */
  public call = async (
    method: string,
    meta: { body?: {}; options?: { token: string } }
  ): Promise<{}> => {
    const headers = {
      Accept: `application/vnd.api+json`,
      'Content-type': `application/vnd.api+json`,
    }

    let response: Response | null = null

    try {
      // The Promise returned from fetch() won’t reject on HTTP error status even
      // if the response is an HTTP 404 or 500.
      //
      // Instead, it will resolve normally (with ok status set to false),
      // and it will only reject on network failure or if anything prevented
      // the request from completing.
      response = await fetch(process.env.REACT_APP_API_ENDPOINT + this.path, {
        method,
        headers,
        credentials: 'include',
        ...((method === 'POST' || method === 'PATCH') &&
          meta.body && {
            body: JsonApi.serialize(meta.body as IDataObject | IDataObject[]),
          }),
      })
    } catch (error) {
      Utils.logger.error(
        'Service :: API :: call :: Request failed with "%s"',
        error
      )
      notification.error({
        message: Configs.ErrorMessages.RequestFailed,
        description: Configs.ErrorMessages.ServerDown,
      })
    }

    return await this.response(response)
  }

  /**
   * Wrapper of the fetch response is order to handle the
   * different json formats.
   */
  private response = async (response): Promise<{}> => {
    const contentType = response.headers.get('content-type')

    if (contentType?.indexOf('json') < 0) {
      Utils.logger.error('Service :: API :: response :: Not a JSON format')

      throw new Error(`The response is not a JSON format`)
    }

    // Whether response is of type
    // "No Content" return early
    if (response.status === 204) {
      return {}
    }

    let json = await response.json()

    if (!response.ok) {
      Utils.logger.error(
        'Service :: API :: response :: Bad HTTP code %o',
        json.errors
      )
      this.showErrorNotification(json.errors)

      // Those error messages are propagated to the
      // caller in the format {stack: '...', message: '...'}
      throw new Error(JSON.stringify(json.errors))
    }

    if (contentType.indexOf('vnd.api+json') >= 0) {
      json = JsonApi.deserialize(JSON.stringify(json))
    }

    return json
  }

  /**
   * Validate JSONAPI request format
   */
  private validateJsonApi(body): boolean {
    const validJson = Object.keys(body).every((key) =>
      /id|type|attributes|relationships/.test(key)
    )

    return validJson
  }

  private showErrorNotification(errors: DomainError[]): void {
    switch (errors[0].code) {
      case ErrorCodes.AuthenticationFailed:
        notification.error({
          message: Configs.ErrorMessages.Authentication.Default,
          description: Configs.ErrorMessages.Authentication.WrongPassword,
        })
        break
      case ErrorCodes.UserUnknown:
        notification.error({
          message: Configs.ErrorMessages.Authentication.Default,
          description: Configs.ErrorMessages.Authentication.UserUnknown,
        })
        break
      case ErrorCodes.NotAuthorized:
        notification.error({
          message: Configs.ErrorMessages.Authorization.Default,
          description: Configs.ErrorMessages.Authorization.UnauthorizedUser,
        })
        break

      default:
        notification.error({
          message: Configs.ErrorMessages.BadResponse,
          description: Configs.ErrorMessages.ContactUs,
        })
    }
  }
}

export default new Api()
