import React, {
  FC,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'

import { Form } from 'antd'
import {
  FormHeader,
  StepForm,
} from 'features/bookPublicationForm/screens/styles'
import CompletionSection from 'features/bookPublicationForm/screens/CompletionSection'
import EditableInstructionsTable from './EditableInstructionsTable'

import { Dispatch, bindActionCreators } from 'redux'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'
import { useLazyEffect } from 'common/hooks/useLazyEffect'
import _ from 'lodash'
import { TechnicalInstructionsStepData } from 'features/bookPublicationForm/types'

const TechnicalInstructions: FC<any> = forwardRef((props, ref) => {
  let defaults: TechnicalInstructionsStepData = props.defaults
  const {
    authors,
    stepDisabled,
    setHasFormChanged,
    actions,
    stepSubmitting,
    stepSubmittedData,
    stepSubmittedErrors,
    allUsers,
  } = props

  const [form] = Form.useForm()

  /**
   * useImperativeHandle customizes the instance value
   * that is exposed to parent components when using ref.
   *
   * We need to expose only the handle submit to the parent
   * instance to handle the validation of the current form
   * from the next/prev step buttons.
   */
  useImperativeHandle(ref, () => ({
    submitHandler,
  }))

  useLazyEffect(() => {
    if (
      !stepSubmitting &&
      !stepSubmittedErrors.length &&
      !_.isEmpty(stepSubmittedData)
    ) {
      dataRef.current.instructionsSentTable =
        stepSubmittedData.technicalInstructions.instructionsSentTable
      defaults = stepSubmittedData.technicalInstructions
    }
  }, [stepSubmitting, stepSubmittedData, stepSubmittedErrors])

  const submitStepValues = useCallback(
    (values) => {
      if (values.completion?.assignees) {
        values.completion.assignees = values.completion.assignees.map(
          (assigneeMail) => allUsers.find((user) => user.email === assigneeMail)
        )
      }
      values.completion.stepEntityId = defaults.completion.stepEntityId
      actions.submitTechnicalInstructionsRequested(
        {
          ...values,
          ...dataRef.current,
        },
        defaults
      )
    },
    [actions, defaults, allUsers]
  )

  /**
   * Step form submit callback used
   * by the parent component
   */
  const submitHandler = useCallback(() => {
    if (form.getFieldValue(['completion', 'stepCompleted'])) {
      form.validateFields().then((values) => submitStepValues(values))
    } else {
      submitStepValues(form.getFieldsValue())
    }
  }, [form, submitStepValues])

  /**
   * Use this ref data structure to gather
   * the submitted values of the inner components.
   *
   * It will help us determine/validate the state of this step
   */
  const dataRef = useRef({
    instructionsSentTable: [],
  })

  return (
    <StepForm
      form={form}
      initialValues={defaults}
      $stepDisabled={stepDisabled}
      onFieldsChange={() => setHasFormChanged(true)}
    >
      <FormHeader>Αποστολές Τεχνικών Οδηγιών</FormHeader>
      <EditableInstructionsTable
        dataRef={dataRef}
        authors={authors}
        defaults={defaults.instructionsSentTable}
        disabled={stepDisabled}
        setHasFormChanged={() => setHasFormChanged(true)}
      />
      <FormHeader>Ολοκλήρωση Αποστολής Τεχνικών οδηγιών</FormHeader>
      <CompletionSection defaults={defaults.completion} form={form} />
    </StepForm>
  )
})

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allUsers: state.entities.getData.users,
    usersErrors: state.entities.errors.getUsersErrors,

    stepSubmitting: state.entities.stepData.stepSubmitting,
    stepSubmittedData: state.entities.stepData.stepSubmittedData,
    stepSubmittedErrors: state.entities.stepData.stepSubmittedErrors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TechnicalInstructions) as any
