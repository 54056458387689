import React, { FC, useState } from 'react'

import { Row, Col, Typography } from 'antd'

import styled from 'styled-components'
import Configs from 'configs/configs'
import { UIBookPublication, UIAuthor } from 'features/bookPublicationForm/types'
import EditableDistributionsTable from './PublicationDetails/EditableDistributionsTable'

const PreviewLabel = styled.span`
  font-weight: bold;
`

const PreviewItem = styled.span`
  font-style: italic;
`

const PreviewTitle = styled.h2`
  width: 100%;
  border-bottom: 0.5px solid;
  font-weight: strong;
  margin-bottom: 20px;
  text-align: center;
`

const PreviewHeader = styled.h3`
  width: 100%;
  border-bottom: 0.5px solid;
  font-weight: strong;
  text-align: center;
`

const PreviewSubHeader = styled.h3`
  width: 96%;
  border-bottom: 0.5px solid;
  font-weight: strong;
  text-align: center;
`

const PublicationReview: FC<any> = (props) => {
  const printBoolean = (b: boolean): string => {
    return b ? 'Ναι' : 'Όχι'
  }

  const printAuthorDetails = (item: UIAuthor, index) => (
    <li key={index}>
      <Row>
        <Col span={12}>
          <p>
            <PreviewLabel>Όνομα: </PreviewLabel>
            <PreviewItem>{item.firstname}</PreviewItem>
          </p>
        </Col>
        <Col span={12}>
          <p>
            <PreviewLabel>Επώνυμο: </PreviewLabel>
            <PreviewItem>{item.lastname}</PreviewItem>
          </p>
        </Col>
      </Row>
      <p>
        <PreviewLabel>Email: </PreviewLabel>
        <PreviewItem>{item.email}</PreviewItem>
      </p>
      <Row>
        <Col span={12}>
          <p>
            <PreviewLabel>Τηλέφωνο 1: </PreviewLabel>
            <PreviewItem>{item.phone1}</PreviewItem>
          </p>
        </Col>
        <Col span={12}>
          <p>
            <PreviewLabel>Τηλέφωνο 2: </PreviewLabel>
            <PreviewItem>{item.phone2}</PreviewItem>
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>
            <PreviewLabel>Πόλη: </PreviewLabel>
            <PreviewItem>{item.address?.city}</PreviewItem>
          </p>
          <p>
            <PreviewLabel>Οδός: </PreviewLabel>
            <PreviewItem>{item.address?.street}</PreviewItem>
          </p>
        </Col>
        <Col span={12}>
          <p>
            <PreviewLabel>Τ.Κ.: </PreviewLabel>
            <PreviewItem>{item.address?.postcode}</PreviewItem>
          </p>
          <p>
            <PreviewLabel>Αριθμός: </PreviewLabel>
            <PreviewItem>{item.address?.number}</PreviewItem>
          </p>
        </Col>
      </Row>
      <p>
        <PreviewLabel>Πανεπιστήμιο: </PreviewLabel>
        <PreviewItem>{item.institutionDetails?.university}</PreviewItem>
      </p>
      <p>
        <PreviewLabel>Τμήμα: </PreviewLabel>
        <PreviewItem>{item.institutionDetails?.department}</PreviewItem>
      </p>
    </li>
  )

  const {
    bookDetails,
    publicationDetails,
    // contractSigning,
    // technicalInstructions,
    // authoring,
    // manuscriptVerification,
    publicationVerification,
  }: UIBookPublication = props.formData

  const [totalCopies, setTotalCopies] = useState<number>(0)

  return (
    <>
      {/* <Row>
        <Col span={23}> */}
      <PreviewTitle style={{ textAlign: 'center' }}>
        Καρτέλα Πρότασης Έκδοσης
      </PreviewTitle>
      {/* </Col>
        <Col span={1}>
          <PrinterFilled style={{ fontSize: '28px' }} />
        </Col>
      </Row> */}
      <Row>
        <PreviewHeader>Πληροφορίες Βιβλίου</PreviewHeader>
        <Col span={12}>
          <p>
            <PreviewLabel>Τίτλος: </PreviewLabel>
            <PreviewItem>{bookDetails.title}</PreviewItem>
          </p>
          <p>
            <PreviewLabel>Πλήθος Σελίδων: </PreviewLabel>
            <PreviewItem>{bookDetails.numPages}</PreviewItem>
          </p>
          <p>
            <PreviewLabel>Είδος Αρχείου: </PreviewLabel>
            <PreviewItem>
              {bookDetails.oneFile ? 'Ενιαίο Αρχείο' : 'Ανά Κεφάλαιο'}
            </PreviewItem>
          </p>
          <p>
            <PreviewLabel>Χρώμα: </PreviewLabel>
            <PreviewItem>
              {bookDetails.colour ? 'Ασπρόμαυρο' : 'Τετράχρωμο'}
            </PreviewItem>
          </p>
          <p>
            <PreviewLabel>Πρόγραμμα Συγγραφής: </PreviewLabel>
            <PreviewItem>{bookDetails.writtenFormat}</PreviewItem>
          </p>
          <p>
            <PreviewLabel>Κατηγορία Βιβλίου: </PreviewLabel>
            <PreviewItem>
              {bookDetails.bookCategoryParent
                ? bookDetails.bookCategoryParent
                : '-'}
            </PreviewItem>
          </p>
          <p>
            <PreviewLabel>Υποκατηγορία Βιβλίου: </PreviewLabel>
            <PreviewItem>
              {bookDetails.bookCategoryChild
                ? bookDetails.bookCategoryChild
                : '-'}
            </PreviewItem>
          </p>
          <p>
            <PreviewLabel>Σειρά Βιβλίου: </PreviewLabel>
            <PreviewItem>
              {bookDetails.bookSeries ? bookDetails.bookSeries.name : '-'}
            </PreviewItem>
          </p>
          <Row>
            <Col span={12}>
              <p>
                <PreviewLabel>Σχήμα Βιβλίου: </PreviewLabel>
                <PreviewItem>{bookDetails.bookDimensions}</PreviewItem>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <PreviewLabel>Σχόλια: </PreviewLabel>
                <PreviewItem>
                  {bookDetails.bookDimensionsComments
                    ? bookDetails.bookDimensionsComments
                    : '-'}
                </PreviewItem>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>
                <PreviewLabel>Εξισώσεις: </PreviewLabel>
                <PreviewItem>{printBoolean(bookDetails.equations)}</PreviewItem>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <PreviewLabel>Σχόλια: </PreviewLabel>
                <PreviewItem>
                  {bookDetails.equationsComments
                    ? bookDetails.equationsComments
                    : '-'}
                </PreviewItem>
              </p>
            </Col>
          </Row>
          <p>
            <PreviewLabel>Πίνακες: </PreviewLabel>
            <PreviewItem>{printBoolean(bookDetails.tables)}</PreviewItem>
          </p>
          <p>
            <PreviewLabel>Γραφήματα: </PreviewLabel>
            <PreviewItem>{printBoolean(bookDetails.diagrams)}</PreviewItem>
          </p>
          <p>
            <PreviewLabel>Φωτογραφίες: </PreviewLabel>
            <PreviewItem>{printBoolean(bookDetails.photos)}</PreviewItem>
          </p>
          <Row>
            <Col span={12}>
              <p>
                <PreviewLabel>Εικόνες με πνευματικά δικαιώματα: </PreviewLabel>
                <PreviewItem>
                  {printBoolean(bookDetails.picturesCopyRight)}
                </PreviewItem>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <PreviewLabel>Σχόλια: </PreviewLabel>
                <PreviewItem>
                  {bookDetails.picturesCopyRightComments
                    ? bookDetails.picturesCopyRightComments
                    : '-'}
                </PreviewItem>
              </p>
            </Col>
          </Row>
          <p>
            <PreviewLabel>Κείμενο με πνευματικά δικαιώματα: </PreviewLabel>
            <PreviewItem>{printBoolean(bookDetails.textCopyRight)}</PreviewItem>
          </p>
        </Col>
        <Col span={12}>
          <PreviewSubHeader>Συγγραφείς/Επιμελητές</PreviewSubHeader>
          <ol>
            {bookDetails.authors.map((item, index) =>
              printAuthorDetails(item, index)
            )}
          </ol>
        </Col>
      </Row>
      <br />
      <Row>
        <PreviewHeader>Συμφωνία Έκδοσης</PreviewHeader>
        <Col span={12}>
          <p>
            <PreviewLabel>Η πρόταση: </PreviewLabel>
            {publicationDetails.resolution ? (
              <PreviewItem>Εγκρίνεται</PreviewItem>
            ) : (
              <PreviewLabel style={{ color: 'red' }}>Απορρίπτεται</PreviewLabel>
            )}
          </p>
          <p>
            <PreviewLabel>
              Ημερομηνία{' '}
              {publicationDetails.resolution ? 'Έγκρισης' : 'Απόρριψης'}:
            </PreviewLabel>
            <PreviewItem>
              {publicationDetails.resolutionDate?.format(Configs.Date.Format)}
            </PreviewItem>
          </p>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <p>
                <PreviewLabel>Ημερομηνία Καταχώρησης Πρότασης: </PreviewLabel>
                <PreviewItem>
                  {publicationDetails.publicationDate?.format(
                    Configs.Date.Format
                  )}
                </PreviewItem>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <PreviewLabel>Σχόλια: </PreviewLabel>
                <PreviewItem>
                  {publicationDetails.publicationComments
                    ? publicationDetails.publicationComments
                    : '-'}
                </PreviewItem>
              </p>
            </Col>
          </Row>
          <p>
            <PreviewLabel>Επιθυμητή Ημερομηνία Έκδοσης: </PreviewLabel>
            <PreviewItem>
              {publicationDetails.desiredReleaseDate?.format(
                Configs.Date.Format
              )}
            </PreviewItem>
          </p>
        </Col>
        <PreviewHeader>Πανεπιστημιακές διανομές</PreviewHeader>
        {publicationDetails.isDistributable ? (
          <>
            <EditableDistributionsTable
              defaults={publicationDetails.distributionsTable}
              disabled={true}
              setTotalCopies={setTotalCopies}
            />
            <div style={{ padding: '1em 0 1em 0' }}>
              <Typography.Text strong style={{ fontSize: 16 }}>
                Σύνολο αντιτύπων: {totalCopies}
              </Typography.Text>
            </div>
          </>
        ) : (
          <p>
            <PreviewItem>
              Το βιβλίο δεν προσφέρεται για πανεπιστημιακή διανομή
            </PreviewItem>
          </p>
        )}
      </Row>
      {/* TODO: Implement the below based on feedback */}
      {/* <Row>
        <PreviewHeader>Υπογραφή Συμβολαίων</PreviewHeader>
        <EditableContractsTable
          authors={bookDetails.authors}
          defaults={contractSigning.contractsSentTable}
          disabled={true}
        />
      </Row>
      <br />
      <Row>
        <PreviewHeader>Αποστολή Οδηγιών</PreviewHeader>
        <EditableInstructionsTable
          authors={bookDetails.authors}
          defaults={technicalInstructions.instructionsSentTable}
          disabled={true}
        />
      </Row>
      <br />
      <PreviewHeader>Χρόνοι Παράδοσης</PreviewHeader>
      <br />
      <PreviewHeader>Έλεγχος Χειρογράφων</PreviewHeader> */}
      <Row>
        <PreviewHeader>Έλεγχος Έκδοσης</PreviewHeader>
        <Col span={12}>
          <p>
            <PreviewLabel>Ημερομηνία Παράδοσης: </PreviewLabel>
            <PreviewItem>
              {publicationVerification.deliveredDate?.format(
                Configs.Date.Format
              )}
            </PreviewItem>
          </p>
          <p>
            <PreviewLabel>Επιθυμητή Ημερομηνία Έκδοσης: </PreviewLabel>
            <PreviewItem>
              {publicationVerification.expectedPublicationDate?.format(
                Configs.Date.Format
              )}
            </PreviewItem>
          </p>
        </Col>
        <Col span={12}>
          <p>
            <PreviewLabel>Τελικά Σχόλια: </PreviewLabel>
            <PreviewItem>
              {publicationVerification.verificationComments}
            </PreviewItem>
          </p>
        </Col>
      </Row>
    </>
  )
}

export default PublicationReview
