import { Typography } from 'antd'
import React, { Component } from 'react'

export class ErrorBoundary extends Component<
  { name?: string; children: any },
  { hasError: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
  render() {
    // You can render any custom fallback UI
    return this.state.hasError ? (
      <Typography>
        An error occured! Please contact with the development team.
      </Typography>
    ) : (
      this.props.children
    )
  }
}
