import React, { FC, useEffect, useState } from 'react'

import { Input, Select, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const { Option } = Select

const ExtendableSelect: FC<any> = (props) => {
  const {
    form,
    fieldName,
    nestedFieldName,
    defaultVal,
    data,
    setHasFormChanged,
    onAddFunc,
  } = props

  const [dataSource, setDataSource] = useState(data)
  const [itemName, setItemName] = useState('')

  useEffect(() => {
    setDataSource(data)
  }, [data])

  const onSelectChange = (value) => {
    if (nestedFieldName) {
      form.setFieldsValue({ [fieldName]: { [nestedFieldName]: value } })
    } else {
      form.setFieldsValue({ [fieldName]: value })
    }
    setHasFormChanged(true)
  }

  const onAdd = () => {
    if (nestedFieldName) {
      setDataSource([
        ...dataSource,
        { [nestedFieldName]: itemName, key: dataSource.length },
      ])
    } else {
      setDataSource([...dataSource, itemName])
    }
    if (onAddFunc) {
      onAddFunc(itemName)
    }
    setItemName('')
  }

  return (
    <Select
      allowClear
      style={{ minWidth: 120 }}
      placeholder="Επιλέξτε"
      defaultValue={defaultVal}
      onChange={onSelectChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              padding: 8,
            }}
          >
            <Input
              style={{ flex: 'auto' }}
              value={itemName}
              onChange={(event) => setItemName(event.target.value)}
            />
            <PlusOutlined
              style={{
                flex: 'none',
                padding: '8px',
                display: 'block',
                cursor: 'pointer',
              }}
              onClick={onAdd}
            >
              Προσθήκη
            </PlusOutlined>
          </div>
        </div>
      )}
    >
      {nestedFieldName
        ? dataSource.map((item) => (
            <Option key={item.key} value={item[nestedFieldName]}>
              {item[nestedFieldName]}
            </Option>
          ))
        : dataSource.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
    </Select>
  )
}

export default ExtendableSelect
