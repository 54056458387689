import React, { FC } from 'react'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import BackgroundImage from 'assets/images/pattern.png'

const LayoutWrapper = styled(Layout.Content)`
  background-image: url(${BackgroundImage}); // Background pattern from Toptal Subtle Patterns
`

const LandingScreen: FC<any> = (props) => (
  <LayoutWrapper>
    <Row justify="center" align="middle">
      <Col style={{ paddingTop: '48px', paddingBottom: '48px' }}>
        {props.children}
      </Col>
    </Row>
  </LayoutWrapper>
)

export default LandingScreen
