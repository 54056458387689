import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { Form, Modal, Input, InputNumber, Row, Col, Button, Select } from 'antd'
import {
  TableAddButton,
  TableDiv,
  ModalColFormItem,
} from 'features/bookPublicationForm/screens/styles'
import Configs from 'configs/configs'
import EditableTable from 'common/components/EditableTable'
import PersonAddArray, { ScreenType } from 'common/components/PersonAddArray'
import {
  UIDistribution,
  UIInstitutionDetails,
} from 'features/bookPublicationForm/types'
import { CSVLink } from 'react-csv'
import { DownloadOutlined } from '@ant-design/icons'
import { getDistributableCsvItem } from 'features/bookPublicationForm/utils'
import { sorterNumberCompare, sorterStringCompare } from 'common/utils'
import { useCounterKey } from 'common/hooks/useCounterKey'

const { Option } = Select

const ModalForm: FC<any> = (props, ref) => {
  const allInstitutionDetails: UIInstitutionDetails[] =
    props.allInstitutionDetails
  const {
    visible,
    onCancel,
    onCreate,
    disabled,
    clearPersonAddArray,
    involvedBookProducers,
  } = props

  const [form] = Form.useForm()
  const [currentDepts, setCurrentDepts] = useState<string[]>([])

  const handleUniversityChange = (value) => {
    form.setFieldsValue({ institutionDetails: { department: undefined } })
    if (value) {
      setCurrentDepts(
        allInstitutionDetails
          .filter((el) => el.university === value)
          .map((el) => el.department)
      )
    } else {
      setCurrentDepts([])
    }
  }

  useImperativeHandle(ref, () => ({
    form: form,
  }))

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onCreate}
      title="Προσθήκη Διανομής"
      okText="Προσθήκη"
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Πανεπιστήμιο"
          name={['institutionDetails', 'university']}
          rules={[{ required: true, message: Configs.Messages.RequiredField }]}
        >
          <Select
            allowClear
            id="distribution_university"
            onChange={handleUniversityChange}
          >
            {[
              ...new Set(allInstitutionDetails?.map((el) => el.university)),
            ].map((uni) => (
              <Option key={uni} value={uni}>
                {uni}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Τμήμα"
          name={['institutionDetails', 'department']}
          rules={[{ required: true, message: Configs.Messages.RequiredField }]}
        >
          <Select
            allowClear
            id="distribution_department"
            disabled={!currentDepts.length}
          >
            {currentDepts.map((dept) => (
              <Option key={dept} value={dept}>
                {dept}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col span={19}>
            <ModalColFormItem label="Μάθημα" name="course">
              <Input />
            </ModalColFormItem>
          </Col>
          <Col span={5}>
            <ModalColFormItem label="Αντίτυπα" name="numCopies">
              <InputNumber min={0} />
            </ModalColFormItem>
          </Col>
        </Row>
        <PersonAddArray
          required={false}
          parentForm={form}
          screenType={ScreenType.Tutors}
          disabled={disabled}
          clearPersonAddArray={clearPersonAddArray}
          involvedBookProducers={involvedBookProducers}
        />
      </Form>
    </Modal>
  )
}

const ModalAddForm = forwardRef(ModalForm)

const EditableDistributionsTable: FC<any> = (props) => {
  const defaults: UIDistribution[] = props.defaults
  const {
    disabled,
    dataRef,
    setHasFormChanged,
    allInstitutionDetails,
    involvedBookProducers,
    setTotalCopies,
  } = props

  const [dataSource, setDataSource] = useState(defaults || [])
  const [modalDisplay, setModalDisplay] = useState(false)
  const [clearPersonAddArray, setClearPersonAddArray] = useCounterKey() // used to call child function

  const csvHeaders = [
    { label: 'Πανεπιστήμιο', key: 'university' },
    { label: 'Τμήμα', key: 'department' },
    { label: 'Μάθημα', key: 'course' },
    { label: 'Αντίτυπα', key: 'numCopies' },
    { label: 'Διδάσκοντες', key: 'tutors' },
  ]
  const [csvData, setCsvData] = useState<any>([])

  const dataColumns = [
    {
      title: 'Πανεπιστήμιο',
      dataIndex: ['institutionDetails', 'university'],
      sorter: (a, b) =>
        sorterStringCompare(
          a.institutionDetails.university,
          b.institutionDetails.university
        ),
      editable: false,
      inputType: 'text',
    },
    {
      title: 'Τμήμα',
      dataIndex: ['institutionDetails', 'department'],
      key: 'department',
      sorter: (a, b) =>
        sorterStringCompare(
          a.institutionDetails.department,
          b.institutionDetails.department
        ),
      editable: false,
      inputType: 'text',
    },
    {
      title: 'Μάθημα',
      dataIndex: 'course',
      key: 'course',
      sorter: (a, b) => sorterStringCompare(a.course, b.course),
      editable: true,
      inputType: 'text',
    },
    {
      title: 'Αντίτυπα',
      dataIndex: 'numCopies',
      key: 'numCopies',
      sorter: (a, b) => sorterNumberCompare(a.numCopies, b.numCopies),
      editable: true,
      inputType: 'number',
    },
    {
      title: 'Διδάσκοντες',
      dataIndex: 'tutors',
      key: 'tutors',
      width: '40%',
      render: (record, row) => (
        <PersonAddArray
          required={false}
          dataRef={dataRef}
          screenType={ScreenType.TutorsEdit}
          disabled={disabled}
          defaults={record}
          rowKey={row.key}
          setHasFormChanged={() => setHasFormChanged(true)}
          involvedBookProducers={involvedBookProducers}
        />
      ),
      editable: false,
    },
  ]

  const getNewRowKey = useCallback(() => {
    return dataSource.length > 0
      ? Math.max(...dataSource.map((item) => +item.key!)) + 1
      : 0
  }, [dataSource])

  // Whenever we've got a dataSource state change
  // inform the dataRef of the parent component
  useEffect(() => {
    if (props.dataRef && dataSource) {
      // update but keep ids from newly POSTed entities
      const currDistributionsTable: UIDistribution[] =
        props.dataRef.current.distributionsTable
      props.dataRef.current.distributionsTable = dataSource.map((item) => ({
        ...item,
        tutors:
          item.tutors?.map((tutor) => ({
            ...tutor,
            id:
              tutor.id ??
              currDistributionsTable
                .find((el) => el.key === item.key)
                ?.tutors.find((el) => el.email === tutor.email)?.id,
          })) ?? [],
        id:
          item.id ??
          currDistributionsTable.find((el) => el.key === item.key)?.id,
      }))
      setCsvData(dataSource.map((el) => getDistributableCsvItem(el)))
    }
    if (dataSource !== defaults) {
      setHasFormChanged()
    }
    // Update total copies number
    setTotalCopies(
      dataSource.reduce((acc, el) => {
        if (el.numCopies) {
          acc += el.numCopies
        }
        return acc
      }, 0)
    )
  }, [dataSource]) // eslint-disable-line

  const modalOpenHandler = useCallback(() => {
    setModalDisplay(true)
  }, [])

  const modalCancelHandler = useCallback(() => {
    const { form } = modalAddFormRef.current
    form.resetFields()
    setModalDisplay(false)
    setClearPersonAddArray()
  }, [setClearPersonAddArray])

  const modalCreateHandler = useCallback(() => {
    const { form } = modalAddFormRef.current

    form.validateFields().then((values) => {
      setDataSource([...dataSource, { ...values, key: getNewRowKey() }])
      form.resetFields()
      setModalDisplay(false)
      setClearPersonAddArray()
    })
  }, [getNewRowKey, dataSource, setClearPersonAddArray])

  const modalAddFormRef = useRef<any>(null)

  return (
    <TableDiv>
      <EditableTable
        disabled={disabled}
        dataSource={dataSource}
        dataColumns={dataColumns}
        setParentData={(newData) => setDataSource(newData)}
      />
      <Row justify="space-between">
        <TableAddButton onClick={modalOpenHandler} disabled={disabled}>
          Προσθήκη Διανομής
        </TableAddButton>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          separator={';'}
          style={{ marginTop: 7 }}
        >
          <Button icon={<DownloadOutlined />}>Εξαγωγή</Button>
        </CSVLink>
      </Row>
      {modalDisplay && (
        <ModalAddForm
          visible
          disabled={disabled}
          onCancel={modalCancelHandler}
          onCreate={modalCreateHandler}
          ref={(form) => (modalAddFormRef.current = form)}
          allInstitutionDetails={allInstitutionDetails}
          clearPersonAddArray={clearPersonAddArray}
          involvedBookProducers={involvedBookProducers}
        />
      )}
    </TableDiv>
  )
}

export default EditableDistributionsTable
