import { call, put, all, takeLatest } from 'redux-saga/effects'

import Services from 'services'
import ActionNames from './actionNames'
import ActionCreators from './actionCreators'
import Endpoints from 'api/endpoints'
import { CommonActionCreators } from 'common/ducks'

import Dtos, { IBookPublication } from '@hypernetica/gutenberg-bpm-common'
import { ForceArray } from 'common/utils'

function* getBookPublicationsFlowWatcher() {
  yield takeLatest(
    ActionNames.GET_BOOK_PUBLICATIONS_REQUESTED,
    getBookPublications
  )
}

function* getBookPublications() {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const result = yield call(
      Services.Api.get as any,
      Endpoints.Entities.BookPublication,
      {}
    )

    const returnDto: IBookPublication[] = ForceArray(result).map((el) =>
      Dtos.BookPublicationDto.fromIDataObject(el)
    )
    yield put(ActionCreators.getBookPublicationsSucceeded(returnDto))
  } catch (error) {
    yield put(ActionCreators.getBookPublicationsFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

export default function* rootSaga() {
  yield all([getBookPublicationsFlowWatcher()])
}
