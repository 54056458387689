import _ from 'lodash'
import { UIUser } from 'features/authentication/types'
import {
  UIAuthor,
  UIBookCategory,
  UIDistribution,
  UIContract,
  UITechnicalInstruction,
  UIAuthorProgress,
  UIDeliverable,
  UIBookSeries,
  UIComment,
  UIBookPublication,
  BookDetailsStepData,
  PublicationDetailsStepData,
  ContractSigningStepData,
  AuthoringStepData,
  ManuscriptVerificationStepData,
  PublicationVerificationStepData,
  StepCompletionSection,
  TechnicalInstructionsStepData,
  UIStep,
  UIBook,
  StepData,
  UIInstitutionDetails,
} from 'features/bookPublicationForm/types'
import {
  IBookCategory,
  IBookProducer,
  IUser,
  IDistributionDetails,
  IContract,
  ITechnicalDetails,
  IAuthoringProgress,
  IDeliverable,
  IComment,
  IBookPublication,
  IBook,
  IStep,
  ISeries,
  IState,
  Nullable,
  IInstitutionDetails,
  IRuleSet,
  AuthRole,
  Role,
} from '@hypernetica/gutenberg-bpm-common'
import moment from 'moment'
import { DeepPartial } from 'redux'
import { RuleSetOptions } from 'features/bookPublicationForm/screens/ManuscriptVerification'
import { keepLastOccurrence } from 'common/utils'

export const mapBookProducerDtoToUi = (dto: IBookProducer): UIAuthor => {
  return {
    id: dto.id,
    firstname: dto.name!.valueOf(),
    lastname: dto.surname!.valueOf(),
    email: dto.email![0]!.valueOf(),
    phone1: dto.phone!.valueOf(),
    address: {
      street: dto.address?.street?.valueOf(),
      number: dto.address?.number?.valueOf(),
      city: dto.address?.city?.valueOf(),
      postcode: dto.address?.postcode?.valueOf(),
    },
    institutionDetails: (dto.institutionDetails &&
      mapInstitutionDetailsDtoToUi(
        dto.institutionDetails
      )) as UIInstitutionDetails,
    // TODO We really have to rethink the whole NullableArray thing.
    // Apart from thinking there is no point in having undefined elements in an array
    // it is possible of having ugly corner cases, like the one below
    roles: dto.roles?.map((el) => el ?? Role.Other),
  }
}

export const mapInstitutionDetailsDtoToUi = (
  dto: IInstitutionDetails
): UIInstitutionDetails => {
  return {
    id: dto.id,
    university: dto.university!.valueOf(),
    department: dto.department!.valueOf(),
  }
}

export const mapBookCategoryDtoToUi = (dto: IBookCategory): UIBookCategory => {
  return {
    id: dto.id,
    name: dto.name!.valueOf(),
    parentCategory: (dto.parentCategory &&
      mapBookCategoryDtoToUi(dto.parentCategory)) as UIBookCategory,
  }
}

export const mapUserDtoToUi = (dto: IUser): UIUser => {
  return {
    id: dto.id,
    username: dto.username!.valueOf(),
    firstname: dto.name!.valueOf(),
    lastname: dto.surname!.valueOf(),
    email: dto.email!.map((el) => el!.valueOf()),
    isAdmin: dto.authRoles?.includes(AuthRole.Administrator) ?? false,
  }
}

export const mapDistributionDetailsDtoToUi = (
  dto: IDistributionDetails
): Partial<UIDistribution> => {
  return {
    id: dto.id,
    institutionDetails: (dto.institutionDetails &&
      mapInstitutionDetailsDtoToUi(
        dto.institutionDetails
      )) as UIInstitutionDetails,
    course: dto.course?.valueOf(),
    numCopies: dto.numCopies?.valueOf(),
    tutors: dto.tutors
      ?.map((el) => el && mapBookProducerDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIAuthor[],
  }
}

export const mapContractDtoToUi = (dto: IContract): UIContract => {
  const author = dto.bookProducer && mapBookProducerDtoToUi(dto.bookProducer)
  const contractEffectStartDate = dto.startDate
    ? moment(dto.startDate)
    : undefined
  const contractEffectEndDate = dto.endDate ? moment(dto.endDate) : undefined
  return {
    id: dto.id,
    author: author!,
    draftSentDate: dto.draftSentDate ? moment(dto.draftSentDate) : undefined,
    draftApprovalDate: dto.draftApprovalDate
      ? moment(dto.draftApprovalDate)
      : undefined,
    finalSentDate: dto.finalSentDate ? moment(dto.finalSentDate) : undefined,
    signDate: dto.signDate ? moment(dto.signDate) : undefined,
    contractEffectDates: [
      contractEffectStartDate as moment.Moment,
      contractEffectEndDate as moment.Moment,
    ],
  }
}

export const mapTechnicalDetailsDtoToUi = (
  dto: ITechnicalDetails
): UITechnicalInstruction => {
  const author = dto.bookProducer && mapBookProducerDtoToUi(dto.bookProducer)
  const sentDate = dto.sentDate ? moment(dto.sentDate) : undefined
  return {
    id: dto.id,
    author: author!,
    sentDate: sentDate as moment.Moment,
  }
}

export const mapDeliverableDtoToUi = (
  dto: IDeliverable
): Partial<UIDeliverable> => {
  return {
    expectedDate: dto.expectedDeliveryDate
      ? moment(dto.expectedDeliveryDate)
      : undefined,
    deliveredDate: dto.deliveryDate ? moment(dto.deliveryDate) : undefined,
    name: dto.description!,
  }
}

export const mapAuthoringProgressDtoToUi = (
  dto: IAuthoringProgress
): UIAuthorProgress => {
  const author = dto.bookProducer && mapBookProducerDtoToUi(dto.bookProducer)
  return {
    id: dto.id,
    author: author!,
    deliverables: dto.deliverables
      ?.map((el) => el && mapDeliverableDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIDeliverable[],
  }
}

export const mapSeriesDtoToUi = (dto: ISeries): UIBookSeries => {
  return {
    id: dto.id,
    name: dto.name!.valueOf(),
  }
}

export const mapCommentDtoToUi = (dto: IComment): UIComment => {
  const user = dto.commenter && mapUserDtoToUi(dto.commenter)
  return {
    id: dto.id,
    user: user!,
    content: dto.message!.valueOf(),
    date: moment(dto.updatedAt as Date),
    createdAt: moment(dto.createdAt as Date),
  }
}

export const mapStepDtoToUiForComments = (dto: IStep): UIStep => {
  return {
    id: dto.id,
    comments: dto.notes
      ?.map((el) => el && mapCommentDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIComment[],
  }
}

export const mapBookDetailsToUi = (
  step: IStep,
  book: Nullable<IBook>
): DeepPartial<BookDetailsStepData> => {
  const bookCategory =
    book?.category?.[0] && mapBookCategoryDtoToUi(book.category[0])
  const bookCategoryParent = bookCategory?.parentCategory
    ? bookCategory?.parentCategory?.name
    : bookCategory?.name
  const bookCategoryChild = bookCategory?.parentCategory
    ? bookCategory.name
    : undefined
  const completion = step && mapStepDtoToUiSection(step)

  return {
    completion: completion,
    bookEntityId: book?.id,
    title: book?.title?.valueOf(),
    authors: book?.authors
      ?.map((el) => el && mapBookProducerDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIAuthor[],
    numPages: book?.numPages?.valueOf(),
    oneFile: book?.oneFile?.valueOf(),
    colour: book?.colour?.valueOf(),
    writtenFormat: book?.writtenFormat?.valueOf(),
    bookCategoryParent,
    bookCategoryChild,
    bookCategory: bookCategory!,
    bookDimensions: book?.dimensions?.valueOf(),
    bookDimensionsComments: book?.dimensionsComments?.valueOf(),
    bookSeries: book?.series ? mapSeriesDtoToUi(book?.series) : undefined,
    equations: book?.equations?.valueOf(),
    equationsComments: book?.equationsComments?.valueOf(),
    tables: book?.tables?.valueOf(),
    diagrams: book?.diagrams?.valueOf(),
    photos: book?.photos?.valueOf(),
    picturesCopyRight: book?.picturesCopyRight?.valueOf(),
    picturesCopyRightComments: book?.picturesCopyRightComments?.valueOf(),
    textCopyRight: book?.textCopyRight?.valueOf(),
    technical: book?.technical!,
  }
}

export const mapBookDtoToUi = (book: IBook): UIBook => {
  const bookCategory =
    book?.category?.[0] && mapBookCategoryDtoToUi(book.category[0])

  return {
    id: book.id,
    title: book.title?.valueOf(),
    authors: book.authors
      ?.map((el) => el && mapBookProducerDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIAuthor[],
    numPages: book.numPages?.valueOf(),
    oneFile: book.oneFile?.valueOf(),
    colour: book.colour?.valueOf(),
    writtenFormat: book.writtenFormat?.valueOf(),
    bookCategory: bookCategory as UIBookCategory,
    bookDimensions: book.dimensions?.valueOf(),
    bookDimensionsComments: book.dimensionsComments?.valueOf(),
    bookSeries: book.series ? mapSeriesDtoToUi(book.series) : undefined,
    equations: book.equations?.valueOf(),
    equationsComments: book.equationsComments?.valueOf(),
    tables: book.tables?.valueOf(),
    diagrams: book.diagrams?.valueOf(),
    photos: book.photos?.valueOf(),
    picturesCopyRight: book.picturesCopyRight?.valueOf(),
    picturesCopyRightComments: book.picturesCopyRightComments?.valueOf(),
    textCopyRight: book.textCopyRight?.valueOf(),
  }
}

export const getBookContentsFromBookDetailsStep = (
  bookDetails: BookDetailsStepData
): UIBook => {
  const { completion, bookEntityId, ...bookContents } = bookDetails
  return bookContents
}

export const mapStepDtoToUiSection = (
  dto: IStep
): Partial<StepCompletionSection> => {
  return {
    stepEntityId: dto.id,
    assignees: dto.assignedTo
      ?.map((el) => el && mapUserDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIUser[],
    desiredDate: dto.desiredDate ? moment(dto.desiredDate) : undefined,
    completionDate: dto.phaseEndDate ? moment(dto.phaseEndDate) : undefined,
    stepCompleted: dto.isCompleted?.valueOf() ?? false,
  }
}

export const mapStepSectionUiToDto = (
  stepCompletion: StepCompletionSection
): Partial<IStep> => {
  return {
    id: stepCompletion.stepEntityId,
    assignedTo: stepCompletion.assignees.map(
      (el) => mapUserUiToDto(el) as IUser
    ),
    desiredDate: stepCompletion.desiredDate?.toDate(),
    phaseEndDate: stepCompletion.completionDate?.toDate(),
    isCompleted: stepCompletion.stepCompleted,
  }
}

export const mapPublicationDetailsToUi = (
  step: IStep,
  dto: IBookPublication
): DeepPartial<PublicationDetailsStepData> => {
  const completion = step && mapStepDtoToUiSection(step)

  return {
    completion: completion!,
    publicationDate: dto.publicationDate
      ? moment(dto.publicationDate)
      : undefined,
    publicationComments: dto.publicationComments?.valueOf(),
    resolution: dto.resolution?.valueOf() ?? false,
    resolutionDate: dto.resolutionDate ? moment(dto.resolutionDate) : undefined,
    resolutionComments: dto.resolutionComments!,
    desiredReleaseDate: dto.desiredReleaseDate
      ? moment(dto.desiredReleaseDate)
      : undefined,
    isDistributable: dto.isDistributable?.valueOf() ?? false,
    distributionsTable: dto.distributionDetails
      ?.map((el) => el && mapDistributionDetailsDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIDistribution[],
  }
}

export const mapContractSigningToUi = (
  step: IStep,
  dto: IBookPublication
): DeepPartial<ContractSigningStepData> => {
  const completion = step && mapStepDtoToUiSection(step)
  return {
    completion: completion!,
    contractsSentTable: dto.contracts
      ?.map((el) => el && mapContractDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIContract[],
  }
}

export const mapTechnicalInstructionsToUi = (
  step: IStep,
  dto: IBookPublication
): DeepPartial<TechnicalInstructionsStepData> => {
  const completion = step && mapStepDtoToUiSection(step)
  return {
    completion: completion!,
    instructionsSentTable: dto.technicalDetails
      ?.map((el) => el && mapTechnicalDetailsDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UITechnicalInstruction[],
  }
}

export const mapAuthoringToUi = (
  step: IStep,
  dto: IBookPublication
): DeepPartial<AuthoringStepData> => {
  const completion = step && mapStepDtoToUiSection(step)
  return {
    completion: completion!,
    authorsProgressTable: dto.authoringProgress
      ?.map((el) => el && mapAuthoringProgressDtoToUi(el))
      .filter((el) => !_.isUndefined(el))
      .map((el, i) => ({ ...el, key: i })) as UIAuthorProgress[],
  }
}

export const mapManuscriptVerificationToUi = (
  step: IStep,
  dto: IBookPublication
): DeepPartial<ManuscriptVerificationStepData> => {
  const completion = step && mapStepDtoToUiSection(step)

  let checkboxes: RuleSetOptions[] = []
  if (dto.verificationRuleSet) {
    for (const key of Object.keys(RuleSetOptions)) {
      if (dto.verificationRuleSet?.[key]) {
        checkboxes.push(RuleSetOptions[key])
      }
    }
  }

  return {
    completion: completion!,
    checkboxes,
  }
}

export const mapPublicationVerificationToUi = (
  step: IStep,
  dto: IBookPublication
): DeepPartial<PublicationVerificationStepData> => {
  const completion = step && mapStepDtoToUiSection(step)
  return {
    completion: completion!,
    checkerDeliveryDate: dto.checkerDates?.delivery
      ? moment(dto.checkerDates.delivery)
      : undefined,
    checkerExpectedCompletionDate: dto.checkerDates?.expectedCompletion
      ? moment(dto.checkerDates.expectedCompletion)
      : undefined,
    checkerActualCompletionDate: dto.checkerDates?.actualCompletion
      ? moment(dto.checkerDates.actualCompletion)
      : undefined,
    checkerNotes: dto.checkerDates?.notes?.valueOf(),
    paginationDeliveryDate: dto.paginationDates?.delivery
      ? moment(dto.paginationDates.delivery)
      : undefined,
    paginationExpectedCompletionDate: dto.paginationDates?.expectedCompletion
      ? moment(dto.paginationDates.expectedCompletion)
      : undefined,
    paginationActualCompletionDate: dto.paginationDates?.actualCompletion
      ? moment(dto.paginationDates.actualCompletion)
      : undefined,
    paginationNotes: dto.paginationDates?.notes?.valueOf(),
    deliveredDate: dto.manuscriptDeliveryEstimatedDate
      ? moment(dto.manuscriptDeliveryEstimatedDate)
      : undefined,
    expectedPublicationDate: dto.desiredReleaseDate
      ? moment(dto.desiredReleaseDate)
      : undefined,
    verificationComments: dto.verificationComments?.valueOf(),
  }
}

export const mapBookPublicationDtoToUi = (
  dto: IBookPublication,
  init = false
): DeepPartial<UIBookPublication> => {
  const bookDetails =
    dto.currentState?.steps?.[0] &&
    mapBookDetailsToUi(dto.currentState?.steps?.[0], dto.book)
  const publicationDetails =
    dto.currentState?.steps?.[1] &&
    mapPublicationDetailsToUi(dto.currentState?.steps?.[1], dto)
  const contractSigning =
    dto.currentState?.steps?.[2] &&
    mapContractSigningToUi(dto.currentState?.steps?.[2], dto)
  const technicalInstructions =
    dto.currentState?.steps?.[3] &&
    mapTechnicalInstructionsToUi(dto.currentState?.steps?.[3], dto)
  const authoring =
    dto.currentState?.steps?.[4] &&
    mapAuthoringToUi(dto.currentState?.steps?.[4], dto)
  const manuscriptVerification =
    dto.currentState?.steps?.[5] &&
    mapManuscriptVerificationToUi(dto.currentState?.steps?.[5], dto)
  const publicationVerification =
    dto.currentState?.steps?.[6] &&
    mapPublicationVerificationToUi(dto.currentState?.steps?.[6], dto)

  return {
    id: dto.id,
    seqId: dto.seqId!,
    bookDetails: bookDetails!,
    publicationDetails: init
      ? { ...publicationDetails, resolution: true }
      : publicationDetails!,
    contractSigning: contractSigning!,
    technicalInstructions: technicalInstructions!,
    authoring: authoring!,
    manuscriptVerification: manuscriptVerification!,
    publicationVerification: publicationVerification!,
    updatedDate: moment(dto.updatedAt as Date),
  }
}

export const mapBookProducerUiToDto = (uiObj: UIAuthor): IBookProducer => {
  return {
    id: uiObj.id,
    name: uiObj.firstname.valueOf(),
    surname: uiObj.lastname.valueOf(),
    email: [uiObj.email.valueOf()],
    phone: uiObj.phone1.valueOf(),
    address: {
      street: uiObj.address?.street?.valueOf(),
      number: uiObj.address?.number?.valueOf(),
      city: uiObj.address?.city?.valueOf(),
      postcode: uiObj.address?.postcode?.valueOf(),
    },
    institutionDetails: uiObj.institutionDetails?.valueOf() as IInstitutionDetails,
    // Bad type matching. Would probably need to export Role enum
    roles: uiObj.roles ?? ([] as Role[]),
  }
}

export const mapBookCategoryUiToDto = (
  uiObj: UIBookCategory
): Partial<IBookCategory> => {
  return {
    id: uiObj.id,
    name: uiObj.name,
    parentCategory:
      uiObj.parentCategory &&
      (mapBookCategoryUiToDto(uiObj.parentCategory) as IBookCategory),
  }
}

export const mapUserUiToDto = (
  uiObj: UIUser,
  password?: string
): Partial<IUser> => {
  return {
    id: uiObj.id,
    name: uiObj.firstname,
    surname: uiObj.lastname,
    username: uiObj.username,
    email: uiObj.email,
    password,
  }
}

export const mapDistributionDetailsUiToDto = (
  uiObj: UIDistribution
): Partial<IDistributionDetails> => {
  return {
    id: uiObj.id,
    institutionDetails: uiObj.institutionDetails?.valueOf() as IInstitutionDetails,
    course: uiObj.course,
    numCopies: uiObj.numCopies,
    tutors: uiObj.tutors
      ?.map((el) => el && mapBookProducerUiToDto(el))
      .filter((el) => !_.isUndefined(el)) as IBookProducer[],
  }
}

export const mapContractUiToDto = (uiObj: UIContract): IContract => {
  const bookProducer = uiObj.author && mapBookProducerUiToDto(uiObj.author)
  return {
    id: uiObj.id,
    bookProducer,
    draftSentDate: uiObj.draftSentDate?.toDate(),
    draftApprovalDate: uiObj.draftApprovalDate?.toDate(),
    finalSentDate: uiObj.finalSentDate?.toDate(),
    signDate: uiObj.signDate?.toDate(),
    startDate: uiObj.contractEffectDates?.[0]?.toDate(),
    endDate: uiObj.contractEffectDates?.[1]?.toDate(),
  }
}

export const mapTechnicalDetailsUiToDto = (
  uiObj: UITechnicalInstruction
): ITechnicalDetails => {
  const bookProducer = uiObj.author && mapBookProducerUiToDto(uiObj.author)
  return {
    id: uiObj.id,
    bookProducer,
    sentDate: uiObj.sentDate?.toDate(),
  }
}

export const mapDeliverableUiToDto = (
  uiObj: UIDeliverable
): Partial<IDeliverable> => {
  return {
    expectedDeliveryDate: uiObj.expectedDate?.toDate(),
    deliveryDate: uiObj.deliveredDate
      ? uiObj.deliveredDate.toDate()
      : undefined,
    description: uiObj.name,
  }
}

export const mapAuthoringProgressUiToDto = (
  uiObj: UIAuthorProgress
): Partial<IAuthoringProgress> => {
  const bookProducer = uiObj.author && mapBookProducerUiToDto(uiObj.author)
  return {
    id: uiObj.id,
    bookProducer,
    deliverables: uiObj.deliverables
      ?.map((el) => el && mapDeliverableUiToDto(el))
      .filter((el) => !_.isUndefined(el)) as IDeliverable[],
  }
}

export const mapSeriesUiToDto = (uiObj: UIBookSeries): ISeries => {
  return {
    id: uiObj.id,
    name: uiObj.name,
  }
}

export const mapCommentUiToDto = (uiObj: UIComment): Partial<IComment> => {
  //Type cast is used to solve this problem. Technically mapUserUiToDto returns
  // Partial<User>, due to missing data in UI
  const commenter = uiObj.user && (mapUserUiToDto(uiObj.user) as IUser)
  return {
    id: uiObj.id,
    commenter,
    message: uiObj.content,
  }
}

export const mapBookDetailsUiToDto = (uiObj: UIBook): IBook => {
  return {
    id: uiObj.id,
    title: uiObj.title?.valueOf(),
    authors: uiObj.authors
      ?.map((el) => el && mapBookProducerUiToDto(el))
      .filter((el) => !_.isUndefined(el)),
    editors: [],
    numPages: uiObj.numPages?.valueOf(),
    oneFile: uiObj.oneFile?.valueOf(),
    colour: uiObj.colour?.valueOf(),
    writtenFormat: uiObj.writtenFormat?.valueOf(),
    category: [uiObj.bookCategory]
      ?.map((el) => el && mapBookCategoryUiToDto(el))
      .filter((el) => !_.isUndefined(el)) as IBookCategory[],
    series: uiObj.bookSeries && mapSeriesUiToDto(uiObj.bookSeries),
    dimensions: uiObj.bookDimensions?.valueOf(),
    dimensionsComments: uiObj.bookDimensionsComments?.valueOf(),
    equations: uiObj.equations?.valueOf(),
    equationsComments: uiObj.equationsComments?.valueOf(),
    tables: uiObj.tables?.valueOf(),
    diagrams: uiObj.diagrams?.valueOf(),
    photos: uiObj.photos?.valueOf(),
    picturesCopyRight: uiObj.picturesCopyRight?.valueOf(),
    picturesCopyRightComments: uiObj.picturesCopyRightComments?.valueOf(),
    textCopyRight: uiObj.textCopyRight?.valueOf(),
    technical: uiObj.technical,
  }
}

export const mapStepUiToDto = (
  uiObj: StepData,
  code: number
): Partial<IStep> => {
  return {
    assignedTo: uiObj.completion?.assignees.map(
      (el) => mapUserUiToDto(el) as IUser
    ),
    code,
    desiredDate: uiObj.completion?.desiredDate?.toDate(),
    phaseEndDate: uiObj.completion?.completionDate?.toDate(),
    isCompleted: uiObj.completion?.stepCompleted,
  }
}

export const mapBookToDto = (uiObj: BookDetailsStepData): Partial<IBook> => {
  return {
    id: uiObj.bookEntityId,
    title: uiObj.title,
    category: [
      uiObj.bookCategory &&
        (mapBookCategoryUiToDto(uiObj.bookCategory) as IBookCategory),
    ],
    authors: uiObj.authors
      ?.map((el) => el && mapBookProducerUiToDto(el))
      .filter((el) => !_.isUndefined(el)) as IBookProducer[],
    series: uiObj.bookSeries && (mapSeriesUiToDto(uiObj.bookSeries) as ISeries),
    oneFile: uiObj.oneFile,
    numPages: uiObj.numPages,
    diagrams: uiObj.diagrams,
    dimensions: uiObj.bookDimensions,
    dimensionsComments: uiObj.bookDimensionsComments,
    colour: uiObj.colour,
    tables: uiObj.tables,
    equations: uiObj.equations,
    equationsComments: uiObj.equationsComments,
    photos: uiObj.photos,
    picturesCopyRight: uiObj.picturesCopyRight,
    picturesCopyRightComments: uiObj.picturesCopyRightComments,
    textCopyRight: uiObj.textCopyRight,
    writtenFormat: uiObj.writtenFormat,
  }
}

export const mapStateToDto = (uiObj: UIBookPublication): Partial<IState> => {
  const steps = [
    mapStepUiToDto(uiObj.bookDetails, 0),
    mapStepUiToDto(uiObj.publicationDetails, 1),
    mapStepUiToDto(uiObj.contractSigning, 2),
    mapStepUiToDto(uiObj.technicalInstructions, 3),
    mapStepUiToDto(uiObj.authoring, 4),
    mapStepUiToDto(uiObj.manuscriptVerification, 5),
    mapStepUiToDto(uiObj.publicationVerification, 6),
  ] as IStep[]
  return {
    code: 0,
    steps,
    assignedTo: steps.map((el) => el.assignedTo?.[0]),
  }
}

export const mapBookPublicationUiToDto = (
  uiObj: UIBookPublication
): Partial<IBookPublication> => {
  const watchers = keepLastOccurrence(
    [
      uiObj.bookDetails,
      uiObj.publicationDetails,
      uiObj.contractSigning,
      uiObj.technicalInstructions,
      uiObj.authoring,
      uiObj.manuscriptVerification,
      uiObj.publicationVerification,
    ]
      .map((el) => el.completion.assignees)
      .flat()
      .filter((el) => !_.isUndefined(el))
  ).map((el) => mapUserUiToDto(el) as IUser)

  let verificationRuleSet: Partial<IRuleSet> = {}
  for (const key of Object.keys(RuleSetOptions)) {
    verificationRuleSet[key] = uiObj.manuscriptVerification.checkboxes.includes(
      RuleSetOptions[key]
    )
  }

  return {
    id: uiObj.id,
    seqId: uiObj.seqId,
    book: uiObj.bookDetails && (mapBookToDto(uiObj.bookDetails) as IBook),
    contracts: uiObj.contractSigning.contractsSentTable?.map(
      (el) => mapContractUiToDto(el) as IContract
    ),
    technicalDetails: uiObj.technicalInstructions.instructionsSentTable?.map(
      (el) => mapTechnicalDetailsUiToDto(el) as ITechnicalDetails
    ),
    distributionDetails: uiObj.publicationDetails.distributionsTable?.map(
      (el) => mapDistributionDetailsUiToDto(el) as IDistributionDetails
    ),
    authoringProgress: uiObj.authoring.authorsProgressTable.map(
      (el) => mapAuthoringProgressUiToDto(el) as IAuthoringProgress
    ),
    desiredReleaseDate: uiObj.publicationDetails.desiredReleaseDate?.toDate(),
    manuscriptDeliveryEstimatedDate: uiObj.publicationVerification.deliveredDate?.toDate(),
    verificationRuleSet: verificationRuleSet as IRuleSet,
    checkerDates: {
      delivery: uiObj.publicationVerification?.checkerDeliveryDate?.toDate(),
      expectedCompletion: uiObj.publicationVerification?.checkerExpectedCompletionDate?.toDate(),
      actualCompletion: uiObj.publicationVerification?.checkerActualCompletionDate?.toDate(),
      notes: uiObj.publicationVerification?.checkerNotes,
    },
    paginationDates: {
      delivery: uiObj.publicationVerification?.paginationDeliveryDate?.toDate(),
      expectedCompletion: uiObj.publicationVerification?.paginationExpectedCompletionDate?.toDate(),
      actualCompletion: uiObj.publicationVerification?.paginationActualCompletionDate?.toDate(),
      notes: uiObj.publicationVerification?.paginationNotes,
    },
    verificationComments: uiObj.publicationVerification.verificationComments,
    resolution: uiObj.publicationDetails.resolution,
    resolutionDate: uiObj.publicationDetails.resolutionDate?.toDate(),
    resolutionComments: uiObj.publicationDetails.resolutionComments,
    watchers,
    publicationDate: uiObj.publicationDetails.publicationDate?.toDate(),
    publicationComments: uiObj.publicationDetails.publicationComments,
    isDistributable: uiObj.publicationDetails.isDistributable,
    previousStates: [],
  }
}
