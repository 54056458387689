import styled from 'styled-components'
import { Layout, Menu } from 'antd'

export const LayoutWrapper = styled(Layout)`
  min-height: 100vh;
  position: relative;
  background: #f0f0f0;
`

export const CenteredContent = styled(Layout.Content)`
  max-width: 1100px !important;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`

export const ContentWrapper = styled.div`
  background: #fff;
  margin: 24px 16px 24px 16px;

  .ant-table {
    line-height: 0.8;
  }
  .ant-table-thead {
    line-height: 1.3;
  }
`

export const SideBar = styled(Layout.Sider)`
  min-height: calc(100vh - 110px);
  margin-top: 48px;

  &.ant-layout-sider {
    flex: 0 0 201px !important;
    max-width: 201px !important;
    min-width: 201px !important;
    width: 201px !important;
  }

  &.ant-layout-sider.ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }
`

// TODO: do we want side menu items to be fixed?
export const FixedMenu = styled(Menu)`
  margin-top: 3px !important;
  position: fixed;
  width: inherit !important;

  .ant-menu-item-active {
    background-color: grey !important;
  }
`
