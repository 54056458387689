import ActionNames from './actionNames'
import makeActionCreator from 'redux/utils'

/* ----------- AUTHORS -------------  */
const getAuthorsRequested = makeActionCreator(ActionNames.GET_AUTHORS_REQUESTED)
const getAuthorsSucceeded = makeActionCreator(
  ActionNames.GET_AUTHORS_SUCCEEDED,
  'authors'
)
const getAuthorsFailed = makeActionCreator(
  ActionNames.GET_AUTHORS_FAILED,
  'error'
)

const postAuthorRequested = makeActionCreator(
  ActionNames.POST_AUTHOR_REQUESTED,
  'author'
)
const postAuthorSucceeded = makeActionCreator(
  ActionNames.POST_AUTHOR_SUCCEEDED,
  'author'
)
const postAuthorFailed = makeActionCreator(
  ActionNames.POST_AUTHOR_FAILED,
  'error'
)

const patchAuthorRequested = makeActionCreator(
  ActionNames.PATCH_AUTHOR_REQUESTED,
  'author'
)
const patchAuthorSucceeded = makeActionCreator(
  ActionNames.PATCH_AUTHOR_SUCCEEDED,
  'author'
)
const patchAuthorFailed = makeActionCreator(
  ActionNames.PATCH_AUTHOR_FAILED,
  'error'
)

/* ----------- INSTITUTION DETAILS -------------  */
const getInstitutionDetailsRequested = makeActionCreator(
  ActionNames.GET_INSTITUTION_DETAILS_REQUESTED
)
const getInstitutionDetailsSucceeded = makeActionCreator(
  ActionNames.GET_INSTITUTION_DETAILS_SUCCEEDED,
  'institutionDetails'
)
const getInstitutionDetailsFailed = makeActionCreator(
  ActionNames.GET_INSTITUTION_DETAILS_FAILED,
  'error'
)

/* ----------- BOOK CATEGORIES -------------  */
const getBookCategoriesRequested = makeActionCreator(
  ActionNames.GET_BOOK_CATEGORIES_REQUESTED
)
const getBookCategoriesSucceeded = makeActionCreator(
  ActionNames.GET_BOOK_CATEGORIES_SUCCEEDED,
  'bookCategories'
)
const getBookCategoriesFailed = makeActionCreator(
  ActionNames.GET_BOOK_CATEGORIES_FAILED,
  'error'
)

const postBookCategoryRequested = makeActionCreator(
  ActionNames.POST_BOOK_CATEGORY_REQUESTED,
  'bookCategory'
)
const postBookCategorySucceeded = makeActionCreator(
  ActionNames.POST_BOOK_CATEGORY_SUCCEEDED,
  'bookCategory'
)
const postBookCategoryFailed = makeActionCreator(
  ActionNames.POST_BOOK_CATEGORY_FAILED,
  'error'
)

/* ----------- BOOKS -------------  */
const postBookRequested = makeActionCreator(
  ActionNames.POST_BOOK_REQUESTED,
  'book'
)
const postBookSucceeded = makeActionCreator(
  ActionNames.POST_BOOK_SUCCEEDED,
  'book'
)
const postBookFailed = makeActionCreator(ActionNames.POST_BOOK_FAILED, 'error')

const patchBookRequested = makeActionCreator(
  ActionNames.PATCH_BOOK_REQUESTED,
  'book'
)
const patchBookSucceeded = makeActionCreator(
  ActionNames.PATCH_BOOK_SUCCEEDED,
  'book'
)
const patchBookFailed = makeActionCreator(
  ActionNames.PATCH_BOOK_FAILED,
  'error'
)

/* ----------- BOOK DISTRIBUTIONS -------------  */
const postBookDistributionRequested = makeActionCreator(
  ActionNames.POST_BOOK_DISTRIBUTION_REQUESTED,
  'bookDistribution'
)
const postBookDistributionSucceeded = makeActionCreator(
  ActionNames.POST_BOOK_DISTRIBUTION_SUCCEEDED,
  'bookDistribution'
)
const postBookDistributionFailed = makeActionCreator(
  ActionNames.POST_BOOK_DISTRIBUTION_FAILED,
  'error'
)

const patchBookDistributionRequested = makeActionCreator(
  ActionNames.PATCH_BOOK_DISTRIBUTION_REQUESTED,
  'bookDistribution'
)
const patchBookDistributionSucceeded = makeActionCreator(
  ActionNames.PATCH_BOOK_DISTRIBUTION_SUCCEEDED,
  'bookDistribution'
)
const patchBookDistributionFailed = makeActionCreator(
  ActionNames.PATCH_BOOK_DISTRIBUTION_FAILED,
  'error'
)

/* ----------- CONTRACTS -------------  */
const postContractRequested = makeActionCreator(
  ActionNames.POST_CONTRACT_REQUESTED,
  'contract'
)
const postContractSucceeded = makeActionCreator(
  ActionNames.POST_CONTRACT_SUCCEEDED,
  'contract'
)
const postContractFailed = makeActionCreator(
  ActionNames.POST_CONTRACT_FAILED,
  'error'
)

const patchContractRequested = makeActionCreator(
  ActionNames.PATCH_CONTRACT_REQUESTED,
  'contract'
)
const patchContractSucceeded = makeActionCreator(
  ActionNames.PATCH_CONTRACT_SUCCEEDED,
  'contract'
)
const patchContractFailed = makeActionCreator(
  ActionNames.PATCH_CONTRACT_FAILED,
  'error'
)

/* ----------- TECHNICAL INSTRUCTIONS -------------  */
const postTechnicalInstructionRequested = makeActionCreator(
  ActionNames.POST_TECHNICAL_INSTRUCTION_REQUESTED,
  'technicalInstruction'
)
const postTechnicalInstructionSucceeded = makeActionCreator(
  ActionNames.POST_TECHNICAL_INSTRUCTION_SUCCEEDED,
  'technicalInstruction'
)
const postTechnicalInstructionFailed = makeActionCreator(
  ActionNames.POST_TECHNICAL_INSTRUCTION_FAILED,
  'error'
)

const patchTechnicalInstructionRequested = makeActionCreator(
  ActionNames.PATCH_TECHNICAL_INSTRUCTION_REQUESTED,
  'technicalInstruction'
)
const patchTechnicalInstructionSucceeded = makeActionCreator(
  ActionNames.PATCH_TECHNICAL_INSTRUCTION_SUCCEEDED,
  'technicalInstruction'
)
const patchTechnicalInstructionFailed = makeActionCreator(
  ActionNames.PATCH_TECHNICAL_INSTRUCTION_FAILED,
  'error'
)

/* ----------- AUTHORING PROGRESS -------------  */
const postAuthoringProgressRequested = makeActionCreator(
  ActionNames.POST_AUTHORING_PROGRESS_REQUESTED,
  'authoringProgress'
)
const postAuthoringProgressSucceeded = makeActionCreator(
  ActionNames.POST_AUTHORING_PROGRESS_SUCCEEDED,
  'authoringProgress'
)
const postAuthoringProgressFailed = makeActionCreator(
  ActionNames.POST_AUTHORING_PROGRESS_FAILED,
  'error'
)

const patchAuthoringProgressRequested = makeActionCreator(
  ActionNames.PATCH_AUTHORING_PROGRESS_REQUESTED,
  'authoringProgress'
)
const patchAuthoringProgressSucceeded = makeActionCreator(
  ActionNames.PATCH_AUTHORING_PROGRESS_SUCCEEDED,
  'authoringProgress'
)
const patchAuthoringProgressFailed = makeActionCreator(
  ActionNames.PATCH_AUTHORING_PROGRESS_FAILED,
  'error'
)

/* ----------- BOOK SERIES -------------  */
const getBookSeriesRequested = makeActionCreator(
  ActionNames.GET_BOOK_SERIES_REQUESTED
)
const getBookSeriesSucceeded = makeActionCreator(
  ActionNames.GET_BOOK_SERIES_SUCCEEDED,
  'bookSeries'
)
const getBookSeriesFailed = makeActionCreator(
  ActionNames.GET_BOOK_SERIES_FAILED,
  'error'
)

const postBookSeriesRequested = makeActionCreator(
  ActionNames.POST_BOOK_SERIES_REQUESTED,
  'bookSeries'
)
const postBookSeriesSucceeded = makeActionCreator(
  ActionNames.POST_BOOK_SERIES_SUCCEEDED,
  'bookSeries'
)
const postBookSeriesFailed = makeActionCreator(
  ActionNames.POST_BOOK_SERIES_FAILED,
  'error'
)

/* ----------- USERS -------------  */
const getUsersRequested = makeActionCreator(ActionNames.GET_USERS_REQUESTED)
const getUsersSucceeded = makeActionCreator(
  ActionNames.GET_USERS_SUCCEEDED,
  'users'
)
const getUsersFailed = makeActionCreator(ActionNames.GET_USERS_FAILED, 'error')

/* ----------- COMMENTS -------------  */
const postCommentRequested = makeActionCreator(
  ActionNames.POST_COMMENT_REQUESTED,
  'stepId',
  'comment'
)
const postCommentSucceeded = makeActionCreator(
  ActionNames.POST_COMMENT_SUCCEEDED,
  'comment'
)
const postCommentFailed = makeActionCreator(
  ActionNames.POST_COMMENT_FAILED,
  'error'
)

const patchCommentRequested = makeActionCreator(
  ActionNames.PATCH_COMMENT_REQUESTED,
  'comment'
)
const patchCommentSucceeded = makeActionCreator(
  ActionNames.PATCH_COMMENT_SUCCEEDED,
  'comment'
)
const patchCommentFailed = makeActionCreator(
  ActionNames.PATCH_COMMENT_FAILED,
  'error'
)

const deleteCommentRequested = makeActionCreator(
  ActionNames.DELETE_COMMENT_REQUESTED,
  'commentId',
  'stepId'
)
const deleteCommentSucceeded = makeActionCreator(
  ActionNames.DELETE_COMMENT_SUCCEEDED
)
const deleteCommentFailed = makeActionCreator(
  ActionNames.DELETE_COMMENT_FAILED,
  'error'
)

/* ----------- STEPS -------------  */
const getStepRequested = makeActionCreator(ActionNames.GET_STEP_REQUESTED, 'id')
const getStepSucceeded = makeActionCreator(
  ActionNames.GET_STEP_SUCCEEDED,
  'step'
)
const getStepFailed = makeActionCreator(ActionNames.GET_STEP_FAILED, 'error')

const patchStepRequested = makeActionCreator(
  ActionNames.PATCH_STEP_REQUESTED,
  'step'
)
const patchStepSucceeded = makeActionCreator(
  ActionNames.PATCH_STEP_SUCCEEDED,
  'step'
)
const patchStepFailed = makeActionCreator(
  ActionNames.PATCH_STEP_FAILED,
  'error'
)

/* ----------- BOOK PUBLICATION -------------  */
const initBookPublicationRequested = makeActionCreator(
  ActionNames.INIT_BOOK_PUBLICATION_REQUESTED
)
const initBookPublicationSucceeded = makeActionCreator(
  ActionNames.INIT_BOOK_PUBLICATION_SUCCEEDED,
  'bookPublication'
)
const initBookPublicationFailed = makeActionCreator(
  ActionNames.INIT_BOOK_PUBLICATION_FAILED,
  'error'
)

const patchBookPublicationRequested = makeActionCreator(
  ActionNames.PATCH_BOOK_PUBLICATION_REQUESTED,
  'bookPublication'
)
const patchBookPublicationSucceeded = makeActionCreator(
  ActionNames.PATCH_BOOK_PUBLICATION_SUCCEEDED,
  'bookPublication'
)
const patchBookPublicationFailed = makeActionCreator(
  ActionNames.PATCH_BOOK_PUBLICATION_FAILED,
  'error'
)

const deleteBookPublicationRequested = makeActionCreator(
  ActionNames.DELETE_BOOK_PUBLICATION_REQUESTED,
  'id'
)
const deleteBookPublicationSucceeded = makeActionCreator(
  ActionNames.DELETE_BOOK_PUBLICATION_SUCCEEDED
)
const deleteBookPublicationFailed = makeActionCreator(
  ActionNames.DELETE_BOOK_PUBLICATION_FAILED,
  'error'
)

/* ----------- STEP SUBMISSION -------------  */
const submitStepStarted = makeActionCreator(ActionNames.SUBMIT_STEP_STARTED)
const submitStepFinished = makeActionCreator(ActionNames.SUBMIT_STEP_FINISHED)

const submitBookDetailsRequested = makeActionCreator(
  ActionNames.SUBMIT_BOOK_DETAILS_REQUESTED,
  'stepData',
  'defaults'
)
const submitBookDetailsSucceeded = makeActionCreator(
  ActionNames.SUBMIT_BOOK_DETAILS_SUCCEEDED,
  'stepData'
)
const submitBookDetailsFailed = makeActionCreator(
  ActionNames.SUBMIT_BOOK_DETAILS_FAILED,
  'error'
)

const submitPublicationDetailsRequested = makeActionCreator(
  ActionNames.SUBMIT_PUBLICATION_DETAILS_REQUESTED,
  'stepData',
  'defaults'
)
const submitPublicationDetailsSucceeded = makeActionCreator(
  ActionNames.SUBMIT_PUBLICATION_DETAILS_SUCCEEDED,
  'stepData'
)
const submitPublicationDetailsFailed = makeActionCreator(
  ActionNames.SUBMIT_PUBLICATION_DETAILS_FAILED,
  'error'
)

const submitContractSigningRequested = makeActionCreator(
  ActionNames.SUBMIT_CONTRACT_SIGNING_REQUESTED,
  'stepData',
  'defaults'
)
const submitContractSigningSucceeded = makeActionCreator(
  ActionNames.SUBMIT_CONTRACT_SIGNING_SUCCEEDED,
  'stepData'
)
const submitContractSigningFailed = makeActionCreator(
  ActionNames.SUBMIT_CONTRACT_SIGNING_FAILED,
  'error'
)

const submitTechnicalInstructionsRequested = makeActionCreator(
  ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_REQUESTED,
  'stepData',
  'defaults'
)
const submitTechnicalInstructionsSucceeded = makeActionCreator(
  ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_SUCCEEDED,
  'stepData'
)
const submitTechnicalInstructionsFailed = makeActionCreator(
  ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_FAILED,
  'error'
)

const submitAuthoringRequested = makeActionCreator(
  ActionNames.SUBMIT_AUTHORING_REQUESTED,
  'stepData',
  'defaults'
)
const submitAuthoringSucceeded = makeActionCreator(
  ActionNames.SUBMIT_AUTHORING_SUCCEEDED,
  'stepData'
)
const submitAuthoringFailed = makeActionCreator(
  ActionNames.SUBMIT_AUTHORING_FAILED,
  'error'
)

const submitManuscriptVerificationRequested = makeActionCreator(
  ActionNames.SUBMIT_MANUSCRIPT_VERIFICATION_REQUESTED,
  'stepData',
  'defaults'
)
const submitManuscriptVerificationSucceeded = makeActionCreator(
  ActionNames.SUBMIT_MANUSCRIPT_VERIFICATION_SUCCEEDED,
  'stepData'
)
const submitManuscriptVerificationFailed = makeActionCreator(
  ActionNames.SUBMIT_MANUSCRIPT_VERIFICATION_FAILED,
  'error'
)

const submitPublicationVerificationRequested = makeActionCreator(
  ActionNames.SUBMIT_PUBLICATION_VERIFICATION_REQUESTED,
  'stepData',
  'defaults'
)
const submitPublicationVerificationSucceeded = makeActionCreator(
  ActionNames.SUBMIT_PUBLICATION_VERIFICATION_SUCCEEDED,
  'stepData'
)
const submitPublicationVerificationFailed = makeActionCreator(
  ActionNames.SUBMIT_PUBLICATION_VERIFICATION_FAILED,
  'error'
)

export default {
  getAuthorsRequested,
  getAuthorsSucceeded,
  getAuthorsFailed,
  postAuthorRequested,
  postAuthorSucceeded,
  postAuthorFailed,
  patchAuthorRequested,
  patchAuthorSucceeded,
  patchAuthorFailed,

  getInstitutionDetailsRequested,
  getInstitutionDetailsSucceeded,
  getInstitutionDetailsFailed,

  getBookCategoriesRequested,
  getBookCategoriesSucceeded,
  getBookCategoriesFailed,
  postBookCategoryRequested,
  postBookCategorySucceeded,
  postBookCategoryFailed,

  postBookRequested,
  postBookSucceeded,
  postBookFailed,
  patchBookRequested,
  patchBookSucceeded,
  patchBookFailed,

  postBookDistributionRequested,
  postBookDistributionSucceeded,
  postBookDistributionFailed,
  patchBookDistributionRequested,
  patchBookDistributionSucceeded,
  patchBookDistributionFailed,

  postContractRequested,
  postContractSucceeded,
  postContractFailed,
  patchContractRequested,
  patchContractSucceeded,
  patchContractFailed,

  postTechnicalInstructionRequested,
  postTechnicalInstructionSucceeded,
  postTechnicalInstructionFailed,
  patchTechnicalInstructionRequested,
  patchTechnicalInstructionSucceeded,
  patchTechnicalInstructionFailed,

  postAuthoringProgressRequested,
  postAuthoringProgressSucceeded,
  postAuthoringProgressFailed,
  patchAuthoringProgressRequested,
  patchAuthoringProgressSucceeded,
  patchAuthoringProgressFailed,

  getBookSeriesRequested,
  getBookSeriesSucceeded,
  getBookSeriesFailed,
  postBookSeriesRequested,
  postBookSeriesSucceeded,
  postBookSeriesFailed,

  getUsersRequested,
  getUsersSucceeded,
  getUsersFailed,

  postCommentRequested,
  postCommentSucceeded,
  postCommentFailed,
  patchCommentRequested,
  patchCommentSucceeded,
  patchCommentFailed,
  deleteCommentRequested,
  deleteCommentSucceeded,
  deleteCommentFailed,

  getStepRequested,
  getStepSucceeded,
  getStepFailed,
  patchStepRequested,
  patchStepSucceeded,
  patchStepFailed,

  initBookPublicationRequested,
  initBookPublicationSucceeded,
  initBookPublicationFailed,
  patchBookPublicationRequested,
  patchBookPublicationSucceeded,
  patchBookPublicationFailed,
  deleteBookPublicationRequested,
  deleteBookPublicationSucceeded,
  deleteBookPublicationFailed,

  submitStepStarted,
  submitStepFinished,

  submitBookDetailsRequested,
  submitBookDetailsSucceeded,
  submitBookDetailsFailed,
  submitPublicationDetailsRequested,
  submitPublicationDetailsSucceeded,
  submitPublicationDetailsFailed,
  submitContractSigningRequested,
  submitContractSigningSucceeded,
  submitContractSigningFailed,
  submitTechnicalInstructionsRequested,
  submitTechnicalInstructionsSucceeded,
  submitTechnicalInstructionsFailed,
  submitAuthoringRequested,
  submitAuthoringSucceeded,
  submitAuthoringFailed,
  submitManuscriptVerificationRequested,
  submitManuscriptVerificationSucceeded,
  submitManuscriptVerificationFailed,
  submitPublicationVerificationRequested,
  submitPublicationVerificationSucceeded,
  submitPublicationVerificationFailed,
}
