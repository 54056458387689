import ActionNames from './actionNames'

import { AuthenticationActionNames } from 'features/authentication/types'

function loginFormRequested(username, password): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_LOGIN_REQUESTED,
    payload: {
      username,
      password,
    },
  }
}

function loginFormSucceeded(): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_LOGIN_SUCCEEDED,
  }
}

function loginFormFailed(error): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_LOGIN_FAILED,
    payload: {
      error,
    },
  }
}

function refreshRequested(): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_REFRESH_REQUESTED,
  }
}

function refreshSucceeded(): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_REFRESH_SUCCEEDED,
  }
}

function refreshFailed(error): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_REFRESH_FAILED,
    payload: {
      error,
    },
  }
}

function tokenRefreshingStarted(): AuthenticationActionNames {
  return {
    type: ActionNames.TOKEN_REFRESHING_STARTED,
  }
}

function tokenRefreshingFinished(): AuthenticationActionNames {
  return {
    type: ActionNames.TOKEN_REFRESHING_FINISHED,
  }
}

function logoutRequested(): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_LOGOUT,
  }
}

function registerFormRequested(
  email: string,
  password: string
): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_REGISTER_REQUESTED,
    payload: {
      email,
      password,
    },
  }
}

function registerFormSucceeded(): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_REGISTER_SUCCEEDED,
  }
}

function registerFormFailed(error): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_REGISTER_FAILED,
    payload: {
      error,
    },
  }
}

function forgotPasswordRequested(username): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_FORGOT_PASSWORD_REQUESTED,
    payload: {
      username,
    },
  }
}

function forgotPasswordSucceeded(): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_FORGOT_PASSWORD_SUCCEEDED,
  }
}

function forgotPasswordFailed(error): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_FORGOT_PASSWORD_FAILED,
    payload: {
      error,
    },
  }
}

function resetPasswordRequested(
  username,
  password,
  resetPasswordToken
): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_RESET_PASSWORD_REQUESTED,
    payload: {
      username,
      password,
      resetPasswordToken,
    },
  }
}

function resetPasswordSucceeded(): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_RESET_PASSWORD_SUCCEEDED,
  }
}

function resetPasswordFailed(error): AuthenticationActionNames {
  return {
    type: ActionNames.AUTH_RESET_PASSWORD_FAILED,
    payload: {
      error,
    },
  }
}

export default {
  logoutRequested,

  loginFormRequested,
  loginFormSucceeded,
  loginFormFailed,

  refreshRequested,
  refreshSucceeded,
  refreshFailed,

  tokenRefreshingStarted,
  tokenRefreshingFinished,

  registerFormRequested,
  registerFormFailed,
  registerFormSucceeded,

  forgotPasswordRequested,
  forgotPasswordSucceeded,
  forgotPasswordFailed,

  resetPasswordRequested,
  resetPasswordSucceeded,
  resetPasswordFailed,
}
