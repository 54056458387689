import React, { FC, useCallback, useState } from 'react'
import { Col, Layout, Row, Statistic } from 'antd'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { AuthenticationActionNames } from 'features/authentication/types'
import { AuthenticationActionCreators } from 'features/authentication/ducks'
import { RootState } from 'redux/types'
import Configs from 'configs/configs'
import { Button, Tooltip } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'

const FooterWrapper = styled(Layout.Footer)`
  background: #101010;
`

const BottomText = styled.p`
  color: #d0d0d0;
  margin: 1em 0 0 0;
`

const Footer: FC<any> = (props) => {
  const { isAuthenticated, loading, actions } = props

  // Toggle boolean to force a countdown reset
  const [forceReset, setForceReset] = useState(false)

  const getTimeoutDate = useCallback(() => {
    if (!loading) {
      return Date.now() + Configs.InactivityTimer * 1000
    }
  }, [loading])

  const logoutUser = useCallback(() => {
    actions.logoutRequested()
  }, [actions])

  // Obvious TODO
  return (
    <FooterWrapper>
      <Row align="bottom">
        <Col span={6} offset={9} style={{ textAlign: 'center' }}>
          <BottomText>&#169;Hypernetica 2021. All rights reserved.</BottomText>
        </Col>
        <Col span={4} offset={5} style={{ textAlign: 'right' }}>
          {isAuthenticated && (
            <Statistic.Countdown
              prefix="Χρονόμετρο αδράνειας"
              value={getTimeoutDate()}
              format="mm:ss"
              valueStyle={{ fontSize: 14, color: '#d0d0d0' }}
              onFinish={logoutUser}
              suffix={
                <Tooltip placement="topRight" title="Ανανέωση χρονομέτρου">
                  <Button
                    shape="circle"
                    size="small"
                    icon={<ReloadOutlined />}
                    onClick={() => setForceReset(!forceReset)}
                  />
                </Tooltip>
              }
            />
          )}
        </Col>
      </Row>
    </FooterWrapper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: state.user.auth.isAuthenticated,
    loading: state.common.ui.loading,
  }
}

export const mapDispatchToProps = (
  dispatch: Dispatch<AuthenticationActionNames>
) => {
  return {
    actions: bindActionCreators({ ...AuthenticationActionCreators }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
