import React, { FC, useCallback } from 'react'
import { Form, Input, Button, Layout, Typography } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { MailOutlined, LockOutlined } from '@ant-design/icons'
import Routes from 'configs/routes'
import Utils from 'common/utils'
import Configs from 'configs/configs'
import { Dispatch, bindActionCreators } from 'redux'
import { AuthenticationActionNames } from 'features/authentication/types'
import { AuthenticationActionCreators } from 'features/authentication/ducks'
import { connect } from 'react-redux'

const CardMargin = styled(Layout)`
  width: min(100vw, 450px);
  align: center;
  padding: 20px 30px 0 30px;
  border-radius: 3px;
`

const LoginWrapper = styled(Form.Item)`
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
`

const FormButton = styled(Button)`
  min-width: 90px;
  width: 50%;
  font-weight: 500;
`

const ForgotPasswordLink = styled(Link)`
  float: right;
  margin-bottom: 24px;
`

const LoginForm: FC<any> = (props) => {
  const [form] = Form.useForm()

  const { actions } = props

  const handleSubmit = useCallback(() => {
    form.validateFields().then((values) => {
      Utils.logger.info(`User :: Authentication :: Login Request :: `, values)
      actions.loginFormRequested(values.email, values.password)
    })
  }, [form, actions])

  return (
    <CardMargin>
      <Typography.Title style={{ fontSize: 24 }}>
        Σύνδεση στην εφαρμογή
      </Typography.Title>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: Configs.Messages.RequiredField },
            {
              type: 'email',
              message: Configs.Messages.InvalidEmail,
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Form.Item>
        <Form.Item
          label="Κωδικός"
          name="password"
          style={{ marginBottom: '4px' }}
          rules={[{ required: true, message: Configs.Messages.RequiredField }]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Form.Item>
        <ForgotPasswordLink to={Routes.ForgotPassword}>
          Ξέχασα τον κωδικό μου
        </ForgotPasswordLink>
        <LoginWrapper>
          <FormButton type="primary" htmlType="submit">
            Σύνδεση
          </FormButton>
        </LoginWrapper>
      </Form>
    </CardMargin>
  )
}

export const mapDispatchToProps = (
  dispatch: Dispatch<AuthenticationActionNames>
) => {
  return {
    actions: bindActionCreators({ ...AuthenticationActionCreators }, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(LoginForm) as any
