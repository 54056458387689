import React, { useState, useMemo, useEffect, FC } from 'react'
import { Row, Col, Form, Checkbox, DatePicker, Select } from 'antd'
import Configs from 'configs/configs'
import moment from 'moment'
import 'moment/locale/el'
import { StepCompletionSection } from 'features/bookPublicationForm/types'
import { UIUser } from 'features/authentication/types'

import { Dispatch, bindActionCreators } from 'redux'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'

const { Option } = Select

const CompletionSection: FC<any> = (props) => {
  const defaults: StepCompletionSection = props.defaults
  const { form, actions, allUsers } = props

  const [stepCompleted, setStepCompleted] = useState(
    defaults?.stepCompleted || false
  )
  const [users, setUsers] = useState<UIUser[]>([])

  useMemo(() => {
    // componentWillMount equivalent
    form.setFieldsValue({
      completion: {
        assignees: form
          .getFieldValue(['completion', 'assignees'])
          ?.map((item) => item.username),
      },
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    actions.getUsersRequested()
  }, [actions])

  useEffect(() => {
    if (allUsers.length) {
      setUsers(allUsers)
    }
    // setUsers(TestUsers)     // use this line instead of the above for test data
  }, [allUsers])

  const onCompletedChange = () => {
    if (!stepCompleted) {
      form.setFieldsValue({ completion: { completionDate: moment() } })
    }
    setStepCompleted(!stepCompleted)
  }

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item name={['completion', 'assignees']} label="Υπεύθυνοι">
            <Select
              mode="multiple"
              allowClear
              style={{ width: '95%' }}
              placeholder="Επιλέξτε"
            >
              {users.map((item) => (
                <Option key={item.username} value={item.username}>
                  {item.lastname} {item.firstname}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['completion', 'desiredDate']}
            label="Επιθυμητή ημερομηνία ολοκλήρωσης"
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name={['completion', 'stepCompleted']}
            valuePropName="checked"
            label="Το βήμα έχει ολοκληρωθεί"
            rules={[
              {
                transform: (value) => value || undefined,
                type: 'boolean',
              },
            ]}
          >
            <Checkbox onChange={onCompletedChange} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['completion', 'completionDate']}
            label="Ημερομηνία ολοκλήρωσης"
            rules={[
              { required: true, message: Configs.Messages.RequiredField },
            ]}
          >
            <DatePicker
              format={Configs.Date.Format}
              placeholder={Configs.Date.Placeholder}
              disabled={!stepCompleted}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    allUsers: state.entities.getData.users,
    usersErrors: state.entities.errors.getUsersErrors,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletionSection) as any
