import React, { useState, useEffect, useCallback, FC } from 'react'
import TextArea from 'antd/lib/input/TextArea'
import { List, Comment, Tooltip, Form, Button, message, Popconfirm } from 'antd'
import 'moment/locale/el'
import Configs from 'configs/configs'
import styled from 'styled-components'
import { UIComment, UIStep } from 'features/bookPublicationForm/types'
import Services from 'services'
import Constants from 'common/constants'

import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { RootState, GenericActionType } from 'redux/types'
import { EntitiesActionCreators } from 'features/bookPublicationForm/ducks'
import { UIUser } from 'features/authentication/types'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import moment from 'moment'

const CommentsSectionWrapper = styled.div`
  .ant-list-header {
    padding-top: 0 !important;
  }
`

const CommentWrapper = styled(Comment)`
  margin-left: 10px;

  .ant-comment-actions {
    margin-top: 4px;
  }
`

const CommentEditorWrapper = styled.div`
  margin-top: 20px;
  margin-left: 10px;

  .ant-form-item {
    margin-bottom: 8px;
  }
`

const CommentsSection: FC<any> = (props) => {
  const currentStep: UIStep = props.currentStep
  const postComment: UIComment = props.postComment
  const {
    currentStepId,
    actions,
    isAuthenticated,
    isLoading,
    postCommentErrors,
  } = props

  const [comments, setComments] = useState<UIComment[]>([])
  const [currentUser, setCurrentUser] = useState<UIUser>()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [textValue, setTextValue] = useState('')

  useEffect(() => {
    if (isAuthenticated) {
      setCurrentUser(
        JSON.parse(Services.LocalStorage.get(Constants.STORAGE_TOKEN_USER_DATA))
      )
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (currentStepId) {
      actions.getStepRequested(currentStepId)
    } else {
      setComments([])
    }
  }, [actions, currentStepId])

  const [isSubmittingEdit, setIsSubmittingEdit] = useState(false)
  const [editingId, setEditingId] = useState('')
  const [editTextValue, setEditTextValue] = useState('')

  useEffect(() => {
    if (isSubmitting && !isLoading) {
      if (postCommentErrors.length) {
        message.error('Δεν ήταν δυνατή η υποβολή του σχολίου', 2)
      } else if (postComment) {
        setComments((prevComments) => [...prevComments, postComment])
        setTextValue('')
        setIsSubmitting(false)
      }
    }
  }, [isLoading, postComment, postCommentErrors]) // eslint-disable-line

  const getNewRowKey = useCallback(() => {
    return comments.length > 0
      ? Math.max(...comments.map((item) => +item.key!)) + 1
      : 0
  }, [comments])

  const submitEdit = () => {
    const editedComment: UIComment = {
      ...comments.find((el) => el.id === editingId)!,
      content: editTextValue,
      date: moment(),
    }
    setIsSubmittingEdit(true)
    setTimeout(() => {
      setComments(
        comments.map((el) => (el.id === editingId ? editedComment : el))
      )
      actions.patchCommentRequested(editedComment)
      setEditingId('')
      setIsSubmittingEdit(false)
    }, 1000)
  }

  const cancelEdit = () => {
    setEditingId('')
    setIsSubmittingEdit(false)
  }

  const editComment = (comment: UIComment) => {
    setEditingId(comment.id!)
    setEditTextValue(comment.content)
  }

  const deleteComment = (comment: UIComment) => {
    actions.deleteCommentRequested(comment.id, currentStepId)
    setComments(comments.filter((el) => el !== comment))
  }

  const getCommentActions = (comment: UIComment) => [
    <span onClick={() => editComment(comment)} style={{ color: 'dodgerblue' }}>
      <EditOutlined /> Επεξεργασία &nbsp;
    </span>,
    <Popconfirm
      title="Θέλετε σίγουρα να διαγράψετε αυτό το σχόλιο;"
      onConfirm={() => deleteComment(comment)}
    >
      <span style={{ color: 'crimson' }}>
        <DeleteOutlined /> Διαγραφή
      </span>
    </Popconfirm>,
  ]

  useEffect(() => {
    if (currentStep) {
      setComments(currentStep.comments)
    }
  }, [currentStep])

  const handleSubmit = () => {
    if (!textValue) {
      return
    }

    setIsSubmitting(true)
    const newComment: Partial<UIComment> = {
      user: currentUser!,
      content: textValue,
      key: getNewRowKey(),
    }
    setTimeout(() => {
      actions.postCommentRequested(currentStepId, newComment)
    }, 1000)
  }

  const renderComment = (comment: UIComment) => {
    return comment.id === editingId ? (
      <CommentWrapper
        author={`${comment.user.firstname} ${comment.user.lastname}`}
        content={
          <>
            <TextArea
              rows={3}
              onChange={(event) => setEditTextValue(event.target.value)}
              value={editTextValue}
              style={{ marginBottom: 8 }}
            />
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={isSubmittingEdit}
              onClick={submitEdit}
              disabled={!editTextValue || editTextValue === comment.content}
              style={{ marginRight: 8 }}
            >
              Ολοκλήρωση Επεξεργασίας
            </Button>
            <Button size="small" onClick={cancelEdit}>
              Ακύρωση
            </Button>
          </>
        }
        datetime={
          <Tooltip title={comment.date?.format(Configs.Date.FormatFull)}>
            <i>
              &nbsp;
              {comment.date > comment.createdAt
                ? comment.date?.fromNow() + ' (επεξεργασμένο)'
                : comment.createdAt?.fromNow()}
            </i>
          </Tooltip>
        }
      />
    ) : (
      <CommentWrapper
        {...(comment.user.id === currentUser?.id
          ? { actions: getCommentActions(comment) }
          : {})}
        author={`${comment.user.firstname} ${comment.user.lastname}`}
        content={<p>{comment.content}</p>}
        datetime={
          <Tooltip title={comment.date?.format(Configs.Date.FormatFull)}>
            <i>
              &nbsp;
              {comment.date > comment.createdAt
                ? comment.date?.fromNow() + ' (επεξεργασμένο)'
                : comment.createdAt?.fromNow()}
            </i>
          </Tooltip>
        }
      />
    )
  }

  return (
    <CommentsSectionWrapper>
      <List
        className="comment-list"
        header={`${comments?.length} σχόλια`}
        itemLayout="horizontal"
        locale={{ emptyText: ' ' }}
        dataSource={comments}
        renderItem={(item) => <li>{renderComment(item)}</li>}
      />
      <CommentEditorWrapper>
        <Form.Item>
          <TextArea
            rows={4}
            onChange={(event) => setTextValue(event.target.value)}
            value={textValue}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            loading={isSubmitting}
            onClick={handleSubmit}
            disabled={!textValue}
          >
            Προσθήκη Σχολίου
          </Button>
        </Form.Item>
      </CommentEditorWrapper>
    </CommentsSectionWrapper>
  )
}

export const mapDispatchToProps = (dispatch: Dispatch<GenericActionType>) => {
  return {
    actions: bindActionCreators({ ...EntitiesActionCreators }, dispatch),
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.common.ui.loading,
    isAuthenticated: state.user.auth.isAuthenticated,

    currentStep: state.entities.getData.step,
    stepErrors: state.entities.errors.getStepErrors,

    postComment: state.entities.postData.comment,
    postCommentErrors: state.entities.errors.postCommentErrors,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsSection) as any
