import { combineReducers } from 'redux'

import { CommonActionTypes } from 'common/ducks'
import ActionNames from './actionNames'
import {
  AuthenticationActionNames,
  IAuthState,
  IErrorState,
} from 'features/authentication/types'

const initialAuthState: IAuthState = {
  isAuthenticated: false,
  isTokenRefreshing: false,
  hasSentForgotPasswordEmail: false,
  hasResetPassword: false,
}

function AuthReducer(
  state = initialAuthState,
  action: AuthenticationActionNames
): IAuthState {
  switch (action.type) {
    case ActionNames.AUTH_LOGIN_SUCCEEDED:
    case ActionNames.AUTH_REFRESH_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: true,
      }

    case ActionNames.TOKEN_REFRESHING_STARTED:
      return {
        ...state,
        isTokenRefreshing: true,
      }

    case ActionNames.TOKEN_REFRESHING_FINISHED:
      return {
        ...state,
        isTokenRefreshing: false,
      }

    case ActionNames.AUTH_FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        hasSentForgotPasswordEmail: true,
      }

    case ActionNames.AUTH_RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        hasResetPassword: true,
      }

    case CommonActionTypes.RESET_UI:
      return {
        ...state,
        hasSentForgotPasswordEmail: false,
        hasResetPassword: false,
      }

    case ActionNames.AUTH_LOGIN_FAILED:
    case ActionNames.AUTH_REFRESH_FAILED:
    case ActionNames.AUTH_FORGOT_PASSWORD_FAILED:
    case ActionNames.AUTH_RESET_PASSWORD_FAILED:
    case ActionNames.AUTH_LOGOUT:
      return initialAuthState

    default:
      return state
  }
}

const initialErrorState: IErrorState = []

function ErrorReducer(
  state = initialErrorState,
  action: AuthenticationActionNames
): IErrorState {
  switch (action.type) {
    case ActionNames.AUTH_LOGIN_FAILED:
    case ActionNames.AUTH_REGISTER_FAILED:
    case ActionNames.AUTH_FORGOT_PASSWORD_FAILED:
    case ActionNames.AUTH_RESET_PASSWORD_FAILED: {
      const { message } = (action as any).payload.error
      try {
        return [...JSON.parse(message)]
      } catch {
        return [message]
      }
    }

    case CommonActionTypes.COMMON_UI_LOADING_STARTED:
      return initialErrorState

    default:
      return state
  }
}

export default combineReducers({
  auth: AuthReducer,
  errors: ErrorReducer,
})
