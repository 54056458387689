import {
  call,
  take,
  put,
  all,
  fork,
  takeLatest,
  cancel,
} from 'redux-saga/effects'

import Services from 'services'
import ActionNames from './actionNames'
import ActionCreators from './actionCreators'
import { CommonActionCreators } from 'common/ducks'
import Constants from 'common/constants'
import Endpoints from 'api/endpoints'
import { UIUser } from '../types'

/**
 * A saga watcher for the login flow
 */
function* loginFlowWatcher() {
  while (true) {
    const { payload } = yield take(ActionNames.AUTH_LOGIN_REQUESTED)

    // Fork request to discard blocking mode, affection the actions bellow
    const authorizeTask = yield fork(authorize, payload)

    // When we've got a logout action or a failed login
    const action = yield take([
      ActionNames.AUTH_LOGOUT,
      ActionNames.AUTH_LOGIN_FAILED,
    ])

    if (action.type === ActionNames.AUTH_LOGOUT) {
      yield cancel(authorizeTask)
    }

    yield put(ActionCreators.logoutRequested())
  }
}

/**
 * A saga worker to handle login authorization
 */
function* authorize(payload) {
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    const response = yield call(
      Services.Api.post as any,
      Endpoints.User.Login,
      {
        body: {
          type: 'User',
          attributes: { ...payload },
        },
      }
    )

    // Store token's expiration in the browser's localStorage
    yield call(
      Services.LocalStorage.set,
      Constants.STORAGE_TOKEN_EXPIRATION_DATE,
      Date.now() + response?.access_expires_in * 1000
    )

    // Store refresh token's expiration in the browser's localStorage
    yield call(
      Services.LocalStorage.set,
      Constants.STORAGE_REFRESH_TOKEN_EXPIRATION_DATE,
      Date.now() + response?.refresh_expires_in * 1000
    )

    const currentUser: UIUser = {
      id: response?.id,
      username: response?.username,
      email: response?.email,
      firstname: response?.name,
      lastname: response?.surname,
      isAdmin: false, // TODO
    }

    yield call(
      Services.LocalStorage.set,
      Constants.STORAGE_TOKEN_USER_DATA,
      JSON.stringify(currentUser)
    )

    // Store token in the Redux store
    yield put(ActionCreators.loginFormSucceeded())
  } catch (error) {
    yield put(ActionCreators.loginFormFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/**
 * A saga watcher for the Refresh flow
 */
function* refreshFlowWatcher() {
  yield takeLatest(ActionNames.AUTH_REFRESH_REQUESTED, refresh)
}

/**
 * A saga worker to handle refresh
 */
function* refresh() {
  try {
    yield put(ActionCreators.tokenRefreshingStarted())

    const response = yield call(
      Services.Api.post as any,
      Endpoints.User.Refresh,
      {}
    )

    yield call(
      Services.LocalStorage.set,
      Constants.STORAGE_TOKEN_EXPIRATION_DATE,
      Date.now() + response?.access_expires_in * 1000
    )
    yield put(ActionCreators.refreshSucceeded())
  } catch (error) {
    yield put(ActionCreators.refreshFailed(error))
  } finally {
    yield put(ActionCreators.tokenRefreshingFinished())
  }
}

/**
 * A saga watcher for the Logout flow
 */
function* logoutFlowWatcher() {
  yield takeLatest(ActionNames.AUTH_LOGOUT, logout)
}

/**
 * A saga worker to handle logout
 */
function* logout() {
  yield call(
    Services.LocalStorage.remove,
    Constants.STORAGE_TOKEN_EXPIRATION_DATE
  )
  yield call(Services.LocalStorage.remove, Constants.STORAGE_TOKEN_USER_DATA)
}

/**
 * A saga watcher for the Register flow
 */
function* registerFlowWatcher() {
  yield takeLatest(ActionNames.AUTH_REGISTER_REQUESTED, register)
}

/**
 * A saga worker to handle registration form
 */
function* register(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    yield call(Services.Api.post as any, Endpoints.User.Register, {
      body: {
        type: 'User',
        attributes: { ...payload },
      },
    })

    yield put(ActionCreators.registerFormSucceeded())
  } catch (error) {
    yield put(ActionCreators.registerFormFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/**
 * A saga watcher for the Register flow
 */
function* forgotPasswordFlowWatcher() {
  yield takeLatest(ActionNames.AUTH_FORGOT_PASSWORD_REQUESTED, forgotPassword)
}

function* forgotPassword(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    yield call(Services.Api.post as any, Endpoints.User.ForgotPassword, {
      body: {
        type: 'User',
        attributes: { ...payload },
      },
    })

    yield put(ActionCreators.forgotPasswordSucceeded())
  } catch (error) {
    yield put(ActionCreators.forgotPasswordFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

/**
 * A saga watcher for the Reset flow
 */
function* resetPasswordFlowWatcher() {
  yield takeLatest(ActionNames.AUTH_RESET_PASSWORD_REQUESTED, resetPassword)
}

function* resetPassword(action) {
  const { payload } = action
  try {
    yield put(CommonActionCreators.uiLoadingStarted())

    yield call(Services.Api.post as any, Endpoints.User.ResetPassword, {
      body: {
        type: 'User',
        attributes: { ...payload },
      },
    })

    yield put(ActionCreators.resetPasswordSucceeded())
  } catch (error) {
    yield put(ActionCreators.resetPasswordFailed(error))
  } finally {
    yield put(CommonActionCreators.uiLoadingFinished())
  }
}

// Single entry point to start all sagas at once
export default function* rootSaga() {
  yield all([
    loginFlowWatcher(),
    refreshFlowWatcher(),
    logoutFlowWatcher(),
    registerFlowWatcher(),
    forgotPasswordFlowWatcher(),
    resetPasswordFlowWatcher(),
  ])
}
