import React, { FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRoutes from 'routes'
import { ConfigProvider } from 'antd'
import elGR from 'antd/es/locale/el_GR'
import { Provider } from 'react-redux'
import Store from 'redux/store'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'

const App: FC = () => (
  <Provider store={Store}>
    <ConfigProvider locale={elGR}>
      <Router>
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      </Router>
    </ConfigProvider>
  </Provider>
)

export default App
