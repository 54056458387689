import * as LocalStorage from 'local-storage'

const CookieName = 'HyperneticaLogger'
const CookieRegex = new RegExp('(^|; )' + CookieName + '=([^;]*)')

const DefaultConfig = {
  timestamps: true,
  whitelist: [],
  blacklist: [],
}

/**
 * HyperneticaLogger is a logging module designed to be used by applications that
 * want to include support logging but do not wish to have this always occur.
 *
 * Instead you must opt-in to the logging by
 * setting a cookie `HyperneticaLogger` to any value
 *
 */
class HyperneticaLogger {
  // Prefix to include with each line,
  // intended to allow quick filtering on
  // lines of interest
  private prefix: string

  // Determine whether logger will be visible by default
  private enabled: boolean

  // Defines the logging methods to use
  private logger: { error: {}; warn: {}; info: {} }

  // Provides configs for the logger output
  // - "timestamps" when true add timestamps on the log messages
  // - "whitelist" an array of regex to display only messages matches the regexs
  // - "blacklist" an array of regex to hide only messages matches the regexs
  private config: {
    whitelist: Array<RegExp>
    blacklist: Array<RegExp>
    timestamps: Boolean
  }

  /**
   * @param prefix {string} prefix - The log prefix to use for all logging coming from
   *  this instance
   */
  constructor(prefix: string = 'default') {
    this.prefix = prefix

    // check for the methods we want to use existing in the browser
    const fallback = console && console.log ? console.log : undefined

    this.logger = {
      error: {
        scope: console,
        func: console && console.error ? console.error : fallback,
      },
      warn: {
        scope: console,
        func: console && console.warn ? console.warn : fallback,
      },
      info: {
        scope: console,
        func: console && console.info ? console.info : fallback,
      },
    }

    this.enabled = this.isLoggingEnabled()

    this.config = this.getConfig()
  }

  /**
   * Check for the cookie name whether
   * is set in order to enable logging
   */
  private isLoggingEnabled() {
    return CookieRegex.test(document.cookie)
  }

  /**
   * Get the proper configs for the logger from localstorage
   *
   * @private
   * @memberof HyperneticaLogger
   */
  private getConfig = () => {
    const timestamps =
      JSON.parse(LocalStorage.get('HyperneticaLogger_Timestamps')) ||
      DefaultConfig.timestamps
    const whitelist =
      JSON.parse(LocalStorage.get('HyperneticaLogger_Whitelist')) ||
      DefaultConfig.whitelist
    const blacklist =
      JSON.parse(LocalStorage.get('HyperneticaLogger_Blacklist')) ||
      DefaultConfig.blacklist

    return {
      ...DefaultConfig,
      timestamps,
      whitelist,
      blacklist,
    }
  }

  /**
   * Log an error level message
   *
   * @param {...*} ...args - a string with optional placeholders followed by
   *   values to be substituted (follows the same rules as console.log)
   */
  public error = (...args) => {
    this.log('error', args)
    return this
  }

  /**
   * Log a warning level message
   *
   * @param {...*} ...args - a string with optional placeholders followed by
   *   values to be substituted (follows the same rules as console.log)
   */
  public warn = (...args) => {
    this.log('warn', args)
    return this
  }

  /**
   * Log an info level message
   *
   * @param {...*} ...args - a string with optional placeholders followed by
   *   values to be substituted (follows the same rules as console.log)
   */
  public info = (...args) => {
    this.log('info', args)
    return this
  }

  /**
   * @private
   *
   * Invoke the appropriate logger
   *
   * @param {string} level - log level desired, expecting one of `error`, `warn`
   *   or `info`.
   * @param {...*} ...args - a string with optional placeholders followed by
   *   values to be substituted (follows the same rules as console.log)
   */
  private log = (level, args) => {
    // Check logging is ok
    if (!this.enabled) {
      return
    }

    // call appropriate logger
    const { scope, func } = this.logger[level]

    if (!scope || !func) {
      return
    }

    args[0] = `${this.getPrefix()} :: ${args[0]}`

    if (!this.isWhitelisted(args)) {
      return
    }

    if (this.isBlacklisted(args)) {
      return
    }

    func.apply(scope, args)
  }

  private isWhitelisted = (args) => {
    if (!this.config.whitelist.length) {
      return true
    }

    return this.config.whitelist.some((expr) =>
      this.testFilterExpression(expr, args[0])
    )
  }

  private isBlacklisted = (args) => {
    if (!this.config.blacklist.length) {
      return false
    }

    return this.config.blacklist.some((expr) =>
      this.testFilterExpression(expr, args[0])
    )
  }

  private getPrefix = (): string => {
    return [this.getTimePrefix(), this.prefix]
      .filter((p) => Boolean(p))
      .join(' ')
  }

  private getTimePrefix = (): string | undefined => {
    if (!this.config.timestamps) {
      return
    }
    const current = new Date()
    return `( ${current.toLocaleTimeString()}.${current.getMilliseconds()} )`
  }

  private testFilterExpression(
    expr: RegExp,
    message: string
  ): RegExp | Boolean {
    if (typeof expr === 'string') {
      return new RegExp(expr).test(message)
    }
    if (expr instanceof RegExp) {
      return expr.test(message)
    }
    return false
  }
}

export default HyperneticaLogger
