const PREFIX = 'features/bookPublicationForm'

const ActionNames = {
  // Authors:
  GET_AUTHORS_REQUESTED: `${PREFIX}/GET/authors/requested`,
  GET_AUTHORS_SUCCEEDED: `${PREFIX}/GET/authors/succeeded`,
  GET_AUTHORS_FAILED: `${PREFIX}/GET/authors/failed`,

  POST_AUTHOR_REQUESTED: `${PREFIX}/POST/authors/requested`,
  POST_AUTHOR_SUCCEEDED: `${PREFIX}/POST/authors/succeeded`,
  POST_AUTHOR_FAILED: `${PREFIX}/POST/authors/failed`,

  PATCH_AUTHOR_REQUESTED: `${PREFIX}/PATCH/authors/requested`,
  PATCH_AUTHOR_SUCCEEDED: `${PREFIX}/PATCH/authors/succeeded`,
  PATCH_AUTHOR_FAILED: `${PREFIX}/PATCH/authors/failed`,

  // Institution Details:
  GET_INSTITUTION_DETAILS_REQUESTED: `${PREFIX}/GET/institution-details/requested`,
  GET_INSTITUTION_DETAILS_SUCCEEDED: `${PREFIX}/GET/institution-details/succeeded`,
  GET_INSTITUTION_DETAILS_FAILED: `${PREFIX}/GET/institution-details/failed`,

  // Book Categories:
  GET_BOOK_CATEGORIES_REQUESTED: `${PREFIX}/GET/book-categories/requested`,
  GET_BOOK_CATEGORIES_SUCCEEDED: `${PREFIX}/GET/book-categories/succeeded`,
  GET_BOOK_CATEGORIES_FAILED: `${PREFIX}/GET/book-categories/failed`,

  POST_BOOK_CATEGORY_REQUESTED: `${PREFIX}/POST/book-categories/requested`,
  POST_BOOK_CATEGORY_SUCCEEDED: `${PREFIX}/POST/book-categories/succeeded`,
  POST_BOOK_CATEGORY_FAILED: `${PREFIX}/POST/book-categories/failed`,

  // Books:
  POST_BOOK_REQUESTED: `${PREFIX}/POST/book/requested`,
  POST_BOOK_SUCCEEDED: `${PREFIX}/POST/book/succeeded`,
  POST_BOOK_FAILED: `${PREFIX}/POST/book/failed`,

  PATCH_BOOK_REQUESTED: `${PREFIX}/PATCH/book/requested`,
  PATCH_BOOK_SUCCEEDED: `${PREFIX}/PATCH/book/succeeded`,
  PATCH_BOOK_FAILED: `${PREFIX}/PATCH/book/failed`,

  // Book Distributions:
  POST_BOOK_DISTRIBUTION_REQUESTED: `${PREFIX}/POST/book-distribution/requested`,
  POST_BOOK_DISTRIBUTION_SUCCEEDED: `${PREFIX}/POST/book-distribution/succeeded`,
  POST_BOOK_DISTRIBUTION_FAILED: `${PREFIX}/POST/book-distribution/failed`,

  PATCH_BOOK_DISTRIBUTION_REQUESTED: `${PREFIX}/PATCH/book-distribution/requested`,
  PATCH_BOOK_DISTRIBUTION_SUCCEEDED: `${PREFIX}/PATCH/book-distribution/succeeded`,
  PATCH_BOOK_DISTRIBUTION_FAILED: `${PREFIX}/PATCH/book-distribution/failed`,

  // Contracts:
  POST_CONTRACT_REQUESTED: `${PREFIX}/POST/contract/requested`,
  POST_CONTRACT_SUCCEEDED: `${PREFIX}/POST/contract/succeeded`,
  POST_CONTRACT_FAILED: `${PREFIX}/POST/contract/failed`,

  PATCH_CONTRACT_REQUESTED: `${PREFIX}/PATCH/contract/requested`,
  PATCH_CONTRACT_SUCCEEDED: `${PREFIX}/PATCH/contract/succeeded`,
  PATCH_CONTRACT_FAILED: `${PREFIX}/PATCH/contract/failed`,

  // Technical Instructions:
  POST_TECHNICAL_INSTRUCTION_REQUESTED: `${PREFIX}/POST/technical-instruction/requested`,
  POST_TECHNICAL_INSTRUCTION_SUCCEEDED: `${PREFIX}/POST/technical-instruction/succeeded`,
  POST_TECHNICAL_INSTRUCTION_FAILED: `${PREFIX}/POST/technical-instruction/failed`,

  PATCH_TECHNICAL_INSTRUCTION_REQUESTED: `${PREFIX}/PATCH/technical-instruction/requested`,
  PATCH_TECHNICAL_INSTRUCTION_SUCCEEDED: `${PREFIX}/PATCH/technical-instruction/succeeded`,
  PATCH_TECHNICAL_INSTRUCTION_FAILED: `${PREFIX}/PATCH/technical-instruction/failed`,

  // Authoring Progress:
  POST_AUTHORING_PROGRESS_REQUESTED: `${PREFIX}/POST/authoring-progress/requested`,
  POST_AUTHORING_PROGRESS_SUCCEEDED: `${PREFIX}/POST/authoring-progress/succeeded`,
  POST_AUTHORING_PROGRESS_FAILED: `${PREFIX}/POST/authoring-progress/failed`,

  PATCH_AUTHORING_PROGRESS_REQUESTED: `${PREFIX}/PATCH/authoring-progress/requested`,
  PATCH_AUTHORING_PROGRESS_SUCCEEDED: `${PREFIX}/PATCH/authoring-progress/succeeded`,
  PATCH_AUTHORING_PROGRESS_FAILED: `${PREFIX}/PATCH/authoring-progress/failed`,

  // Book Series:
  GET_BOOK_SERIES_REQUESTED: `${PREFIX}/GET/book-series/requested`,
  GET_BOOK_SERIES_SUCCEEDED: `${PREFIX}/GET/book-series/succeeded`,
  GET_BOOK_SERIES_FAILED: `${PREFIX}/GET/book-series/failed`,

  POST_BOOK_SERIES_REQUESTED: `${PREFIX}/POST/book-series/requested`,
  POST_BOOK_SERIES_SUCCEEDED: `${PREFIX}/POST/book-series/succeeded`,
  POST_BOOK_SERIES_FAILED: `${PREFIX}/POST/book-series/failed`,

  // Users:
  GET_USERS_REQUESTED: `${PREFIX}/GET/users/requested`,
  GET_USERS_SUCCEEDED: `${PREFIX}/GET/users/succeeded`,
  GET_USERS_FAILED: `${PREFIX}/GET/users/failed`,

  // Comments:
  POST_COMMENT_REQUESTED: `${PREFIX}/POST/comment/requested`,
  POST_COMMENT_SUCCEEDED: `${PREFIX}/POST/comment/succeeded`,
  POST_COMMENT_FAILED: `${PREFIX}/POST/comment/failed`,

  PATCH_COMMENT_REQUESTED: `${PREFIX}/PATCH/comment/requested`,
  PATCH_COMMENT_SUCCEEDED: `${PREFIX}/PATCH/comment/succeeded`,
  PATCH_COMMENT_FAILED: `${PREFIX}/PATCH/comment/failed`,

  DELETE_COMMENT_REQUESTED: `${PREFIX}/DELETE/comment/requested`,
  DELETE_COMMENT_SUCCEEDED: `${PREFIX}/DELETE/comment/succeeded`,
  DELETE_COMMENT_FAILED: `${PREFIX}/DELETE/comment/failed`,

  // Book Publication:
  INIT_BOOK_PUBLICATION_REQUESTED: `${PREFIX}/init/book-publication/requested`,
  INIT_BOOK_PUBLICATION_SUCCEEDED: `${PREFIX}/init/book-publication/succeeded`,
  INIT_BOOK_PUBLICATION_FAILED: `${PREFIX}/init/book-publication/failed`,

  PATCH_BOOK_PUBLICATION_REQUESTED: `${PREFIX}/PATCH/book-publication/requested`,
  PATCH_BOOK_PUBLICATION_SUCCEEDED: `${PREFIX}/PATCH/book-publication/succeeded`,
  PATCH_BOOK_PUBLICATION_FAILED: `${PREFIX}/PATCH/book-publication/failed`,

  DELETE_BOOK_PUBLICATION_REQUESTED: `${PREFIX}/DELETE/book-publication/requested`,
  DELETE_BOOK_PUBLICATION_SUCCEEDED: `${PREFIX}/DELETE/book-publication/succeeded`,
  DELETE_BOOK_PUBLICATION_FAILED: `${PREFIX}/DELETE/book-publication/failed`,

  // Steps:
  GET_STEP_REQUESTED: `${PREFIX}/GET/step/requested`,
  GET_STEP_SUCCEEDED: `${PREFIX}/GET/step/succeeded`,
  GET_STEP_FAILED: `${PREFIX}/GET/step/failed`,

  PATCH_STEP_REQUESTED: `${PREFIX}/PATCH/step/requested`,
  PATCH_STEP_SUCCEEDED: `${PREFIX}/PATCH/step/succeeded`,
  PATCH_STEP_FAILED: `${PREFIX}/PATCH/step/failed`,

  // Step Submission:
  SUBMIT_STEP_STARTED: `${PREFIX}/submit/step/started`,
  SUBMIT_STEP_FINISHED: `${PREFIX}/submit/step/finished`,

  SUBMIT_BOOK_DETAILS_REQUESTED: `${PREFIX}/submit/step/book-details/requested`,
  SUBMIT_BOOK_DETAILS_SUCCEEDED: `${PREFIX}/submit/step/book-details/succeeded`,
  SUBMIT_BOOK_DETAILS_FAILED: `${PREFIX}/submit/step/book-details/failed`,

  SUBMIT_PUBLICATION_DETAILS_REQUESTED: `${PREFIX}/submit/step/publication-details/requested`,
  SUBMIT_PUBLICATION_DETAILS_SUCCEEDED: `${PREFIX}/submit/step/publication-details/succeeded`,
  SUBMIT_PUBLICATION_DETAILS_FAILED: `${PREFIX}/submit/step/publication-details/failed`,

  SUBMIT_CONTRACT_SIGNING_REQUESTED: `${PREFIX}/submit/step/contract-signing/requested`,
  SUBMIT_CONTRACT_SIGNING_SUCCEEDED: `${PREFIX}/submit/step/contract-signing/succeeded`,
  SUBMIT_CONTRACT_SIGNING_FAILED: `${PREFIX}/submit/step/contract-signing/failed`,

  SUBMIT_TECHNICAL_INSTRUCTIONS_REQUESTED: `${PREFIX}/submit/step/technical-instructions/requested`,
  SUBMIT_TECHNICAL_INSTRUCTIONS_SUCCEEDED: `${PREFIX}/submit/step/technical-instructions/succeeded`,
  SUBMIT_TECHNICAL_INSTRUCTIONS_FAILED: `${PREFIX}/submit/step/technical-instructions/failed`,

  SUBMIT_AUTHORING_REQUESTED: `${PREFIX}/submit/step/authoring/requested`,
  SUBMIT_AUTHORING_SUCCEEDED: `${PREFIX}/submit/step/authoring/succeeded`,
  SUBMIT_AUTHORING_FAILED: `${PREFIX}/submit/step/authoring/failed`,

  SUBMIT_MANUSCRIPT_VERIFICATION_REQUESTED: `${PREFIX}/submit/step/manuscript-verification/requested`,
  SUBMIT_MANUSCRIPT_VERIFICATION_SUCCEEDED: `${PREFIX}/submit/step/manuscript-verification/succeeded`,
  SUBMIT_MANUSCRIPT_VERIFICATION_FAILED: `${PREFIX}/submit/step/manuscript-verification/failed`,

  SUBMIT_PUBLICATION_VERIFICATION_REQUESTED: `${PREFIX}/submit/step/publication-verification/requested`,
  SUBMIT_PUBLICATION_VERIFICATION_SUCCEEDED: `${PREFIX}/submit/step/publication-verification/succeeded`,
  SUBMIT_PUBLICATION_VERIFICATION_FAILED: `${PREFIX}/submit/step/publication-verification/failed`,
}

export default ActionNames
