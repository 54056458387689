import React, {
  useState,
  useImperativeHandle,
  useEffect,
  FC,
  forwardRef,
  useCallback,
  useRef,
} from 'react'

import { Form, Modal, Button, DatePicker, Input, Divider, Row, Col } from 'antd'
import { MinusCircleTwoTone, PlusOutlined } from '@ant-design/icons'
import Configs from 'configs/configs'
import styled from 'styled-components'

const ModalFormItem = styled(Form.Item)`
  margin: 0 24px 16px 0;
`

const ModalDivider = styled(Divider)`
  border-color: #888888;
  margin: 12px 0;
`

const ModalForm: FC<any> = (props, ref) => {
  const [form] = Form.useForm()
  const { visible, onCancel, onCreate } = props

  useImperativeHandle(ref, () => ({
    form: form,
  }))

  return (
    <Modal
      width="auto"
      visible={visible}
      onCancel={onCancel}
      onOk={onCreate}
      title="Προσθήκη Παραδοτέων"
      okText="Προσθήκη όλων"
      style={{
        textAlign: 'center',
        maxWidth: '600px',
      }}
    >
      <Form
        name="dynamic_form_nest_item"
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.List name="authorDeliverables">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, idx) => (
                  <div key={field.name}>
                    <Row style={{ position: 'relative', textAlign: 'left' }}>
                      <Col>
                        <ModalFormItem
                          {...field}
                          label="Όνομα"
                          name={[field.name, 'name']}
                          fieldKey={[field.fieldKey, 'name']}
                        >
                          <Input />
                        </ModalFormItem>
                      </Col>
                      <Col>
                        <ModalFormItem
                          {...field}
                          label="Επιθυμητή ημερομηνία παράδοσης"
                          name={[field.name, 'expectedDate']}
                          fieldKey={[field.fieldKey, 'expectedDate']}
                          rules={[
                            {
                              required: true,
                              message: Configs.Messages.RequiredField,
                            },
                          ]}
                        >
                          <DatePicker
                            format={Configs.Date.Format}
                            placeholder={Configs.Date.Placeholder}
                          />
                        </ModalFormItem>
                      </Col>
                      <Button
                        type="text"
                        shape="circle"
                        size="small"
                        icon={
                          <MinusCircleTwoTone
                            twoToneColor="red"
                            style={{ fontSize: 18 }}
                          />
                        }
                        onClick={() => {
                          remove(field.name)
                        }}
                        style={{ position: 'absolute', right: 0, top: '45%' }}
                      />
                    </Row>
                    {fields.length > 1 && idx !== fields.length - 1 && (
                      <ModalDivider />
                    )}
                  </div>
                ))}

                <Button
                  type="dashed"
                  onClick={() => {
                    add()
                  }}
                  block
                >
                  <PlusOutlined /> Προσθήκη παραδοτέου
                </Button>
              </>
            )
          }}
        </Form.List>
      </Form>
    </Modal>
  )
}

const ModalAddForm = forwardRef(ModalForm)

const AddDeliverablesModal: FC<any> = (props, ref) => {
  const { defaults, setDataRef, setHasFormChanged } = props

  const [modalDisplay, setModalDisplay] = useState(false)
  const [dataSource, setDataSource] = useState(defaults || [])

  // Whenever we've got an dataSource state change
  // perform the update on the parent component
  useEffect(() => {
    setDataRef(dataSource)
    if (dataSource !== defaults) {
      setHasFormChanged()
    }
  }, [dataSource]) // eslint-disable-line

  useImperativeHandle(ref, () => ({
    modalOpenHandler: modalOpenHandler,
  }))

  const modalOpenHandler = useCallback(() => {
    setModalDisplay(true)
  }, [])

  const modalCancelHandler = useCallback(() => {
    const { form } = modalAddFormRef.current
    setModalDisplay(false)
    form.resetFields()
  }, [])

  const modalCreateHandler = useCallback(() => {
    const { form } = modalAddFormRef.current

    form.validateFields().then((values) => {
      if (values.authorDeliverables) {
        setDataSource(
          values.authorDeliverables.map((item, index) => ({
            key: index,
            expectedDate: item.expectedDate,
            deliveredDate: '',
            name: item.name ?? '',
          }))
        )
      }
      form.resetFields()
      setModalDisplay(false)
    })
  }, [])

  const modalAddFormRef = useRef<any>(null)

  return (
    <ModalAddForm
      visible={modalDisplay}
      onCancel={modalCancelHandler}
      onCreate={modalCreateHandler}
      ref={(form) => (modalAddFormRef.current = form)}
    />
  )
}

export default forwardRef(AddDeliverablesModal)
