import { UIBookPublication } from 'features/bookPublicationForm/types'
import { PublicationStep, BookPublicationsListItem } from './types'
import _ from 'lodash'
import { UIUser } from 'features/authentication/types'
import Configs from 'configs/configs'

const getUnfinishedBookPublicationSteps = (
  bookPublication: UIBookPublication
): PublicationStep[] => {
  let unfinishedSteps: PublicationStep[] = []
  if (!bookPublication.bookDetails?.completion?.stepCompleted) {
    unfinishedSteps.push(PublicationStep.BookDetails)
  }
  if (!bookPublication.publicationDetails?.completion?.stepCompleted) {
    unfinishedSteps.push(PublicationStep.PublicationDetails)
  }
  if (!bookPublication.contractSigning?.completion?.stepCompleted) {
    unfinishedSteps.push(PublicationStep.ContractSigning)
  }
  if (!bookPublication.technicalInstructions?.completion?.stepCompleted) {
    unfinishedSteps.push(PublicationStep.TechnicalInstructions)
  }
  if (!bookPublication.authoring?.completion?.stepCompleted) {
    unfinishedSteps.push(PublicationStep.Authoring)
  }
  if (!bookPublication.manuscriptVerification?.completion?.stepCompleted) {
    unfinishedSteps.push(PublicationStep.ManuscriptVerification)
  }
  if (!bookPublication.publicationVerification?.completion?.stepCompleted) {
    unfinishedSteps.push(PublicationStep.PublicationVerification)
  }
  return unfinishedSteps
}

const getCurrentAssignees = (bookPublication: UIBookPublication): UIUser[] => {
  let currentAssignees: UIUser[] = []
  if (!bookPublication.bookDetails?.completion?.stepCompleted) {
    currentAssignees.push(...bookPublication.bookDetails?.completion?.assignees)
  }
  if (!bookPublication.publicationDetails?.completion?.stepCompleted) {
    currentAssignees.push(
      ...bookPublication.publicationDetails?.completion?.assignees
    )
  }
  if (!bookPublication.contractSigning?.completion?.stepCompleted) {
    currentAssignees.push(
      ...bookPublication.contractSigning?.completion?.assignees
    )
  }
  if (!bookPublication.technicalInstructions?.completion?.stepCompleted) {
    currentAssignees.push(
      ...bookPublication.technicalInstructions?.completion?.assignees
    )
  }
  if (!bookPublication.authoring?.completion?.stepCompleted) {
    currentAssignees.push(...bookPublication.authoring?.completion?.assignees)
  }
  if (!bookPublication.manuscriptVerification?.completion?.stepCompleted) {
    currentAssignees.push(
      ...bookPublication.manuscriptVerification?.completion?.assignees
    )
  }
  if (!bookPublication.publicationVerification?.completion?.stepCompleted) {
    currentAssignees.push(
      ...bookPublication.publicationVerification?.completion?.assignees
    )
  }
  return currentAssignees // includes duplicates, but it doesn't bother us
}

/**
 * Based on whether there's at least one, not completed, step in the
 * publication for which the user is included in the assignees.
 **/
export const isPublicationPendingOnUser = (
  bookPublication: UIBookPublication,
  userId: string
): boolean => {
  return getCurrentAssignees(bookPublication)
    .map((el) => el.id)
    .includes(userId)
}

/**
 * Based on whether there's at least one, not completed, step in the
 * publication.
 **/
export const isPublicationCompleted = (
  bookPublication: UIBookPublication
): boolean => {
  return (
    bookPublication.bookDetails?.completion.stepCompleted &&
    bookPublication.publicationDetails?.completion?.stepCompleted &&
    bookPublication.contractSigning?.completion?.stepCompleted &&
    bookPublication.technicalInstructions?.completion?.stepCompleted &&
    bookPublication.authoring?.completion?.stepCompleted &&
    bookPublication.manuscriptVerification?.completion?.stepCompleted &&
    bookPublication.publicationVerification?.completion?.stepCompleted
  )
}

export const getListItemFromBookPublication = (
  bookPublication: UIBookPublication
): BookPublicationsListItem => {
  return {
    seqId: bookPublication.seqId,
    bookTitle: bookPublication.bookDetails?.title,
    unfinishedSteps: getUnfinishedBookPublicationSteps(bookPublication),
    updatedDate: bookPublication.updatedDate,
    resolutionDate: bookPublication.publicationDetails.resolutionDate,
  }
}

export const isEmptyBookPublication = (
  bookPublication: UIBookPublication
): boolean => {
  const cleanPubValues = _.values(_.omit(bookPublication, 'id')).map((el) => ({
    ...(el as any),
    completion: _.omit((el as any)?.completion, 'stepEntityId'),
  }))
  for (let step of cleanPubValues) {
    for (let prop in step) {
      if (prop === 'resolution' && step === 'publicationDetails') continue
      if (prop === 'completion') {
        for (let completionProp in step[prop]) {
          if (
            Array.isArray(step[prop][completionProp])
              ? !!step[prop][completionProp].length
              : !!step[prop][completionProp]
          ) {
            return false
          }
        }
      } else {
        if (Array.isArray(step[prop]) ? !!step[prop].length : !!step[prop]) {
          return false
        }
      }
    }
  }
  return true
}

export const getPublicationCsvItem = (
  bookPublicationItem: BookPublicationsListItem
) => {
  return {
    id: bookPublicationItem.seqId.toString(),
    bookTitle: bookPublicationItem.bookTitle,
    unfinishedSteps: bookPublicationItem.unfinishedSteps.length
      ? bookPublicationItem.unfinishedSteps.map((el) => el.toString())
      : Configs.Messages.CompletedBookPublication,
    updatedDate: bookPublicationItem.updatedDate?.format(
      Configs.Date.FormatWithTime
    ),
    resolutionDate: bookPublicationItem.resolutionDate?.format(
      Configs.Date.FormatWithTime
    ),
  }
}
