import React, { FC } from 'react'

import Header from 'common/components/Header'
import Footer from 'common/components/Footer'
import { LayoutWrapper } from './styles'
import { ErrorBoundary } from 'common/components/ErrorBoundary'

const MainLayout: FC<any> = (props) => {
  return (
    <LayoutWrapper>
      <Header />
      <ErrorBoundary>{props.children}</ErrorBoundary>
      <Footer />
    </LayoutWrapper>
  )
}

export default MainLayout
