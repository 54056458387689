import React, { FC } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Result, Button } from 'antd'

import Routes from 'configs/routes'

const NotFoundLink = styled(Link)`
  color: white;
  &:hover {
    color: white;
  }
`

const NotFound: FC<{}> = () => {
  const location = useLocation()
  return (
    <section>
      <Result
        status="404"
        title="404"
        subTitle={`Η σελίδα "${location.pathname}" που ζητήσατε δεν υπάρχει.`}
        extra={
          <Button type="primary">
            {' '}
            <NotFoundLink to={Routes.Home}>Επιστροφή στην Αρχική</NotFoundLink>
          </Button>
        }
      />
    </section>
  )
}

export default NotFound
