import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Col, Form, Input, Modal, Row, Divider, Select, Checkbox } from 'antd'

import {
  FormHeader,
  ModalColFormItem,
} from 'features/bookPublicationForm/screens/styles'
import TextArea from 'antd/lib/input/TextArea'
import Configs from 'configs/configs'
import AuthorSearchInput from './AuthorSearch'
import { useCounterKey } from 'common/hooks/useCounterKey'
import {
  UIAuthor,
  UIInstitutionDetails,
} from 'features/bookPublicationForm/types'
import { Role } from '@hypernetica/gutenberg-bpm-common'

const { Option } = Select

const AuthorFormContent: FC<any> = (props) => {
  const allAuthors: UIAuthor[] = props.allAuthors
  const allInstitutionDetails: UIInstitutionDetails[] =
    props.allInstitutionDetails
  const { form, defaults, clearSearchFormMarker, rowKey } = props

  const [currentDepts, setCurrentDepts] = useState<string[]>([])

  useEffect(() => {
    setCurrentDepts(
      allInstitutionDetails
        .filter(
          (el) => el.university === defaults?.institutionDetails?.university
        )
        .map((el) => el.department)
    )
  }, [allInstitutionDetails, defaults])

  const handleUniversityChange = (value) => {
    form.setFieldsValue({ institutionDetails: { department: undefined } })
    if (value) {
      setCurrentDepts(
        allInstitutionDetails
          .filter((el) => el.university === value)
          .map((el) => el.department)
      )
    } else {
      setCurrentDepts([])
    }
  }

  const institutionDetailsHandler = (uni, dept) => {
    handleUniversityChange(uni)
    form.setFieldsValue({ institutionDetails: { department: dept } })
  }

  const roles = [
    { label: 'Συγγραφέας', value: Role.Author },
    { label: 'Επιμελητής', value: Role.Editor },
    // Client might not want this one displayed here
    { label: 'Καθηγητής', value: Role.Tutor },
    { label: 'Άλλο', value: Role.Other },
  ]
  return (
    <>
      <AuthorSearchInput
        parentForm={form}
        rowKey={rowKey}
        allAuthors={allAuthors}
        allInstitutionDetails={allInstitutionDetails}
        clearSearchFormMarker={clearSearchFormMarker}
        institutionDetailsHandler={institutionDetailsHandler}
      />
      <Divider />
      <Form layout="vertical" form={form} initialValues={defaults}>
        <Row>
          <Col span={12}>
            <ModalColFormItem
              label="Όνομα"
              name="firstname"
              rules={[
                { required: true, message: Configs.Messages.RequiredField },
              ]}
            >
              <Input id={`firstname_${rowKey}`} />
            </ModalColFormItem>
          </Col>
          <Col span={12}>
            <ModalColFormItem
              label="Επώνυμο"
              name="lastname"
              rules={[
                { required: true, message: Configs.Messages.RequiredField },
              ]}
            >
              <Input id={`lastname_${rowKey}`} />
            </ModalColFormItem>
          </Col>
        </Row>
        <ModalColFormItem
          label="Email"
          name="email"
          rules={[
            { required: true, message: Configs.Messages.RequiredField },
            {
              type: 'email',
              message: Configs.Messages.InvalidEmail,
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input id={`email_${rowKey}`} />
        </ModalColFormItem>
        <Row>
          <Col span={12}>
            <ModalColFormItem
              label="Τηλέφωνο 1"
              name="phone1"
              rules={[
                { required: true, message: Configs.Messages.RequiredField },
              ]}
            >
              <Input id={`phone1_${rowKey}`} />
            </ModalColFormItem>
          </Col>
          <Col span={12}>
            <ModalColFormItem label="Τηλέφωνο 2" name="phone2">
              <Input id={`phone2_${rowKey}`} />
            </ModalColFormItem>
          </Col>
        </Row>
        <FormHeader>Διεύθυνση</FormHeader>
        <Row>
          <Col span={19}>
            <ModalColFormItem label="Πόλη" name={['address', 'city']}>
              <Input id={`city_${rowKey}`} />
            </ModalColFormItem>
            <ModalColFormItem label="Οδός" name={['address', 'street']}>
              <Input id={`street_${rowKey}`} />
            </ModalColFormItem>
          </Col>
          <Col span={5}>
            <ModalColFormItem label="Τ.Κ." name={['address', 'postcode']}>
              <Input id={`postcode_${rowKey}`} />
            </ModalColFormItem>
            <ModalColFormItem label="Αριθμός" name={['address', 'number']}>
              <Input id={`number_${rowKey}`} />
            </ModalColFormItem>
          </Col>
        </Row>
        <FormHeader>Ιδιότητα </FormHeader>
        <ModalColFormItem name={'roles'}>
          <Checkbox.Group options={roles} />
        </ModalColFormItem>
        <FormHeader>Πληροφορίες Ιδρύματος</FormHeader>
        <ModalColFormItem
          label="Πανεπιστήμιο"
          name={['institutionDetails', 'university']}
        >
          <Select
            allowClear
            id={`university_${rowKey}`}
            onChange={handleUniversityChange}
          >
            {[
              ...new Set(allInstitutionDetails?.map((el) => el.university)),
            ].map((uni) => (
              <Option key={uni} value={uni}>
                {uni}
              </Option>
            ))}
          </Select>
        </ModalColFormItem>
        <ModalColFormItem
          label="Τμήμα"
          name={['institutionDetails', 'department']}
        >
          <Select
            allowClear
            id={`department_${rowKey}`}
            disabled={!currentDepts.length}
          >
            {currentDepts.map((dept) => (
              <Option key={dept} value={dept}>
                {dept}
              </Option>
            ))}
          </Select>
        </ModalColFormItem>
        <FormHeader>Σημειώσεις</FormHeader>
        <ModalColFormItem name="authorNotes">
          <TextArea id={`authorNotes_${rowKey}`} rows={3} />
        </ModalColFormItem>
      </Form>
    </>
  )
}

const AuthorCForm: FC<any> = (props, ref) => {
  const {
    visible,
    onCancel,
    onCreate,
    allAuthors,
    allInstitutionDetails,
    rowKey,
  } = props

  const [form] = Form.useForm()
  const [clearSearchFormMarker, setClearSearchFormMarker] = useCounterKey() // used to call child function

  useImperativeHandle(ref, () => ({
    form: form,
  }))

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel()
        setClearSearchFormMarker()
      }}
      onOk={() => {
        onCreate()
        setClearSearchFormMarker()
      }}
      title="Προσθήκη"
      okText="Προσθήκη"
    >
      <AuthorFormContent
        form={form}
        allAuthors={allAuthors}
        allInstitutionDetails={allInstitutionDetails}
        disabled={false}
        clearSearchFormMarker={clearSearchFormMarker}
        rowKey={rowKey}
      />
    </Modal>
  )
}

const AuthorEForm: FC<any> = (props, ref) => {
  const {
    visible,
    onCancel,
    onEdit,
    editAuthor,
    disabled,
    allAuthors,
    allInstitutionDetails,
    rowKey,
  } = props

  const [form] = Form.useForm()
  const [clearSearchFormMarker, setClearSearchFormMarker] = useCounterKey() // used to call child function

  useImperativeHandle(ref, () => ({
    form: form,
  }))

  useEffect(() => {
    form.setFieldsValue(editAuthor)
  }, [form, editAuthor])

  return disabled ? (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel()
        setClearSearchFormMarker()
      }}
      footer={null}
      title="Επισκόπηση"
      forceRender
    >
      <div style={{ pointerEvents: 'none' }}>
        <AuthorFormContent
          form={form}
          defaults={editAuthor}
          allAuthors={allAuthors}
          allInstitutionDetails={allInstitutionDetails}
          clearSearchFormMarker={clearSearchFormMarker}
          rowKey={rowKey}
        />
      </div>
    </Modal>
  ) : (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel()
        setClearSearchFormMarker()
      }}
      onOk={() => {
        onEdit()
        setClearSearchFormMarker()
      }}
      title="Ανανέωση"
      okText="Ανανέωση"
      forceRender
    >
      <AuthorFormContent
        form={form}
        defaults={editAuthor}
        allAuthors={allAuthors}
        allInstitutionDetails={allInstitutionDetails}
        clearSearchFormMarker={clearSearchFormMarker}
        rowKey={rowKey}
      />
    </Modal>
  )
}

export const AuthorCreateForm = forwardRef(AuthorCForm)
export const AuthorEditForm = forwardRef(AuthorEForm)
