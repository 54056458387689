import { combineReducers } from 'redux'

import ActionNames from './actionNames'
import {
  IGetEntitiesState,
  IEntitiesErrorState,
  IPostEntitiesState,
  IStepSubmitState,
  UIDistribution,
} from 'features/bookPublicationForm/types'
import {
  mapBookProducerDtoToUi,
  mapBookCategoryDtoToUi,
  mapSeriesDtoToUi,
  mapUserDtoToUi,
  mapTechnicalDetailsDtoToUi,
  mapContractDtoToUi,
  mapDistributionDetailsDtoToUi,
  mapAuthoringProgressDtoToUi,
  mapStepDtoToUiForComments,
  mapBookDtoToUi,
  mapBookPublicationDtoToUi,
  mapCommentDtoToUi,
  mapInstitutionDetailsDtoToUi,
} from 'api/mappers'
import { GenericActionType } from 'redux/types'
import Dtos from '@hypernetica/gutenberg-bpm-common'

const initialEntitiesState: IGetEntitiesState = {
  authors: [],
  institutionDetails: [],
  bookCategories: [],
  bookSeries: [],
  users: [],
  step: undefined,
}

function GetEntitiesReducer(
  state = initialEntitiesState,
  action: GenericActionType
): IGetEntitiesState {
  switch (action.type) {
    case ActionNames.GET_AUTHORS_SUCCEEDED:
      const authorsJson = (action as any).payload.authors
      return {
        ...state,
        authors: authorsJson.map((el) => mapBookProducerDtoToUi(el)),
      }
    case ActionNames.GET_INSTITUTION_DETAILS_SUCCEEDED:
      const institutionDetailsJson = (action as any).payload.institutionDetails
      return {
        ...state,
        institutionDetails: institutionDetailsJson.map((el) =>
          mapInstitutionDetailsDtoToUi(el)
        ),
      }
    case ActionNames.GET_BOOK_CATEGORIES_SUCCEEDED:
      const bookCategoriesJson = (action as any).payload.bookCategories
      return {
        ...state,
        bookCategories: bookCategoriesJson.map((el, i) => ({
          ...mapBookCategoryDtoToUi(el),
          key: i,
        })),
      }
    case ActionNames.GET_BOOK_SERIES_SUCCEEDED:
      const bookSeriesJson = (action as any).payload.bookSeries
      return {
        ...state,
        bookSeries: bookSeriesJson.map((el, i) => ({
          ...mapSeriesDtoToUi(el),
          key: i,
        })),
      }
    case ActionNames.GET_USERS_SUCCEEDED:
      const usersJson = (action as any).payload.users
      return {
        ...state,
        users: usersJson.map((el, i) => ({
          ...mapUserDtoToUi(el),
          key: i,
        })),
      }
    case ActionNames.GET_STEP_SUCCEEDED:
      const stepJson = (action as any).payload.step
      return {
        ...state,
        step: mapStepDtoToUiForComments(stepJson),
      }

    default:
      return state
  }
}

const initialPostEntitiesState: IPostEntitiesState = {
  authors: [],
  bookCategory: undefined,
  book: undefined,
  bookDistributions: [],
  contracts: [],
  technicalInstructions: [],
  authoringProgress: [],
  bookSeries: undefined,
  bookPublicationData: {},
  comment: undefined,
}

function PostEntitiesReducer(
  state = initialPostEntitiesState,
  action: GenericActionType
): IPostEntitiesState {
  switch (action.type) {
    case ActionNames.POST_AUTHOR_SUCCEEDED:
      const authorJson = (action as any).payload.author
      return {
        ...state,
        authors: [
          ...state.authors,
          mapBookProducerDtoToUi(
            Dtos.BookProducerDto.fromIDataObject(authorJson)
          ),
        ],
      }

    case ActionNames.POST_BOOK_CATEGORY_SUCCEEDED:
      const bookCategoryJson = (action as any).payload.bookCategory
      return {
        ...state,
        bookCategory: mapBookCategoryDtoToUi(
          Dtos.BookCategoryDto.fromIDataObject(bookCategoryJson)
        ),
      }

    case ActionNames.POST_BOOK_SUCCEEDED:
      const bookJson = (action as any).payload.book
      return {
        ...state,
        book: mapBookDtoToUi(Dtos.BookDto.fromIDataObject(bookJson)),
      }

    case ActionNames.POST_BOOK_DISTRIBUTION_SUCCEEDED:
      const bookDistributionJson = (action as any).payload.bookDistribution
      return {
        ...state,
        bookDistributions: [
          ...state.bookDistributions,
          mapDistributionDetailsDtoToUi(
            Dtos.DistributionDetailsDto.fromIDataObject(bookDistributionJson)
          ) as UIDistribution,
        ],
      }

    case ActionNames.POST_CONTRACT_SUCCEEDED:
      const contractJson = (action as any).payload.contract
      return {
        ...state,
        contracts: [
          ...state.contracts,
          mapContractDtoToUi(Dtos.ContractDto.fromIDataObject(contractJson)),
        ],
      }

    case ActionNames.POST_TECHNICAL_INSTRUCTION_SUCCEEDED:
      const technicalInstructionJson = (action as any).payload
        .technicalInstruction
      return {
        ...state,
        technicalInstructions: [
          ...state.technicalInstructions,
          mapTechnicalDetailsDtoToUi(
            Dtos.TechnicalDetailsDto.fromIDataObject(technicalInstructionJson)
          ),
        ],
      }

    case ActionNames.POST_AUTHORING_PROGRESS_SUCCEEDED:
      const authoringProgressJson = (action as any).payload.authoringProgress
      return {
        ...state,
        authoringProgress: [
          ...state.authoringProgress,
          mapAuthoringProgressDtoToUi(
            Dtos.AuthoringProgressDto.fromIDataObject(authoringProgressJson)
          ),
        ],
      }

    case ActionNames.POST_BOOK_SERIES_SUCCEEDED:
      const bookSeriesJson = (action as any).payload.bookSeries
      return {
        ...state,
        bookSeries: mapSeriesDtoToUi(
          Dtos.SeriesDto.fromIDataObject(bookSeriesJson)
        ),
      }

    case ActionNames.POST_COMMENT_REQUESTED:
      return {
        ...state,
        comment: undefined,
      }
    case ActionNames.POST_COMMENT_SUCCEEDED:
      const commentJson = (action as any).payload.comment
      return {
        ...state,
        comment: mapCommentDtoToUi(
          Dtos.CommentDto.fromIDataObject(commentJson)
        ),
      }

    case ActionNames.INIT_BOOK_PUBLICATION_SUCCEEDED:
    case ActionNames.PATCH_BOOK_PUBLICATION_SUCCEEDED: {
      const bookPublicationJson = (action as any).payload.bookPublication
      return {
        ...state,
        bookPublicationData: mapBookPublicationDtoToUi(
          Dtos.BookPublicationDto.fromIDataObject(bookPublicationJson),
          true
        ),
      }
    }

    // Clearing POSTed entities following the submission's resolution
    case ActionNames.SUBMIT_BOOK_DETAILS_SUCCEEDED:
    case ActionNames.SUBMIT_BOOK_DETAILS_FAILED: {
      return {
        ...state,
        authors: [],
        bookCategory: undefined,
        book: undefined,
        bookSeries: undefined,
      }
    }

    case ActionNames.SUBMIT_PUBLICATION_DETAILS_SUCCEEDED:
    case ActionNames.SUBMIT_PUBLICATION_DETAILS_FAILED: {
      return {
        ...state,
        authors: [],
        bookDistributions: [],
      }
    }

    case ActionNames.SUBMIT_CONTRACT_SIGNING_SUCCEEDED:
    case ActionNames.SUBMIT_CONTRACT_SIGNING_FAILED: {
      return {
        ...state,
        contracts: [],
      }
    }

    case ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_SUCCEEDED:
    case ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_FAILED: {
      return {
        ...state,
        technicalInstructions: [],
      }
    }

    case ActionNames.SUBMIT_AUTHORING_SUCCEEDED:
    case ActionNames.SUBMIT_AUTHORING_FAILED: {
      return {
        ...state,
        authoringProgress: [],
      }
    }

    case ActionNames.DELETE_BOOK_PUBLICATION_SUCCEEDED: {
      return {
        ...state,
        bookPublicationData: {},
      }
    }

    default:
      return state
  }
}

const initialStepSubmitState: IStepSubmitState = {
  stepSubmitting: false,
  stepSubmissionComplete: false,
  stepSubmittedData: {},
  stepSubmittedErrors: [],
}

function StepSubmitReducer(
  state = initialStepSubmitState,
  action: GenericActionType
): IStepSubmitState {
  switch (action.type) {
    case ActionNames.SUBMIT_STEP_STARTED:
      return { ...initialStepSubmitState, stepSubmitting: true }

    case ActionNames.SUBMIT_STEP_FINISHED:
      return { ...state, stepSubmitting: false, stepSubmissionComplete: true }

    case ActionNames.SUBMIT_BOOK_DETAILS_SUCCEEDED:
    case ActionNames.SUBMIT_PUBLICATION_DETAILS_SUCCEEDED:
    case ActionNames.SUBMIT_CONTRACT_SIGNING_SUCCEEDED:
    case ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_SUCCEEDED:
    case ActionNames.SUBMIT_AUTHORING_SUCCEEDED:
    case ActionNames.SUBMIT_MANUSCRIPT_VERIFICATION_SUCCEEDED:
    case ActionNames.SUBMIT_PUBLICATION_VERIFICATION_SUCCEEDED:
      const payloadStepData = (action as any).payload?.stepData
      return {
        ...state,
        stepSubmittedData: payloadStepData,
        stepSubmittedErrors: [],
      }

    case ActionNames.SUBMIT_BOOK_DETAILS_FAILED:
    case ActionNames.SUBMIT_PUBLICATION_DETAILS_FAILED:
    case ActionNames.SUBMIT_CONTRACT_SIGNING_FAILED:
    case ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_FAILED:
    case ActionNames.SUBMIT_AUTHORING_FAILED:
    case ActionNames.SUBMIT_MANUSCRIPT_VERIFICATION_FAILED:
    case ActionNames.SUBMIT_PUBLICATION_VERIFICATION_FAILED:
      const message = (action as any).payload?.error?.message
      return returnErrorWithCatch(state, 'stepSubmittedErrors', message)

    case ActionNames.PATCH_BOOK_PUBLICATION_SUCCEEDED:
    case ActionNames.PATCH_BOOK_PUBLICATION_FAILED:
      return initialStepSubmitState

    default:
      return state
  }
}

const initialErrorState: IEntitiesErrorState = {
  getAuthorsErrors: [],
  getInstitutionDetailsErrors: [],
  getBookCategoriesErrors: [],
  getBookSeriesErrors: [],
  getUsersErrors: [],
  getStepErrors: [],
  postAuthorErrors: [],
  postBookCategoryErrors: [],
  postBookErrors: [],
  postBookDistributionErrors: [],
  postContractErrors: [],
  postTechnicalInstructionErrors: [],
  postAuthoringProgressErrors: [],
  postBookSeriesErrors: [],
  postCommentErrors: [],
  patchAuthorErrors: [],
  patchBookErrors: [],
  patchBookDistributionErrors: [],
  patchContractErrors: [],
  patchTechnicalInstructionErrors: [],
  patchAuthoringProgressErrors: [],
  patchCommentErrors: [],
  patchStepErrors: [],
  initBookPublicationErrors: [],
  patchBookPublicationErrors: [],
}

function ErrorReducer(
  state = initialErrorState,
  action: GenericActionType
): IEntitiesErrorState {
  const message = (action as any).payload?.error?.message
  switch (action.type) {
    case ActionNames.GET_AUTHORS_SUCCEEDED:
      return { ...state, getAuthorsErrors: [] }
    case ActionNames.GET_AUTHORS_FAILED: {
      return returnErrorWithCatch(state, 'getAuthorsErrors', message)
    }
    case ActionNames.GET_INSTITUTION_DETAILS_SUCCEEDED:
      return { ...state, getInstitutionDetailsErrors: [] }
    case ActionNames.GET_INSTITUTION_DETAILS_FAILED: {
      return returnErrorWithCatch(state, 'getInstitutionDetailsErrors', message)
    }
    case ActionNames.GET_BOOK_CATEGORIES_SUCCEEDED:
      return { ...state, getBookCategoriesErrors: [] }
    case ActionNames.GET_BOOK_CATEGORIES_FAILED: {
      return returnErrorWithCatch(state, 'getBookCategoriesErrors', message)
    }
    case ActionNames.GET_BOOK_SERIES_SUCCEEDED:
      return { ...state, getBookSeriesErrors: [] }
    case ActionNames.GET_BOOK_SERIES_FAILED: {
      return returnErrorWithCatch(state, 'getBookSeriesErrors', message)
    }
    case ActionNames.GET_USERS_SUCCEEDED:
      return { ...state, getUsersErrors: [] }
    case ActionNames.GET_USERS_FAILED: {
      return returnErrorWithCatch(state, 'getUsersErrors', message)
    }
    case ActionNames.GET_STEP_SUCCEEDED:
      return { ...state, getStepErrors: [] }
    case ActionNames.GET_STEP_FAILED: {
      return returnErrorWithCatch(state, 'getStepErrors', message)
    }

    case ActionNames.POST_AUTHOR_FAILED: {
      return returnErrorWithCatch(state, 'postAuthorErrors', message)
    }
    case ActionNames.POST_BOOK_CATEGORY_FAILED: {
      return returnErrorWithCatch(state, 'postBookCategoryErrors', message)
    }
    case ActionNames.POST_BOOK_FAILED: {
      return returnErrorWithCatch(state, 'postBookErrors', message)
    }
    case ActionNames.POST_BOOK_DISTRIBUTION_FAILED: {
      return returnErrorWithCatch(state, 'postBookDistributionsErrors', message)
    }
    case ActionNames.POST_CONTRACT_FAILED: {
      return returnErrorWithCatch(state, 'postContractErrors', message)
    }
    case ActionNames.POST_TECHNICAL_INSTRUCTION_FAILED: {
      return returnErrorWithCatch(
        state,
        'postTechnicalInstructionErrors',
        message
      )
    }
    case ActionNames.POST_AUTHORING_PROGRESS_FAILED: {
      return returnErrorWithCatch(state, 'postAuthoringProgressErrors', message)
    }
    case ActionNames.POST_BOOK_SERIES_FAILED: {
      return returnErrorWithCatch(state, 'postBookSeriesErrors', message)
    }

    case ActionNames.PATCH_AUTHOR_FAILED: {
      return returnErrorWithCatch(state, 'patchAuthorErrors', message)
    }
    case ActionNames.PATCH_BOOK_FAILED: {
      return returnErrorWithCatch(state, 'patchBookErrors', message)
    }
    case ActionNames.PATCH_BOOK_DISTRIBUTION_FAILED: {
      return returnErrorWithCatch(
        state,
        'patchBookDistributionsErrors',
        message
      )
    }
    case ActionNames.PATCH_CONTRACT_FAILED: {
      return returnErrorWithCatch(state, 'patchContractErrors', message)
    }
    case ActionNames.PATCH_TECHNICAL_INSTRUCTION_FAILED: {
      return returnErrorWithCatch(
        state,
        'patchTechnicalInstructionErrors',
        message
      )
    }
    case ActionNames.PATCH_AUTHORING_PROGRESS_FAILED: {
      return returnErrorWithCatch(
        state,
        'patchAuthoringProgressErrors',
        message
      )
    }

    case ActionNames.POST_COMMENT_REQUESTED: {
      return { ...state, postCommentErrors: [] }
    }
    case ActionNames.POST_COMMENT_FAILED: {
      return returnErrorWithCatch(state, 'postCommentErrors', message)
    }
    case ActionNames.PATCH_COMMENT_REQUESTED: {
      return { ...state, patchCommentErrors: [] }
    }
    case ActionNames.PATCH_COMMENT_FAILED: {
      return returnErrorWithCatch(state, 'patchCommentErrors', message)
    }
    case ActionNames.PATCH_STEP_REQUESTED: {
      return { ...state, patchStepErrors: [] }
    }
    case ActionNames.PATCH_STEP_FAILED: {
      return returnErrorWithCatch(state, 'patchStepErrors', message)
    }

    case ActionNames.INIT_BOOK_PUBLICATION_SUCCEEDED: {
      return { ...state, initBookPublicationErrors: [] }
    }
    case ActionNames.INIT_BOOK_PUBLICATION_FAILED: {
      return returnErrorWithCatch(state, 'initBookPublicationErrors', message)
    }
    case ActionNames.PATCH_BOOK_PUBLICATION_SUCCEEDED: {
      return { ...state, patchBookPublicationErrors: [] }
    }
    case ActionNames.PATCH_BOOK_PUBLICATION_FAILED: {
      return returnErrorWithCatch(state, 'patchBookPublicationErrors', message)
    }

    // Clearing POST/PATCH errors from step entities following the submission's resolution
    case ActionNames.SUBMIT_BOOK_DETAILS_SUCCEEDED:
    case ActionNames.SUBMIT_BOOK_DETAILS_FAILED: {
      return {
        ...state,
        postBookCategoryErrors: [],
        postAuthorErrors: [],
        postBookErrors: [],
        postBookSeriesErrors: [],
        patchAuthorErrors: [],
        patchBookErrors: [],
      }
    }

    case ActionNames.SUBMIT_PUBLICATION_DETAILS_SUCCEEDED:
    case ActionNames.SUBMIT_PUBLICATION_DETAILS_FAILED: {
      return {
        ...state,
        postAuthorErrors: [],
        postBookDistributionErrors: [],
        patchAuthorErrors: [],
        patchBookDistributionErrors: [],
      }
    }

    case ActionNames.SUBMIT_CONTRACT_SIGNING_SUCCEEDED:
    case ActionNames.SUBMIT_CONTRACT_SIGNING_FAILED: {
      return {
        ...state,
        postContractErrors: [],
        patchContractErrors: [],
      }
    }

    case ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_SUCCEEDED:
    case ActionNames.SUBMIT_TECHNICAL_INSTRUCTIONS_FAILED: {
      return {
        ...state,
        postTechnicalInstructionErrors: [],
        patchTechnicalInstructionErrors: [],
      }
    }

    case ActionNames.SUBMIT_AUTHORING_SUCCEEDED:
    case ActionNames.SUBMIT_AUTHORING_FAILED: {
      return {
        ...state,
        postAuthoringProgressErrors: [],
        patchAuthoringProgressErrors: [],
      }
    }

    default:
      return state
  }
}

const returnErrorWithCatch = (state, fieldname: string, message: string) => {
  try {
    return {
      ...state,
      [fieldname]: [...JSON.parse(message)],
    }
  } catch {
    return {
      ...state,
      [fieldname]: [message],
    }
  }
}

export default combineReducers({
  getData: GetEntitiesReducer,
  postData: PostEntitiesReducer,
  stepData: StepSubmitReducer,
  errors: ErrorReducer,
})
